import { makeAutoObservable } from 'mobx';

export class PaginationStore {
	nextPage: number | null = null;
	perPage: number | null = null;

	constructor() {
		makeAutoObservable(this);
	}

	setNextPage = (page: number | null): void => {
		this.nextPage = page;
	};

	get isHasNextPage(): boolean {
		return this.nextPage !== null;
	}
}
