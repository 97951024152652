import './Auth.scss';

import {
	ErrorDataResponse,
	RegisterService,
} from '../../services/auth/register.service';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import React, { useState } from 'react';
import {
	URL_LOGIN_PATH,
	URL_TELEGRAM_AUTH_PATH,
} from '../../constants/urls.constants';

import { RegisterForm } from '../../components/Auth/RegisterForm';
import { SecondaryButton } from '../../components/Buttons/SecondaryButton/SecondaryButton';
import { useRootStore } from '../../stores/RootStore';

export const RegisterPage: React.FC = () => {
	const navigate = useNavigate();

	const [searchParams] = useSearchParams({});
	const invitationCode: string | null = searchParams.get('invitation_code');

	const { profileStore } = useRootStore();

	const [errors, setErrors] = useState<Partial<ErrorDataResponse>>({});
	const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

	const submitRegister = async (
		username: string,
		password: string,
		passwordConfirm: string,
	) => {
		if (isSubmitLoading) {
			return;
		}

		if (password !== passwordConfirm) {
			setErrors({ password_confirm: ['Пароли не совпадают'] });
			return;
		}

		setErrors({});
		setIsSubmitLoading(true);

		const serviceResponse = await RegisterService(
			username,
			password,
			invitationCode,
		);
		if (serviceResponse.status === 'OK') {
			const profileInfo = serviceResponse.data;
			profileStore.setProfileInfo(profileInfo);
			navigate(URL_TELEGRAM_AUTH_PATH);
		} else {
			setErrors(serviceResponse.data);
		}

		setIsSubmitLoading(false);
	};

	return (
		<div className="auth-page">
			<RegisterForm
				errors={errors}
				isSubmitLoading={isSubmitLoading}
				submitCallback={submitRegister}
			/>
			<Link to={URL_LOGIN_PATH} className="auth-page__change_step">
				<SecondaryButton title="Войти" />
			</Link>
		</div>
	);
};
