import './NewsPage.scss';

import {
	EVENT_MESSAGE_CART_DELETED,
	EVENT_MESSAGE_CART_EDITED,
} from '../../constants/events.constants';
import React, { useEffect, useState } from 'react';

import { CartEntity } from '../../entities/cart.entity';
import { CheckNewsService } from '../../services/newsfeed/check-news.service';
import { FetchNewsFiltersService } from '../../services/newsfeed/fetch-news-filters.service';
import { FetchNewsService } from '../../services/newsfeed/fetch-news.service';
import { FilterStore } from '../../stores/FilterStore';
import { FullCartEntity } from '../../entities/full-cart.entity';
import { LoadingData } from '../../components/LoadingData/LoadingData';
import { NewsFeedCart } from '../../components/Cart/NewsFeedCart/NewsFeedCart';
import { NewsFeedCartEntity } from '../../entities/news-feed.entity';
import { NoContent } from '../../components/NoContent/NoContent';
import { PageWithSidePanel } from '../../components/Pages/PageWithSidePanel';
import { ReactedEmojiEntity } from '../../entities/emoji.entity';
import { SearchFiltersSidePanel } from '../../components/SidePanel/SearchFiltersSidePanel';
import { getFullCart } from '../../services/carts/get-full-cart.service';
import { observer } from 'mobx-react-lite';
import { pushEmoji } from '../../services/carts/push-emoji.service';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useRootStore } from '../../stores/RootStore';

const signablesFilterStore = new FilterStore();

export const NewsPage = observer(() => {
	const { profileStore, eventsStore } = useRootStore();
	const emojiStore = profileStore.emojiStore;

	const [isNewsChecked, setIsNewsChecked] = useState<boolean>(false);
	const [isFiltersFetched, setIsFiltersFetched] = useState<boolean>(false);
	const [isCartsFetched, setIsCartsFetched] = useState<boolean>(false);

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [nextPage, setNextPage] = useState<number | null>(1);

	const [fullCart, setFullCart] = useState<FullCartEntity | null>(null);
	const [openedFullCartId, setOpenedFullCartId] = useState<number | null>(
		null,
	);
	const [repostingCartId, setRepostingCartId] = useState<number | null>(null);
	const [toPendingCartId, setToPendingCartId] = useState<number | null>(null);

	const [carts, setCarts] = useState<NewsFeedCartEntity[]>([]);

	const checkNews = async () => {
		if (isNewsChecked || !isCartsFetched || !isFiltersFetched) {
			return;
		}
		setIsNewsChecked(true);

		const serviceResponse = await CheckNewsService();
		if (serviceResponse.status === 'OK' && profileStore.profileInfo) {
			profileStore.setNewsFeedChecked();
		} else if (serviceResponse.status === 'ERROR') {
			eventsStore.pushUnknownErrorEvent();
		}
	};

	useEffect(() => {
		checkNews();
	}, [isCartsFetched, isFiltersFetched]);

	const updateFiltersItems = async () => {
		const serviceResponse = await FetchNewsFiltersService();
		if (serviceResponse.status === 'OK') {
			signablesFilterStore.updateItems(serviceResponse.data.signables);
			setIsFiltersFetched(true);
		} else {
			eventsStore.pushUnknownErrorEvent();
		}
	};

	useEffect(() => {
		updateFiltersItems();
	}, []);

	const fetchNewsFeedCarts = async (
		signableId: number | null,
		page: number | null,
		carts: NewsFeedCartEntity[] = [],
	) => {
		if (isLoading) {
			return;
		}
		setIsLoading(true);

		const serviceResponse = await FetchNewsService(signableId, page);
		if (serviceResponse.status === 'OK') {
			const newCarts = serviceResponse.data.data;
			setCarts([...carts, ...newCarts]);
			setNextPage(serviceResponse.data.meta.next_page);
			setIsCartsFetched(true);
		} else {
			eventsStore.pushUnknownErrorEvent();
			setNextPage(null);
		}

		setIsLoading(false);
	};

	const isHasNextPage = nextPage !== null;
	const filteredSignableId: number | null =
		signablesFilterStore.picked.length > 0
			? signablesFilterStore.picked[0]
			: null;

	const fetchFilteredNewsFeedCartsEffect = () => {
		const firstPage = 1;
		fetchNewsFeedCarts(filteredSignableId, firstPage);
	};
	useEffect(fetchFilteredNewsFeedCartsEffect, [filteredSignableId]);

	const [infiniteScrollRef] = useInfiniteScroll({
		loading: isLoading,
		hasNextPage: isHasNextPage,
		onLoadMore: () => {
			fetchNewsFeedCarts(filteredSignableId, nextPage, carts);
		},
		disabled: false,
		rootMargin: '0px 0px 10px 0px',
	});

	const fetchFullCartData = () => {
		const updateFullCart = async (cartId: number) => {
			const response = await getFullCart(cartId.toString());
			if (response.status === 'OK') {
				setFullCart(response.data);
			} else {
				eventsStore.pushUnknownErrorEvent();
			}
		};

		setFullCart(null);
		if (openedFullCartId !== null) {
			updateFullCart(openedFullCartId);
		}
	};
	useEffect(fetchFullCartData, [openedFullCartId]);

	const updateCartEmojis = (cartId: number, emojis: ReactedEmojiEntity[]) => {
		const cartIndexToUpdate = carts.findIndex(
			(obj) => obj.cart.id === cartId,
		);
		const cartToUpdate = carts[cartIndexToUpdate];
		cartToUpdate.cart.emojis = emojis;
		setCarts([...carts]);
	};
	const updateFullCartEmojis = (emojis: ReactedEmojiEntity[]) => {
		if (fullCart === null) {
			return;
		}
		const newFullCartEntity = { ...fullCart };
		newFullCartEntity.cart.emojis = emojis;
		setFullCart(newFullCartEntity);
	};

	const renderCart = (newsFeedCart: NewsFeedCartEntity): React.ReactNode => {
		const cart = newsFeedCart.cart;

		const pushEmojiCallback = async (emojiName: string, add: boolean) => {
			const response = await pushEmoji(cart.id, emojiName, add);
			if (response.status === 'OK') {
				const emojis = response.data;
				updateCartEmojis(cart.id, emojis);
				updateFullCartEmojis(emojis);
			} else {
				eventsStore.pushUnknownErrorEvent();
			}
		};

		const isOpenedFullCart = openedFullCartId === cart.id;
		const setIsOpenedFullCartCallback = (isOpened: boolean) => {
			isOpened ? setOpenedFullCartId(cart.id) : setOpenedFullCartId(null);
		};

		const isRepostingCart = repostingCartId === cart.id;
		const setIsRepostingCartCallback = (isReposting: boolean) => {
			isReposting
				? setRepostingCartId(cart.id)
				: setRepostingCartId(null);
		};

		const isToPendingCart = toPendingCartId === cart.id;
		const setToPendingCartCallback = (isToPending: boolean) => {
			isToPending
				? setToPendingCartId(cart.id)
				: setToPendingCartId(null);
		};

		const isMyCart = newsFeedCart.user.userId === profileStore.userId;

		const updateCart = (cart: CartEntity) => {
			const cartIndexToUpdate = carts.findIndex(
				(obj) => obj.cart.id === cart.id,
			);
			const cartToUpdate = carts[cartIndexToUpdate];
			cartToUpdate.cart = { ...cart, emojis: cartToUpdate.cart.emojis };
			setCarts([...carts]);
		};
		const editedCallback = (cart: CartEntity) => {
			eventsStore.pushSuccessEvent(
				EVENT_MESSAGE_CART_EDITED`${cart.title}`,
			);
			updateCart(cart);
			setOpenedFullCartId(null);
		};

		const deleteCart = () => {
			const cartIndexToDelete = carts.findIndex(
				(obj) => obj.cart.id === cart.id,
			);
			carts.splice(cartIndexToDelete, 1);
			setCarts([...carts]);
		};
		const deletedCallback = () => {
			eventsStore.pushSuccessEvent(
				EVENT_MESSAGE_CART_DELETED`${cart.title}`,
			);
			deleteCart();
			setOpenedFullCartId(null);
		};
		return (
			<div className="news__cart_container" key={cart.id}>
				<NewsFeedCart
					newsFeedCart={newsFeedCart}
					fullCart={fullCart}
					isOpenedFullCart={isOpenedFullCart}
					setIsOpenedFullCartCallback={setIsOpenedFullCartCallback}
					isRepostingCart={isRepostingCart}
					setIsRepostingCartCallback={setIsRepostingCartCallback}
					isToPendingCart={isToPendingCart}
					setToPendingCartCallback={setToPendingCartCallback}
					allEmojis={emojiStore.allEmojis}
					lastUsedEmojis={emojiStore.lastUsedEmojis}
					pushEmojiCallback={pushEmojiCallback}
					isMyCart={isMyCart}
					editedCallback={editedCallback}
					deletedCallback={deletedCallback}
				/>
			</div>
		);
	};

	const newsFeedElements: React.ReactNode[] = carts.map(renderCart);
	const noNewsNode: React.ReactNode = carts.length === 0 && !isLoading && (
		<NoContent text="Новостей нет" />
	);

	const loading = (
		<LoadingData isLoading={isLoading}>
			{isHasNextPage && <div ref={infiniteScrollRef}></div>}
		</LoadingData>
	);

	const sidePanel: React.ReactNode = (
		<SearchFiltersSidePanel
			filters={[
				{
					filter: signablesFilterStore,
					title: 'Подписки',
					canPickOnlyOneOption: true,
				},
			]}
		/>
	);

	const contentNode: React.ReactNode = (
		<div className="news">
			{newsFeedElements}
			{noNewsNode}
			{loading}
		</div>
	);

	return <PageWithSidePanel sidePanel={sidePanel} content={contentNode} />;
});
