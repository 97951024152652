import './CollectionInfo.scss';

import { CollectionDescription } from './CollectionDescription';
import { FullCollectionEntity } from '../../../entities/collection.entity';
import { Link } from 'react-router-dom';
import React from 'react';
import { URL_USER_COLLECTION_PAGE } from '../../../constants/urls.constants';

type CollectionInfoProps = {
	collection: FullCollectionEntity;
	isMyCollection: boolean;
	isLink: boolean;
	instrumentNodes?: React.ReactNode;
	submitDescriptionCallback?: (description: string | null) => void;
};

export const CollectionInfo: React.FC<CollectionInfoProps> = ({
	collection,
	isMyCollection,
	isLink,
	instrumentNodes,
	submitDescriptionCallback,
}) => {
	const titleNode: React.ReactNode = isLink ? (
		<Link
			className="collection-info__title collection-info__title--link"
			to={URL_USER_COLLECTION_PAGE`${collection.id.toString()}`}
		>
			{collection.title}
		</Link>
	) : (
		<h1 className="collection-info__title">{collection.title}</h1>
	);

	const instrumentsNode: React.ReactNode = instrumentNodes && (
		<div className="collection-info__instruments">{instrumentNodes}</div>
	);

	const descriptionNode: React.ReactNode = (
		<CollectionDescription
			collection={collection}
			isMyCollection={isMyCollection}
			submitDescriptionCallback={submitDescriptionCallback}
		/>
	);

	return (
		<div className="collection-info">
			<div className="collection-info__row-1">
				<div className="collection-info__info">
					{titleNode}
					<span className="collection-info__category">
						{collection.category.title}
					</span>
				</div>
				{instrumentsNode}
			</div>
			<div className="collection-info__row-2">{descriptionNode}</div>
		</div>
	);
};
