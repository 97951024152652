import './PopupContainer.scss';

import React, { useRef } from 'react';

import { CloseButton } from '../CloseButton/CloseButton';
import { useClickAway } from 'react-use';

type PopupContainerProps = {
	isOpened: boolean;
	noDarkBackground?: boolean;
	closePopupCallback: () => void;
};

export const PopupContainer: React.FC<
	React.PropsWithChildren<PopupContainerProps>
> = ({ isOpened, noDarkBackground, closePopupCallback, children }) => {
	const refPopup = useRef<HTMLDivElement>(null);

	useClickAway(refPopup, () => closePopupCallback());

	const popupDisplay = isOpened ? 'block' : 'none';
	const noDarkBackgroundClass = noDarkBackground
		? 'popup-container__background--no'
		: '';

	return (
		<div
			className={`popup-container__background ${noDarkBackgroundClass}`}
			style={{ display: popupDisplay }}
		>
			<div ref={refPopup} className="popup-container">
				<div className="popup-container__content">
					<CloseButton
						topPx={10}
						rightPx={14}
						closePopupCallback={closePopupCallback}
					/>
					{children}
				</div>
			</div>
		</div>
	);
};
