import './ProfileSettings.scss';

import React, { useEffect, useState } from 'react';

import { BubbleButton } from '../../Buttons/BubbleButton/BubbleButton';
import { CheckBoxInput } from '../../FormInputs/CheckBoxInput/CheckBoxInput';
import { Errors } from '../../../types/api-error.type';
import { ImageInput } from '../../FormInputs/ImageInput/ImageInput';
import { TextInput } from '../../FormInputs/TextInput/TextInput';

type ProfileSettingsProps = {
	initialData: {
		avatarUrl: string | null;
		pageUrl: string;
		isHiddenFromUnauthorizedUsers: boolean;
	};
	isEditingLoading: boolean;
	submitEditing: (
		avatar: File | null,
		avatarUrl: string | null,
		pageUrl: string,
		isHiddenFromUnauthorizedUsers: boolean,
	) => void;
	errors: Errors;
	setErrors: (errors: Errors) => void;
};

export const ProfileSettings: React.FC<ProfileSettingsProps> = ({
	initialData,
	isEditingLoading,
	submitEditing,
	errors,
}) => {
	const [isChanged, setIsChanged] = useState<boolean>(false);
	const [avatar, setAvatar] = useState<File | null>(null);
	const [avatarUrl, setAvatarUrl] = useState<string | null>(
		initialData.avatarUrl,
	);
	const [pageUrl, setPageUrl] = useState<string>(initialData.pageUrl);
	const [isHiddenFromUnauthorizedUsers, setIsHiddenFromUnauthorizedUsers] =
		useState<boolean>(initialData.isHiddenFromUnauthorizedUsers);

	useEffect(() => {
		if (
			avatar !== null ||
			avatarUrl !== initialData.avatarUrl ||
			pageUrl !== initialData.pageUrl ||
			isHiddenFromUnauthorizedUsers !==
				initialData.isHiddenFromUnauthorizedUsers
		) {
			setIsChanged(true);
		} else {
			setIsChanged(false);
		}
	}, [
		avatar,
		avatarUrl,
		pageUrl,
		isHiddenFromUnauthorizedUsers,
		initialData,
	]);

	const submit = async () => {
		submitEditing(
			avatar,
			avatarUrl,
			pageUrl,
			isHiddenFromUnauthorizedUsers,
		);
	};

	const pageUrlError = errors['pageUrl'];
	const pageUrlInputNode: React.ReactNode = (
		<div className="profile-settings__input">
			<TextInput
				label="URL страницы"
				value={pageUrl}
				setValueCallback={setPageUrl}
				error={pageUrlError && pageUrlError[0]}
			/>
		</div>
	);

	const imageError = errors['picture_url'] || errors['picture'];
	const avatarInputNode: React.ReactNode = (
		<div className="profile-settings__input">
			<ImageInput
				label="Аватар"
				file={avatar}
				setFile={setAvatar}
				imageUrl={avatarUrl}
				setImageUrl={setAvatarUrl}
				error={imageError && imageError[0]}
			/>
		</div>
	);

	const isHiddenFromUnauthorizedUsersError =
		errors['is_hidden_from_unauthorized_users'];
	const checkBoxInputNode: React.ReactNode = (
		<div className="profile-settings__input">
			<CheckBoxInput
				label="Cкрывать коллекции от неавторизованных пользователей"
				value={isHiddenFromUnauthorizedUsers}
				setValueCallback={setIsHiddenFromUnauthorizedUsers}
				error={
					isHiddenFromUnauthorizedUsersError &&
					isHiddenFromUnauthorizedUsersError[0]
				}
			/>
		</div>
	);

	const submitButton = (
		<div
			className="profile-settings__submit"
			style={{ opacity: isChanged ? 100 : 0 }}
		>
			<BubbleButton
				title="Сохранить"
				disabled={isEditingLoading}
				isLoading={isEditingLoading}
				onClick={submit}
			/>
		</div>
	);

	return (
		<div className="profile-settings__form">
			{pageUrlInputNode}
			{avatarInputNode}
			{checkBoxInputNode}
			{submitButton}
		</div>
	);
};
