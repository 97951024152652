import './Image.scss';

import React, { useState } from 'react';

type ImageProps = {
	src: string;
	height?: number;
	maxHeight?: number;
	maxWidth?: number;
	borderRadius?: string;
};

export const Image: React.FC<ImageProps> = ({
	src,
	height,
	maxHeight,
	maxWidth,
	borderRadius,
}) => {
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const loaderNode: React.ReactNode = isLoading && (
		<div className="loader">
			<div className="loadingio-spinner-disk-23pll9qqddb">
				<div className="ldio-l8fyn3jmar">
					<div>
						<div></div>
						<div></div>
					</div>
				</div>
			</div>
		</div>
	);

	const imageNode: React.ReactNode = (
		<img
			style={{
				borderRadius: borderRadius,
			}}
			className="image__img"
			src={src}
			alt=""
			onLoad={() => setIsLoading(false)}
		/>
	);

	return (
		<div
			className="image"
			style={{
				height: `${height}px`,
				maxHeight: `${maxHeight}px`,
				maxWidth: `${maxWidth}px`,
			}}
		>
			{loaderNode}
			{imageNode}
		</div>
	);
};
