import './App.scss';

import { Route, Routes } from 'react-router-dom';
import {
	URL_CART_PATH,
	URL_LOGIN_PATH,
	URL_NEWS_PATH,
	URL_NOT_FOUND,
	URL_PENDING_CART_PATH,
	URL_REGISTER_PATH,
	URL_SETTINGS_INVITE_SUBPATH,
	URL_SETTINGS_PATH,
	URL_SETTINGS_PROFILE_SUBPATH,
	URL_TELEGRAM_AUTH_PATH,
	URL_USER_COLLECTION_PATH,
	URL_USER_PATH,
} from '../../constants/urls.constants';

import { CollectionPage } from '../CollectionPage/CollectionPage';
import { Events } from '../../smart_components/Events/Events';
import { FullCartPage } from '../FullCartPage/FullCartPage';
import { HeaderMenu } from '../../smart_components/HeaderMenu/HeaderMenu';
import { InviteSettingsPage } from '../Settings/InviteSettingsPage/InviteSettingsPage';
import { LoginPage } from '../Auth/LoginPage';
import { NewsPage } from '../NewsPage/NewsPage';
import { NotFoundPage } from '../NotFoundPage/NotFoundPage';
import { PendingCartsPage } from '../PendingCartsPage/PendingCartsPage';
import { ProfileSettingsPage } from '../Settings/ProfileSettingsPage/ProfileSettingsPage';
import { ProtectedRoute } from '../ProtectedRoute/ProtectedRoute';
import React from 'react';
import { RegisterPage } from '../Auth/RegisterPage';
import { SettingsPage } from '../Settings/SettingsPage';
import { TelegramAuthPage } from '../Auth/TelegramAuthPage';
import { UserPage } from '../UserPage/UserPage';

function App(): React.ReactElement {
	return (
		<div className="App">
			<HeaderMenu />
			<Routes>
				<Route path={URL_REGISTER_PATH} element={<RegisterPage />} />
				<Route
					path={URL_TELEGRAM_AUTH_PATH}
					element={
						<ProtectedRoute>
							<TelegramAuthPage />
						</ProtectedRoute>
					}
				/>
				<Route path={URL_LOGIN_PATH} element={<LoginPage />} />
				<Route
					path={URL_SETTINGS_PATH}
					element={
						<ProtectedRoute>
							<SettingsPage />
						</ProtectedRoute>
					}
				>
					<Route
						path={URL_SETTINGS_PROFILE_SUBPATH}
						element={<ProfileSettingsPage />}
					/>
					<Route
						path={URL_SETTINGS_INVITE_SUBPATH}
						element={<InviteSettingsPage />}
					/>
				</Route>
				<Route
					path={URL_NEWS_PATH}
					element={
						<ProtectedRoute>
							<NewsPage />
						</ProtectedRoute>
					}
				/>
				<Route
					path={URL_PENDING_CART_PATH}
					element={
						<ProtectedRoute>
							<PendingCartsPage />
						</ProtectedRoute>
					}
				/>
				<Route
					path={URL_USER_COLLECTION_PATH}
					element={<CollectionPage />}
				/>
				<Route path={URL_CART_PATH} element={<FullCartPage />} />
				<Route path={URL_USER_PATH} element={<UserPage />} />

				<Route path={URL_NOT_FOUND} element={<NotFoundPage />} />
				<Route path="*" element={<NotFoundPage />} />
			</Routes>
			<Events />
		</div>
	);
}

export default App;
