export interface PaginatedDataResponse<T> {
	meta: {
		page: number;
		total_pages: number;
		total_objects: number;
		per_page: number;
		next_page: number | null;
		prev_page: number | null;
	};
	data: Array<T>;
}
export interface CursorPaginatedDataResponse<T> {
	meta: { next_cursor: string | null };
	data: Array<T>;
}

export interface RepositoryResponse {
	status: string;
	statusCode: number;
	data: any;
}

const baseFetchOptions = {
	headers: {
		'Content-Type': 'application/json',
	},
};

const getCsrf = (): string | undefined => {
	const csrfCookieName = 'csrftoken';

	const cookies = Object.fromEntries(
		document.cookie
			.split('; ')
			.map((v) => v.split(/=(.*)/s).map(decodeURIComponent)),
	);
	return cookies[csrfCookieName];
};

export const request = async (
	url: string,
	options: {
		body?: string | FormData;
		method: string;
	},
	noBaseOptions?: boolean,
): Promise<RepositoryResponse> => {
	const repoOptions = noBaseOptions ? { headers: {} } : baseFetchOptions;
	repoOptions.headers = { ...repoOptions.headers, 'X-CSRFTOKEN': getCsrf() };

	options = {
		...repoOptions,
		...options,
	};

	const response = await fetch(url, options);

	const status = response.statusText;
	const statusCode = response.status;

	try {
		const data = await response.json();
		return {
			status,
			statusCode,
			data,
		};
	} catch {
		return {
			status,
			statusCode,
			data: {},
		};
	}
};
