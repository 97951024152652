import { RepositoryResponse, request } from '../request.repository';

import { ERROR_REASON_PERMISSION_DENIED } from '../../constants/api-errors.constants';
import { FullCartEntity } from './../../entities/full-cart.entity';
import { URL_API_CART_GET_FULL } from '../../constants/urls.constants';

export interface ErrorDataResponse {
	[key: string]: Array<string>;
}

export interface ServiceResponseSuccess {
	status: 'OK';
	data: FullCartEntity;
}

export interface ServiceResponseError {
	status: 'ERROR';
	data: Partial<ErrorDataResponse>;
	reason?: string;
}

export async function getFullCart(
	cartId: string,
): Promise<ServiceResponseSuccess | ServiceResponseError> {
	const url = URL_API_CART_GET_FULL`${cartId}`;

	const method = 'GET';
	const options = {
		method,
	};

	const response: RepositoryResponse = await request(url, options);

	if (response.status === 'OK') {
		const data: FullCartEntity = response.data.content.data;
		return {
			status: 'OK',
			data,
		};
	}

	if (response.statusCode === 403) {
		return {
			status: 'ERROR',
			data: {},
			reason: ERROR_REASON_PERMISSION_DENIED,
		};
	}

	return {
		status: 'ERROR',
		data: {},
	};
}
