import './NoContent.scss';

import React from 'react';

type NoContentProps = {
	text: string;
	width?: string;
};

export const NoContent: React.FC<NoContentProps> = ({ text, width }) => {
	return (
		<div className="no-content" style={{ width }}>
			<h1 className="no-content__text">{text}</h1>
		</div>
	);
};
