import './InviteSettings.scss';

import { Errors } from '../../../types/api-error.type';
import React from 'react';
import { TextInput } from '../../FormInputs/TextInput/TextInput';

type InviteSettingsProps = {
	inviteLink: string | null;
	invitedUsersCount: number | null;
	maxInvitedUsersCount: number | null;
	errors: Errors;
};

export const InviteSettings: React.FC<InviteSettingsProps> = ({
	inviteLink,
	invitedUsersCount,
	maxInvitedUsersCount,
	errors,
}) => {
	const helpTextNode: React.ReactNode = inviteLink ? (
		<div className="invite-settings__element">
			<p className="invite-settings__element__text">
				По этой ссылке новый пользователь может зарегистрироваться на
				Годнохабе:
			</p>
		</div>
	) : null;

	const inviteLinkInputNode: React.ReactNode = inviteLink ? (
		<div className="invite-settings__element invite-settings__element--margin-bot">
			<TextInput label="" value={inviteLink} disabled={true} />
		</div>
	) : null;

	const invitedUsersCountNode: React.ReactNode = (
		<div className="invite-settings__element">
			<p className="invite-settings__element__text invite-settings__element__text--small">
				Пользователей зарегистрировано по ссылке:{' '}
				<b>{invitedUsersCount}</b> (максимум {maxInvitedUsersCount})
			</p>
		</div>
	);

	const error = errors['non_field_errors'] && errors['non_field_errors'][0];
	const errorNode: React.ReactNode = error ? (
		<div className="invite-settings__element">
			<p className="invite-settings__element__text">{error}</p>
		</div>
	) : null;

	return (
		<div className="invite-settings">
			{helpTextNode}
			{inviteLinkInputNode}
			{invitedUsersCountNode}
			{errorNode}
		</div>
	);
};
