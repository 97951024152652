import './NotFoundPage.scss';

import { BubbleButton } from '../../components/Buttons/BubbleButton/BubbleButton';
import { Link } from 'react-router-dom';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../stores/RootStore';

export const NotFoundPage: React.FC = observer(() => {
	const { profileStore } = useRootStore();

	const navigateButtonTitle = profileStore.isAuth ? 'Домой' : 'На главную';
	const navigateButton = (
		<Link to={profileStore.homePagePath}>
			<BubbleButton
				title={navigateButtonTitle}
				disabled={false}
				isLoading={false}
			/>
		</Link>
	);
	return (
		<div className="not-found">
			<h1 className="not-found__404">404</h1>
			{navigateButton}
		</div>
	);
});
