import './SettingsPage.scss';

import { NavLink, Outlet } from 'react-router-dom';
import {
	URL_SETTINGS_INVITE_SUBPATH,
	URL_SETTINGS_PATH,
	URL_SETTINGS_PROFILE_SUBPATH,
} from '../../constants/urls.constants';

import { PageWithSidePanel } from '../../components/Pages/PageWithSidePanel';
import React from 'react';
import { SidePanel } from '../../components/SidePanel/SidePanel';
import menuIconSrc from '../../assets/icons/common/menu.svg';
import { observer } from 'mobx-react-lite';

export const SettingsPage: React.FC = observer(() => {
	const navLinkClass = ({ isActive }: { isActive: boolean }) =>
		isActive
			? 'settings__menu-option settings__menu-option--active'
			: 'settings__menu-option';

	const menuNode: React.ReactNode = (
		<div className="settings__menu">
			<NavLink
				className={navLinkClass}
				to={`${URL_SETTINGS_PATH}/${URL_SETTINGS_PROFILE_SUBPATH}`}
			>
				Профиль
			</NavLink>
			<NavLink
				className={navLinkClass}
				to={`${URL_SETTINGS_PATH}/${URL_SETTINGS_INVITE_SUBPATH}`}
			>
				Приглашения на Годнохаб
			</NavLink>
		</div>
	);

	return (
		<div className="settings">
			<PageWithSidePanel
				sidePanel={
					<SidePanel toggleIconSrc={menuIconSrc}>
						{menuNode}
					</SidePanel>
				}
				content={
					<div className="settings__content">
						<Outlet />
					</div>
				}
			/>
		</div>
	);
});
