import { URL_MAIN_PATH, URL_USER_PAGE } from '../constants/urls.constants';

import { EmojiStore } from './EmojiStore';
import { LOCAL_STORAGE_KEY_PROFILE_INFO } from '../constants/local-storage.constants';
import { ProfileEntity } from '../entities/profile.entity';
import { makeAutoObservable } from 'mobx';

export class ProfileStore {
	profileInfo: ProfileEntity | null = null;
	emojiStore: EmojiStore = new EmojiStore();

	constructor() {
		makeAutoObservable(this);

		const profileInfo = this.getLSProfileInfo();
		if (profileInfo) {
			this.setProfileInfo(profileInfo);
		}
	}

	setProfileInfo(profileInfo: ProfileEntity): void {
		this.profileInfo = profileInfo;
		this.setLSProfileInfo(profileInfo);
	}

	logout(): void {
		this.profileInfo = null;
		this.removeLSProfileInfo();
	}

	setLSProfileInfo(profileInfo: ProfileEntity): void {
		window.localStorage.setItem(
			LOCAL_STORAGE_KEY_PROFILE_INFO,
			JSON.stringify(profileInfo),
		);
	}

	getLSProfileInfo(): ProfileEntity | undefined {
		const profileInfo = window.localStorage.getItem(
			LOCAL_STORAGE_KEY_PROFILE_INFO,
		);
		if (profileInfo) {
			return JSON.parse(profileInfo);
		}
	}

	removeLSProfileInfo(): void {
		window.localStorage.removeItem(LOCAL_STORAGE_KEY_PROFILE_INFO);
	}

	setNewsFeedChecked(): void {
		if (this.profileInfo === null) {
			return;
		}

		this.profileInfo.hasFeedNews = false;
	}

	get isAuth(): boolean {
		return this.profileInfo !== null;
	}

	get homePagePath(): string {
		const path =
			this.profileInfo !== null
				? URL_USER_PAGE`${this.profileInfo.pageUrl}`
				: URL_MAIN_PATH;
		return path;
	}

	get userId(): number | null {
		return this.profileInfo === null ? null : this.profileInfo.userId;
	}
}
