import './SingleChoiceInput.scss';

import React from 'react';
import { TagBubble } from '../../TagBubble/TagBubble';

export interface Option {
	id: number | string;
	title: string;
}

type SingleChoiceInputProps = {
	label: string;
	error: string | undefined;
	options: Option[];
	selectedOption: Option | null;
	setSelectedOptionCallback: (option: Option | null) => void;
};

export const SingleChoiceInput: React.FC<SingleChoiceInputProps> = ({
	label,
	error,
	options,
	selectedOption,
	setSelectedOptionCallback,
}) => {
	const renderOption = (option: Option, index: number): React.ReactNode => {
		const isOptionSelected =
			selectedOption !== null && option.id === selectedOption.id;

		return (
			<div
				onClick={() => setSelectedOptionCallback(option)}
				className="single-choice-input__option"
				key={index}
			>
				<TagBubble
					isPicked={isOptionSelected}
					title={option.title}
					size="small"
				/>
			</div>
		);
	};

	const optionsNodes: React.ReactNode[] = options.map(renderOption);

	const errorNode: React.ReactNode = error && (
		<span className="single-choice-input__error">{error}</span>
	);
	return (
		<div className="single-choice-input">
			<label className="single-choice-input__label">{label}</label>
			<div className="single-choice-input__options">{optionsNodes}</div>
			{errorNode}
		</div>
	);
};
