import './CartTagsInput.scss';

import React from 'react';
import { Tag } from '../../../entities/tag.entity';
import { TagBubble } from '../../TagBubble/TagBubble';

type CartTagsInputProps = {
	inputTag: string;
	setInputTag: (inputTag: string) => void;
	tags: Tag[];
	selectedTags: Tag[];
	setSelectedTags: (selectedTags: Tag[]) => void;
	error: string | undefined;
};

export const CartTagsInput: React.FC<CartTagsInputProps> = ({
	inputTag,
	setInputTag,
	tags,
	selectedTags,
	setSelectedTags,
	error,
}) => {
	const filterTagsPossible = (inputTag: string | null): Tag[] => {
		const tagsUnselected = tags.filter(
			(tag) =>
				!selectedTags.find(
					(selectedTag) => selectedTag.name === tag.name,
				),
		);
		let tagsPossibleFiltered = tagsUnselected;
		if (inputTag) {
			tagsPossibleFiltered = tagsPossibleFiltered.filter((tag) =>
				tag.name
					.toLocaleLowerCase()
					.includes(inputTag.toLocaleLowerCase()),
			);
			const findExistingTagFromInput = (tag: Tag) =>
				tag.name.toLocaleLowerCase() === inputTag.toLocaleLowerCase();
			const existingTagFromInput =
				tagsPossibleFiltered.find(findExistingTagFromInput) ||
				selectedTags.find(findExistingTagFromInput);
			const isNewTag = existingTagFromInput ? false : true;
			tagsPossibleFiltered = isNewTag
				? [{ pk: 0, name: inputTag }].concat(tagsPossibleFiltered)
				: tagsPossibleFiltered;
		}

		return tagsPossibleFiltered;
	};
	const tagsPossibleFiltered = filterTagsPossible(inputTag);

	const selectTag = (tag: Tag) => {
		setSelectedTags([...selectedTags, tag]);
		setInputTag('');
	};
	const inputTagEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			if (inputTag) {
				const currentTag = tagsPossibleFiltered.find(
					(tag) =>
						tag.name.toLocaleLowerCase() ===
						inputTag.toLocaleLowerCase(),
				);
				currentTag && selectTag(currentTag);
			}
		}
	};
	const tagsPossibleChoices: React.ReactNode[] = tagsPossibleFiltered.map(
		(tag, index) => {
			const { pk, name } = tag;
			const isNewTag = pk === 0;
			return (
				<div
					key={index}
					className="cart-tags-input__option"
					onClick={() => selectTag(tag)}
				>
					<TagBubble
						isPicked={false}
						title={name}
						size="small"
						noBackground={isNewTag}
					/>
				</div>
			);
		},
	);
	const tagsSelectedChoices: React.ReactNode[] = selectedTags.map(
		(tag, index) => {
			const { name } = tag;
			return (
				<div
					key={index}
					className={'cart-tags-input__option'}
					onClick={() =>
						setSelectedTags(
							selectedTags.filter(
								(selectedTag) => selectedTag.name !== name,
							),
						)
					}
				>
					<TagBubble isPicked={true} title={name} size="small" />
				</div>
			);
		},
	);
	const tagsChoicesInput = (
		<div className="cart-tags-input__tags-input">
			<input
				type="text"
				name="tags"
				id="tags"
				placeholder="Название тэга"
				onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
					setInputTag(e.target.value)
				}
				onKeyDown={inputTagEnter}
				className="cart-tags-input__input"
				value={inputTag}
			/>
			<div className="cart-tags-input__tags-possible">
				{tagsPossibleChoices}
			</div>
			<div className="cart-tags-input__tags-selected">
				{tagsSelectedChoices}
			</div>
		</div>
	);

	const errorNode: React.ReactNode = error && (
		<span className="cart-tags-input__error">{error}</span>
	);
	return (
		<div className="cart-tags-input">
			<label className="cart-tags-input__label" htmlFor="tags">
				Тэги
			</label>
			<div className="cart-tags-input__tags-input-container">
				{tagsChoicesInput}
			</div>
			{errorNode}
		</div>
	);
};
