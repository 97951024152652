import './FullCart.scss';

import React, { useState } from 'react';

import { CartTagsInput } from '../../FormInputs/TagsInput/CartTagsInput';
import { Link } from 'react-router-dom';
import { Tag } from '../../../entities/tag.entity';
import { TagBubble } from '../../TagBubble/TagBubble';
import { URL_USER_COLLECTION_PAGE } from '../../../constants/urls.constants';

type FullCartTagsProps = {
	isCartChanging: boolean;
	setIsCartChanging: (isChanging: boolean) => void;

	collectionId: number;
	collectionTags: Tag[];
	tags: Tag[];
	setSelectedTagsCallback: (tags: Tag[]) => void;

	isAllowChanging: boolean;
	isAllowChangingClass: string;
	error: string | undefined;
};

export const FullCartTags: React.FC<FullCartTagsProps> = ({
	isCartChanging,
	setIsCartChanging,
	collectionId,
	collectionTags,
	tags,
	setSelectedTagsCallback,
	isAllowChanging,
	isAllowChangingClass,
	error,
}) => {
	const [isChanging, setIsChanging] = useState<boolean>(false);
	const [inputTag, setInputTag] = useState<string>('');

	const inputNode: React.ReactNode = (
		<CartTagsInput
			inputTag={inputTag}
			setInputTag={setInputTag}
			tags={collectionTags}
			selectedTags={tags}
			setSelectedTags={setSelectedTagsCallback}
			error={error}
		/>
	);

	const renderTagNode = (tag: Tag): React.ReactNode => {
		const { pk, name } = tag;
		return (
			<Link
				to={{
					pathname: URL_USER_COLLECTION_PAGE`${collectionId.toString()}`,
					search: `?tag_id=${pk}`,
				}}
				key={pk}
			>
				<div className="full-cart__tag" key={pk}>
					<TagBubble title={name} isPicked={false} />
				</div>
			</Link>
		);
	};

	const tagsNodes = tags.map(renderTagNode);
	const noTagsNode = <span className="full-cart__tags-no">Без тэгов</span>;

	const tagsContentNode: React.ReactNode =
		tags.length > 0 ? tagsNodes : noTagsNode;

	const displayNode: React.ReactNode = (
		<div>
			<span
				className={`full-cart__label ${isAllowChangingClass}`}
				onClick={() => {
					setIsChanging(isAllowChanging);
					setIsCartChanging(isAllowChanging);
				}}
			>
				Тэги
			</span>
			<div className="full-cart__tags">{tagsContentNode}</div>
		</div>
	);

	const finalNode: React.ReactNode =
		isChanging && isCartChanging ? inputNode : displayNode;

	return <div>{finalNode}</div>;
};
