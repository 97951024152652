import './NewsFeedCart.scss';

import { AllEmojisEntity, EmojiEntity } from '../../../entities/emoji.entity';
import {
	EVENT_MESSAGE_CART_CREATED,
	EVENT_MESSAGE_PENDING_CART_CREATED,
} from '../../../constants/events.constants';
import {
	URL_USER_COLLECTION_PAGE,
	URL_USER_PAGE,
} from '../../../constants/urls.constants';

import { Avatar } from '../../Users/Avatar/Avatar';
import { CartEntity } from '../../../entities/cart.entity';
import { CreateCart } from '../../../smart_components/CartControl/CreateCart';
import { CreatePendingCart } from '../../../smart_components/CartControl/PendingCart/CreatePendingCart';
import { FullCartEntity } from '../../../entities/full-cart.entity';
import { FullCartPopup } from '../../../smart_components/popups/FullCartPopup/FullCartPopup';
import { HorizontalCart } from '../HorizontalCart/HorizontalCart';
import { Icon } from '../../Icon/Icon';
import { Link } from 'react-router-dom';
import { NewsFeedCartEntity } from '../../../entities/news-feed.entity';
import { PendingCartEntity } from '../../../entities/pending-cart.entity';
import { PopupContainer } from '../../PopupContainer/PopupContainer';
import React from 'react';
import { TagBubble } from '../../TagBubble/TagBubble';
import newDotImgSrc from '../../../assets/icons/common/new-dot.svg';
import { useRootStore } from '../../../stores/RootStore';

type NewsFeedCartProps = {
	newsFeedCart: NewsFeedCartEntity;
	fullCart: FullCartEntity | null;
	isOpenedFullCart: boolean;
	setIsOpenedFullCartCallback: (isOpened: boolean) => void;

	isRepostingCart: boolean;
	setIsRepostingCartCallback: (isReposting: boolean) => void;

	isToPendingCart: boolean;
	setToPendingCartCallback: (isToPending: boolean) => void;

	allEmojis: AllEmojisEntity;
	lastUsedEmojis: EmojiEntity[];
	pushEmojiCallback: (emojiName: string, add: boolean) => void;

	isMyCart: boolean;

	editedCallback: (cart: CartEntity) => void;
	deletedCallback: () => void;
};

export const NewsFeedCart: React.FC<NewsFeedCartProps> = ({
	newsFeedCart,
	isOpenedFullCart,
	setIsOpenedFullCartCallback,
	isRepostingCart,
	setIsRepostingCartCallback,
	isToPendingCart,
	setToPendingCartCallback,
	fullCart,
	allEmojis,
	lastUsedEmojis,
	pushEmojiCallback,
	isMyCart,
	editedCallback,
	deletedCallback,
}) => {
	const { eventsStore } = useRootStore();

	const { cart, user, isViewed } = newsFeedCart;

	const userPageUrl = URL_USER_PAGE`${user.pageUrl}`;
	const collectionPageUrl = URL_USER_COLLECTION_PAGE`${cart.collection.id.toString()}`;

	const cartInfoNode: React.ReactNode = (
		<div className="news-feed-cart__cart-info">
			<div className="news-feed-cart__cart-info__block">
				<span
					className="news-feed-cart__cart-info__item news-feed-cart__cart-info__date-created"
					title={`Дата добавления: ${newsFeedCart.dateCreated}`}
				>
					{newsFeedCart.dateCreatedRelative}
				</span>
			</div>

			<div className="news-feed-cart__cart-info__block">
				<Link
					className="news-feed-cart__cart-info__item"
					to={userPageUrl}
				>
					<TagBubble
						isPicked={false}
						title={user.username}
						size="small"
					>
						<Avatar
							avatarUrl={user.avatar}
							username={user.username}
							size="small"
						/>
					</TagBubble>
				</Link>
				<Link
					className="news-feed-cart__cart-info__item"
					to={collectionPageUrl}
				>
					<TagBubble
						isPicked={false}
						title={cart.collection.title}
						size="small"
					/>
				</Link>
			</div>
		</div>
	);

	const fullCartNode: React.ReactNode = isOpenedFullCart && (
		<PopupContainer
			isOpened={isOpenedFullCart}
			closePopupCallback={() => setIsOpenedFullCartCallback(false)}
		>
			<FullCartPopup
				fullCart={fullCart}
				isMyCart={isMyCart}
				allEmojis={allEmojis}
				lastUsedEmojis={lastUsedEmojis}
				pushEmojiCallback={pushEmojiCallback}
				editedCallback={editedCallback}
				deletedCallback={deletedCallback}
			/>
		</PopupContainer>
	);

	const createdCallback = (cart: CartEntity) => {
		eventsStore.pushSuccessEvent(
			EVENT_MESSAGE_CART_CREATED`${cart.title}${cart.collection.title}`,
		);
		setIsRepostingCartCallback(false);
	};
	const repostCartNode: React.ReactNode = isRepostingCart && (
		<PopupContainer
			isOpened={isRepostingCart}
			closePopupCallback={() => setIsRepostingCartCallback(false)}
		>
			<CreateCart
				createdCallback={createdCallback}
				initialCart={cart}
				isRepost={true}
			/>
		</PopupContainer>
	);

	const createdPendingCallback = (pendingCart: PendingCartEntity) => {
		eventsStore.pushSuccessEvent(
			EVENT_MESSAGE_PENDING_CART_CREATED`${pendingCart.title}`,
		);
		setToPendingCartCallback(false);
	};
	const toPendingCartNode: React.ReactNode = isToPendingCart && (
		<PopupContainer
			isOpened={isToPendingCart}
			closePopupCallback={() => setToPendingCartCallback(false)}
		>
			<CreatePendingCart
				createdCallback={createdPendingCallback}
				initialCart={cart}
			/>
		</PopupContainer>
	);

	const isMyCartClass = isMyCart ? 'news-feed-cart__new--my-cart' : '';
	const newCartNode: React.ReactNode = (
		<div className={`news-feed-cart__new ${isMyCartClass}`}>
			{!isViewed && <Icon src={newDotImgSrc} title="new" sizePx={6} />}
		</div>
	);

	return (
		<div className="news-feed-cart" key={cart.id}>
			{fullCartNode}
			{repostCartNode}
			{toPendingCartNode}
			<div className="news-feed-cart__cart">
				<HorizontalCart
					cart={cart}
					isMyCart={isMyCart}
					isAuth={true}
					maxTagsCount={5}
					allEmojis={allEmojis}
					lastUsedEmojis={lastUsedEmojis}
					openFullCartCallback={() =>
						setIsOpenedFullCartCallback(true)
					}
					repostCartCallback={() => setIsRepostingCartCallback(true)}
					toPendingCartCallback={() => setToPendingCartCallback(true)}
					pushEmojiCallback={pushEmojiCallback}
					colorLinePosition="left"
				/>
			</div>

			{newCartNode}
			{cartInfoNode}
		</div>
	);
};
