import { useState } from 'react';

export const useLimitItems = (items: Array<any>, limit: number) => {
	const [limited, setLimited] = useState<boolean>(true);

	const allItemsCount = items.length;
	const limitItemsCount = allItemsCount - limit;
	const isLimited = limitItemsCount > 0 && limited;

	const resultItemsCount = isLimited ? limit : allItemsCount;
	const resultItems = items.slice(0, resultItemsCount);

	return [isLimited, limitItemsCount, resultItems, setLimited] as const;
};
