import './EmojiPanel.scss';

import {
	AllEmojisEntity,
	EmojiEntity,
	ReactedEmojiEntity,
} from '../../../entities/emoji.entity';
import React, { useState } from 'react';

import { EmojiPicker } from '../EmojiPicker/EmojiPicker';
import { Icon } from '../../Icon/Icon';
import { ReactedEmoji } from '../ReactedEmoji/ReactedEmoji';
import emojiAddImgSrc from '../../../assets/icons/cart/emoji.svg';
import plusImgSrc from '../../../assets/icons/common/plus.svg';

type EmojiPanelProps = {
	allEmojis: AllEmojisEntity;
	lastUsedEmojis: EmojiEntity[];
	emojiList: ReactedEmojiEntity[];
	pushEmojiCallback: (emojiName: string, add: boolean) => void;
	isEmojiAddDisplayed: boolean;
};

export const EmojiPanel: React.FC<EmojiPanelProps> = ({
	allEmojis,
	lastUsedEmojis,
	emojiList,
	pushEmojiCallback,
	isEmojiAddDisplayed,
}) => {
	const [isEmojiPickerOpened, setIsEmojiPickerOpened] =
		useState<boolean>(false);
	const [isAllEmojiDisplay, setIsAllEmojiDisplay] = useState<boolean>(false);

	const renderEmoji = (emojiEntity: ReactedEmojiEntity): React.ReactNode => {
		return (
			<div className="emoji-panel__emoji-item" key={emojiEntity.name}>
				<ReactedEmoji
					emojiEntity={emojiEntity}
					pushEmojiCallback={pushEmojiCallback}
				/>
			</div>
		);
	};
	const emojisToDisplay = isAllEmojiDisplay
		? emojiList
		: emojiList.slice(0, 6);
	const emojiNodes = emojisToDisplay.map(renderEmoji);

	const emojiPickerNode: React.ReactNode = isEmojiPickerOpened && (
		<div className="emoji-panel__emoji-picker">
			<EmojiPicker
				allEmojis={allEmojis}
				lastUsedEmojis={lastUsedEmojis}
				pushEmojiCallback={pushEmojiCallback}
				closePickerCallback={() => setIsEmojiPickerOpened(false)}
			/>
		</div>
	);

	const hiddenEmojisCount = emojiList.length - emojisToDisplay.length;
	const showAllEmojisNode: React.ReactNode =
		hiddenEmojisCount > 0 ? (
			<div className="emoji-panel__util">
				<div
					className="emoji-panel__util__icon"
					onClick={() => setIsAllEmojiDisplay(true)}
					title="Показать все"
				>
					<Icon src={plusImgSrc} title="emoji" sizePx={16} />
					<span>{hiddenEmojisCount}</span>
				</div>
			</div>
		) : null;

	const addEmojiNode: React.ReactNode = (isEmojiAddDisplayed ||
		isEmojiPickerOpened) && (
		<div className="emoji-panel__util">
			{emojiPickerNode}
			<div
				className="emoji-panel__util__icon"
				onClick={() => setIsEmojiPickerOpened(true)}
				title="Добавить реакцию"
			>
				<Icon src={emojiAddImgSrc} title="emoji" sizePx={16} />
			</div>
		</div>
	);

	return (
		<div className="emoji-panel">
			<div className="emoji-panel__emoji-list">
				{emojiNodes}
				{showAllEmojisNode}
				{addEmojiNode}
			</div>
		</div>
	);
};
