import {
	EVENT_DEFAULT_MESSAGE_TIMEOUT,
	EVENT_TYPE_ERROR,
	EVENT_TYPE_SUCCESS,
} from '../constants/events.constants';

import { EventEntity } from '../entities/event.entity';
import { makeAutoObservable } from 'mobx';

export class EventsStore {
	events: EventEntity[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	pushEvent(event: EventEntity): void {
		this.events.push(event);
	}

	shiftEvent(): EventEntity | undefined {
		return this.events.shift();
	}

	pushUnknownErrorEvent(): void {
		const event: EventEntity = {
			type: EVENT_TYPE_ERROR,
			timeout: EVENT_DEFAULT_MESSAGE_TIMEOUT,
			message: 'Что-то пошло не так. Попробуй перезагрузить страницу.',
		};
		this.pushEvent(event);
	}

	pushErrorEvent(message: string): void {
		const event: EventEntity = {
			type: EVENT_TYPE_ERROR,
			timeout: EVENT_DEFAULT_MESSAGE_TIMEOUT,
			message: message,
		};
		this.pushEvent(event);
	}

	pushSuccessEvent(message: string): void {
		const event: EventEntity = {
			type: EVENT_TYPE_SUCCESS,
			timeout: EVENT_DEFAULT_MESSAGE_TIMEOUT,
			message: message,
		};
		this.pushEvent(event);
	}
}
