import './Auth.scss';

import {
	ErrorDataResponse,
	TelegramAuthService,
} from '../../services/auth/telegram-auth.service';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { LoadingData } from '../../components/LoadingData/LoadingData';
import { SecondaryButton } from '../../components/Buttons/SecondaryButton/SecondaryButton';
import { TelegramAuthForm } from '../../components/Auth/TelegramAuthForm';
import { TelegramUser } from '../../utils/reactTelegramAuthButton';
import { URL_USER_PAGE } from '../../constants/urls.constants';
import { useRootStore } from '../../stores/RootStore';

export const TelegramAuthPage: React.FC = () => {
	const navigate = useNavigate();

	const { profileStore } = useRootStore();

	const [errors, setErrors] = useState<Partial<ErrorDataResponse>>({});
	const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

	useEffect(() => {
		const { profileInfo } = profileStore;
		if (profileInfo?.isTelegramAuthorized) {
			navigate(URL_USER_PAGE`${profileStore.profileInfo!.pageUrl}`);
		}
	}, [navigate, profileStore, profileStore.profileInfo]);

	const onTelegramAuth = async (telegramUser: TelegramUser) => {
		setIsSubmitLoading(true);

		const serviceResponse = await TelegramAuthService(telegramUser);
		if (serviceResponse.status === 'OK') {
			navigate(URL_USER_PAGE`${profileStore.profileInfo!.pageUrl}`);
		} else {
			setErrors(serviceResponse.data);
		}

		setIsSubmitLoading(false);
	};

	const telegramAuthFormNode: React.ReactNode = !isSubmitLoading && (
		<TelegramAuthForm errors={errors} submitCallback={onTelegramAuth} />
	);

	const loadingDataNode: React.ReactNode = (
		<LoadingData isLoading={isSubmitLoading} />
	);

	return (
		<div className="auth-page">
			<p>
				Чтобы получить полный достут к Годнохабу нужно авторизоваться:
			</p>
			{telegramAuthFormNode}
			{loadingDataNode}
			<Link
				to={URL_USER_PAGE`${profileStore.profileInfo!.pageUrl}`}
				className="auth-page__change_step"
			>
				<SecondaryButton title="Пропустить" />
			</Link>
		</div>
	);
};
