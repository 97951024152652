import './UserInfoPageBlock.scss';

import React, { useEffect, useState } from 'react';

import { FullUserEntity } from '../../entities/full-user.entity';
import { PopupContainer } from '../../components/PopupContainer/PopupContainer';
import { SubscribeService } from '../../services/subscription/subscribe.service';
import { SubscriptionsPopup } from '../popups/SubscriptionsPopup/SubscriptionsPopup';
import { UnsubscribeService } from '../../services/subscription/unsubscribe.service';
import { UserInfoWide } from '../../components/Users/UserInfoWide/UserInfoWide';
import { useRootStore } from '../../stores/RootStore';

type UserInfoPageBlockProps = {
	profileId: number | null;
	user: FullUserEntity | null;
	setIsFollowingCallback: (isFollowing: boolean) => void;
};

export const UserInfoPageBlock: React.FC<UserInfoPageBlockProps> = ({
	profileId,
	user,
	setIsFollowingCallback,
}) => {
	const { eventsStore } = useRootStore();

	const [isSubscribersOpened, setIsSubscribersOpened] =
		useState<boolean>(false);
	const [isSignablesOpened, setIsSignablesOpened] = useState<boolean>(false);

	useEffect(() => {
		setIsSubscribersOpened(false);
		setIsSignablesOpened(false);
	}, [user?.userId]);

	const subscribe = async () => {
		if (user === null) {
			return;
		}

		const serviceResponse = await SubscribeService(user.userId);
		if (serviceResponse.status === 'OK') {
			setIsFollowingCallback(true);
		} else {
			eventsStore.pushUnknownErrorEvent();
		}
	};

	const unsubscribe = async () => {
		if (user === null) {
			return;
		}

		const serviceResponse = await UnsubscribeService(user.userId);
		if (serviceResponse.status === 'OK') {
			setIsFollowingCallback(false);
		} else {
			eventsStore.pushUnknownErrorEvent();
		}
	};

	const subscribersContainer = user && isSubscribersOpened && (
		<PopupContainer
			isOpened={isSubscribersOpened}
			closePopupCallback={() => setIsSubscribersOpened(false)}
		>
			<SubscriptionsPopup
				type="subscribers"
				profileId={profileId}
				owner={user}
				setOwnerIsFollowingCallback={setIsFollowingCallback}
			/>
		</PopupContainer>
	);

	const signablesContainer = user && isSignablesOpened && (
		<PopupContainer
			isOpened={isSignablesOpened}
			closePopupCallback={() => setIsSignablesOpened(false)}
		>
			<SubscriptionsPopup
				type="signables"
				profileId={profileId}
				owner={user}
				setOwnerIsFollowingCallback={setIsFollowingCallback}
			/>
		</PopupContainer>
	);

	return (
		user && (
			<div>
				{subscribersContainer}
				{signablesContainer}
				<UserInfoWide
					profileId={profileId}
					user={user}
					isFull={true}
					subscribeCallback={subscribe}
					unsubscribeCallback={unsubscribe}
					openSubscribersCallback={() => setIsSubscribersOpened(true)}
					openSignablesCallback={() => setIsSignablesOpened(true)}
				/>
			</div>
		)
	);
};
