import React from 'react';
import { useDropdownState } from './DropdownContext';

export const DropdownToggle: React.FC<React.PropsWithChildren<unknown>> = ({
	children,
}) => {
	const { toggled } = useDropdownState();
	const toggleClass: string = toggled ? 'toggle--toggled' : '';

	return <div className={`toggle ${toggleClass}`}>{children}</div>;
};
