import './CheckBoxInput.scss';

import React from 'react';

type CheckBoxInputProps = {
	label: string;
	value: boolean;
	setValueCallback: (value: boolean) => void;
	error: string | undefined;
};

export const CheckBoxInput: React.FC<CheckBoxInputProps> = ({
	label,
	value,
	setValueCallback,
	error,
}) => {
	const labelNode: React.ReactNode = (
		<label className="form-checkbox-input__label" htmlFor="checkbox-input">
			{label}
		</label>
	);
	const inputNode: React.ReactNode = (
		<input
			className="form-checkbox-input__input"
			required
			type="checkbox"
			name="checkbox-input"
			onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
				setValueCallback(e.target.checked)
			}
			checked={value}
		/>
	);

	const errorNode: React.ReactNode = error && (
		<span className="form-checkbox-input__error">{error}</span>
	);
	return (
		<div className="form-checkbox-input">
			<div className="form-checkbox-input__block">
				{labelNode}
				{inputNode}
			</div>
			{errorNode}
		</div>
	);
};
