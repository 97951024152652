import { RepositoryResponse, request } from '../request.repository';

import { URL_API_NOTIFICATIONS_CHECK } from '../../constants/urls.constants';

export interface ServiceResponseSuccess {
	status: 'OK';
}

export interface ServiceResponseError {
	status: 'ERROR';
}

export async function CheckNotificationsService(): Promise<
	ServiceResponseSuccess | ServiceResponseError
> {
	const url = URL_API_NOTIFICATIONS_CHECK;
	const method = 'POST';
	const options = {
		method,
	};

	const response: RepositoryResponse = await request(url, options);

	if (response.status === 'OK') {
		return { status: 'OK' };
	}

	return { status: 'ERROR' };
}
