import React, { useState } from 'react';

import { ConfirmBlock } from '../../../components/ConfirmBlock/ConfirmBlock';
import { DeletePendingCartService } from '../../../services/pending/delete-pending-cart.service';
import { useRootStore } from '../../../stores/RootStore';

type DeletePendingCartProps = {
	cartId: number;
	cartTitle: string;
	deletedCallback: () => void;
};

export const DeletePendingCart: React.FC<DeletePendingCartProps> = ({
	cartId,
	cartTitle,
	deletedCallback,
}) => {
	const { eventsStore } = useRootStore();

	const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

	const confirmBlockTitle = `Удалить ${cartTitle}?`;
	const submitButtonName = 'Удалить';

	const submitDeletingCart = async () => {
		setIsSubmitLoading(true);
		const deleteCartResponse = await DeletePendingCartService(cartId);
		if (deleteCartResponse.status === 'OK') {
			setIsSubmitLoading(false);
			deletedCallback();

			return;
		}

		eventsStore.pushUnknownErrorEvent();
		setIsSubmitLoading(false);
	};

	return (
		<ConfirmBlock
			title={confirmBlockTitle}
			submitButtonName={submitButtonName}
			confirmCallback={submitDeletingCart}
			isLoading={isSubmitLoading}
		/>
	);
};
