import React, { useState } from 'react';

import { ConfirmBlock } from '../../ConfirmBlock/ConfirmBlock';
import { deleteCollection } from '../../../services/collections/delete-collection.service';
import { useRootStore } from '../../../stores/RootStore';

type DeleteCollectionProps = {
	collectionId: number;
	collectionTitle: string;
	collectionCartsCount: number;
	collectionDeletedCallback: () => void;
};

export const DeleteCollection: React.FC<DeleteCollectionProps> = ({
	collectionId,
	collectionTitle,
	collectionCartsCount,
	collectionDeletedCallback,
}) => {
	const { eventsStore } = useRootStore();

	const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

	const confirmBlockTitle = `Удалить ${collectionTitle}?`;
	const text =
		collectionCartsCount > 0
			? `Карточек будет удалено: ${collectionCartsCount}`
			: '';
	const submitButtonName = 'Удалить';

	const submitDeletingCollection = async () => {
		setIsSubmitLoading(true);
		const deleteCollectionResponse = await deleteCollection(collectionId);
		if (deleteCollectionResponse.status === 'OK') {
			collectionDeletedCallback();
			setIsSubmitLoading(false);
		} else {
			eventsStore.pushUnknownErrorEvent();
		}
		setIsSubmitLoading(false);
	};

	return (
		<ConfirmBlock
			title={confirmBlockTitle}
			text={text}
			submitButtonName={submitButtonName}
			confirmCallback={submitDeletingCollection}
			isLoading={isSubmitLoading}
		/>
	);
};
