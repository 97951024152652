import './CartControlButton.scss';

import React from 'react';

type CartControlButtonProps = {
	iconScr: string;
	title: string;
	callback: () => void;
};

export const CartControlButton: React.FC<CartControlButtonProps> = ({
	iconScr,
	title,
	callback,
}) => {
	return (
		<div
			className="cart-control__icon-container"
			onClick={callback}
			title={title}
		>
			<img className="cart-control__icon" src={iconScr} alt={iconScr} />
		</div>
	);
};
