import './Avatar.scss';

import React from 'react';

type AvatarSizes = 'small' | 'default' | 'large';

const AVATAR_SIZES_MAP = {
	small: '20',
	default: '40',
	large: '55',
};

type AvatarProps = {
	avatarUrl: string | null;
	username: string;
	size: AvatarSizes;
	shape?: 'circle' | 'square';
};

export const Avatar: React.FC<AvatarProps> = ({
	avatarUrl,
	username,
	size = 'default',
	shape = 'circle',
}) => {
	const avatarSize = AVATAR_SIZES_MAP[size];
	let className = 'avatar';
	switch (shape) {
		case 'circle':
			className = 'avatar avatar--circle';
			break;
		case 'square':
			className = 'avatar avatar--square';
	}

	return (
		<img
			src={avatarUrl || '/logo192.png'}
			alt={username}
			width={avatarSize}
			height={avatarSize}
			className={className}
		/>
	);
};
