import './UserInfoMini.scss';

import React, { useState } from 'react';

import { Avatar } from '../Avatar/Avatar';
import { Link } from 'react-router-dom';
import { SecondaryButton } from '../../Buttons/SecondaryButton/SecondaryButton';
import { URL_USER_PAGE } from '../../../constants/urls.constants';
import { UserEntity } from '../../../entities/user.entity';

type UserInfoMiniProps = {
	profileId: number | null;
	user: UserEntity;
	subscribeCallback: () => void;
	unsubscribeCallback: () => void;
};

export const UserInfoMini: React.FC<UserInfoMiniProps> = ({
	profileId,
	user,
	subscribeCallback,
	unsubscribeCallback,
}) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const isMe = user.userId === profileId;

	const executeCallback = (callback: () => void) => {
		if (isLoading) {
			return;
		}

		setIsLoading(true);
		callback();
		setIsLoading(false);
	};

	const userInfoNode: React.ReactNode = (
		<Link
			className="user-info-mini__info"
			to={URL_USER_PAGE`${user.pageUrl}`}
		>
			<Avatar
				avatarUrl={user.avatar}
				username={user.username}
				size={'default'}
			/>
			<h1 className="user-info-mini__username">{user.username}</h1>
		</Link>
	);

	const subscribeButton: React.ReactNode = !isMe && !user.isFollowing && (
		<SecondaryButton
			title="Подписаться на обновления"
			onClick={() => executeCallback(subscribeCallback)}
		/>
	);

	const unsubscribeButton: React.ReactNode = !isMe && user.isFollowing && (
		<SecondaryButton
			title="Отписаться"
			onClick={() => executeCallback(unsubscribeCallback)}
		/>
	);

	const instrumentsNode: React.ReactNode = profileId && (
		<div className="user-info-mini__instruments">
			{subscribeButton || unsubscribeButton}
		</div>
	);

	return (
		<div className="user-info-mini">
			{userInfoNode}
			{instrumentsNode}
		</div>
	);
};
