export interface I_EMOJI_GROUPS_NAMES_MAP {
	custom: string;
	people: string;
	body: string;
	clothing: string;
	nature: string;
	food: string;
	activities: string;
	travel: string;
	objects: string;
	symbols: string;
	flags: string;
}

export const EMOJI_GROUPS_NAMES_MAP: I_EMOJI_GROUPS_NAMES_MAP = {
	custom: 'Годное',
	people: 'Людское',
	body: 'Телесное',
	clothing: 'Материальное',
	nature: 'Природное',
	food: 'Съестное',
	activities: 'Активное',
	travel: 'Пространственное',
	objects: 'Предметное',
	symbols: 'Символическое',
	flags: 'Политическое',
};
