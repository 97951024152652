import { RepositoryResponse, request } from '../request.repository';

import { FilterItem } from '../../entities/filter-item.entity';
import { URL_API_NEWSFFED_FILTERS } from '../../constants/urls.constants';

type Filters = {
	signables: FilterItem[];
};

export interface ServiceResponseSuccess {
	status: 'OK';
	data: Filters;
}

export interface ServiceResponseError {
	status: 'ERROR';
}

export async function FetchNewsFiltersService(): Promise<
	ServiceResponseSuccess | ServiceResponseError
> {
	const method = 'GET';
	const options = {
		method,
	};

	const response: RepositoryResponse = await request(
		URL_API_NEWSFFED_FILTERS,
		options,
	);

	if (response.status === 'OK') {
		const filters: Filters = response.data.content.data;
		return {
			status: 'OK',
			data: filters,
		};
	}

	return {
		status: 'ERROR',
	};
}
