export const minifyString = (str: string, maxLength: number): string => {
	const isNeedToMinify = str.length > maxLength;

	if (isNeedToMinify) {
		const minifiedStr = str.slice(0, maxLength);

		return `${minifiedStr}...`;
	}

	return str;
};
