export enum ORDERING_CHOICES {
	ASC = 'asc',
	DSC = 'dsc',
}

export enum CARTS_ORDER_BY_CHOICES {
	DATE_CREATED = 'date_created',
	RATE = 'rate',
}

export enum PENDING_CARTS_ORDER_BY_CHOICES {
	DATE_CREATED = 'date_created',
}
