import './MenuButton.scss';

import { Link } from 'react-router-dom';
import React from 'react';

type MenuButtonProps = {
	title: React.ReactNode;
	urlPath?: string;
	onClickCallback?: () => void;
	extraInfo?: React.ReactNode;
};

export const MenuButton: React.FC<MenuButtonProps> = ({
	title,
	urlPath,
	onClickCallback,
	extraInfo,
}) => {
	const extraInfoNode: React.ReactNode = extraInfo && (
		<div className="menu-button__extra-info">{extraInfo}</div>
	);

	const linkNode: React.ReactNode = urlPath && (
		<Link to={urlPath} className="menu-button-container">
			<button className="menu-button">
				{title}
				{extraInfoNode}
			</button>
		</Link>
	);

	const buttonNode: React.ReactNode = onClickCallback && (
		<div className="menu-button-container">
			<button onClick={onClickCallback} className="menu-button">
				{title}
				{extraInfoNode}
			</button>
		</div>
	);

	return (
		<div style={{ display: 'contents' }}>
			{linkNode}
			{buttonNode}
		</div>
	);
};
