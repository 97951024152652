import './CloseButton.scss';

import React from 'react';

type CloseButtonProps = {
	topPx: number;
	rightPx: number;
	position?: 'fixed' | 'absolute';
	closePopupCallback: () => void;
};

export const CloseButton: React.FC<CloseButtonProps> = ({
	topPx,
	rightPx,
	position = 'absolute',
	closePopupCallback,
}) => {
	return (
		<div
			className="close-button"
			style={{
				top: `${topPx}px`,
				right: `${rightPx}px`,
				position: position,
			}}
			onClick={closePopupCallback}
		>
			⨯
		</div>
	);
};
