import { RepositoryResponse, request } from '../request.repository';

import { ERROR_REASON_UNAUTHORIZED } from '../../constants/api-errors.constants';
import { ProfileEntity } from '../../entities/profile.entity';
import { URL_API_AUTH_INFO } from '../../constants/urls.constants';

export interface ErrorDataResponse {
	reason: string;
}

export interface ServiceResponseSuccess {
	status: 'OK';
	data: ProfileEntity;
}

export interface ServiceResponseError {
	status: 'ERROR';
	data?: ErrorDataResponse;
}

export async function getAuthInfoService(): Promise<
	ServiceResponseSuccess | ServiceResponseError
> {
	const url = URL_API_AUTH_INFO;

	const method = 'GET';
	const options = { method };

	const response: RepositoryResponse = await request(url, options);

	if (response.status === 'OK') {
		const data: ProfileEntity = response.data.content.data;
		return {
			status: 'OK',
			data,
		};
	}

	if (response.statusCode === 401) {
		return {
			status: 'ERROR',
			data: { reason: ERROR_REASON_UNAUTHORIZED },
		};
	}

	return { status: 'ERROR' };
}
