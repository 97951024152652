import React, { useContext } from 'react';

export interface DropdownState {
	toggled: boolean;
}

export const DropdownStateContext = React.createContext<DropdownState>(
	{} as DropdownState,
);

export const useDropdownState = (): DropdownState =>
	useContext(DropdownStateContext);
