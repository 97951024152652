import './LoadingData.scss';

import React from 'react';

type LoadingDataProps = {
	isLoading: boolean;
};

export const LoadingData: React.FC<
	React.PropsWithChildren<LoadingDataProps>
> = ({ isLoading, children }) => {
	const loader = (
		<div className="loading-data__loader-container">
			<div className="loading-data__loader loading-data__loader--1"></div>
			<div className="loading-data__loader loading-data__loader--2"></div>
			<div className="loading-data__loader loading-data__loader--3"></div>
			<div className="loading-data__loader loading-data__loader--1"></div>
			<div className="loading-data__loader loading-data__loader--2"></div>
		</div>
	);
	const loadingNode: React.ReactNode = (
		<div className="loading-data--loading-container">
			{isLoading && loader}
			{children}
		</div>
	);

	return <div className="loading-data">{loadingNode}</div>;
};
