import { RepositoryResponse, request } from '../request.repository';

import { Tag } from '../../entities/tag.entity';
import { URL_API_COLLECTION_TAGS } from '../../constants/urls.constants';

export interface ServiceResponse {
	status: 'OK' | 'ERROR';
	data: Tag[];
}

async function fetchCollectionTags(
	collection_id: string,
): Promise<ServiceResponse> {
	const url = URL_API_COLLECTION_TAGS`${collection_id}`;

	const method = 'GET';
	const options = { method };

	const response: RepositoryResponse = await request(url, options);

	if (response.status === 'OK') {
		const data: Tag[] = response.data.content.data;
		return {
			status: 'OK',
			data,
		};
	}

	return {
		status: 'ERROR',
		data: [],
	};
}

export { fetchCollectionTags };
