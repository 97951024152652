import './index.css';

import App from './pages/App/App';
import React from 'react';
import { RootStateProvider } from './stores/RootStore';
import { BrowserRouter as Router } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
	<React.StrictMode>
		<Router>
			<RootStateProvider>
				<App />
			</RootStateProvider>
		</Router>
	</React.StrictMode>,
);
