import { useEffect, useRef } from 'react';

export interface TelegramLoginButtonProps {
	botName: string;
	dataOnAuth?: (user: TelegramUser) => void;
	buttonSize?: 'large' | 'medium' | 'small';
	cornerRadius?: number;
	requestAccess?: boolean;
	usePic?: boolean;
	lang?: string;
	additionalClassNames?: string;
}

export interface TelegramUser {
	id: number;
	first_name?: string;
	last_name?: string;
	username?: string;
	photo_url?: string;
	auth_date: number;
	hash: string;
}

function TelegramLogin(props: TelegramLoginButtonProps) {
	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const {
			botName,
			buttonSize = 'large',
			cornerRadius,
			requestAccess,
			usePic = true,
			dataOnAuth,
			lang = 'en',
		} = props;

		if (dataOnAuth) {
			(window as any).telegramLoginWidgetCb = dataOnAuth;
		}

		const script = document.createElement('script');

		script.src = 'https://telegram.org/js/telegram-widget.js?9';

		script.async = true;

		const attributes = {
			'data-telegram-login': botName,
			'data-size': buttonSize,
			'data-radius': cornerRadius,
			'data-request-access': requestAccess ? 'write' : undefined,
			'data-userpic': usePic,
			'data-lang': lang,
			'data-onauth': 'telegramLoginWidgetCb(user)',
		};

		for (const [k, v] of Object.entries(attributes)) {
			v !== undefined && script.setAttribute(k, `${v}`);
		}

		containerRef.current!.appendChild(script);

		return () => {
			if (containerRef.current) {
				containerRef.current.innerHTML = '';
			}

			if ((window as any).telegramLoginWidgetCb) {
				delete (window as any).telegramLoginWidgetCb;
			}
		};
	});

	return (
		<div className={props.additionalClassNames} ref={containerRef}></div>
	);
}

export default TelegramLogin;
