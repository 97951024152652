import { RepositoryResponse, request } from '../request.repository';

import { URL_API_PENDING_CART_DELETE } from '../../constants/urls.constants';

export interface ServiceResponseSuccess {
	status: 'OK';
}

export interface ServiceResponseError {
	status: 'ERROR';
}

export async function DeletePendingCartService(
	cartId: number,
): Promise<ServiceResponseSuccess | ServiceResponseError> {
	const url = URL_API_PENDING_CART_DELETE`${cartId.toString()}`;
	const method = 'POST';
	const options = { method };

	const response: RepositoryResponse = await request(url, options);

	if (response.status === 'OK') {
		return { status: 'OK' };
	}

	return { status: 'ERROR' };
}
