import { useEffect } from 'react';

export const useMenuTrigger = (
	refTriggerElement: React.RefObject<HTMLDivElement>,
	refChildElement: React.RefObject<HTMLDivElement>,
	toggled: boolean,
	setToggled: (toggled: boolean | ((toggled: boolean) => boolean)) => void,
): void => {
	// const [toggled, setToggled] = useState<boolean>(false);

	useEffect(() => {
		document.addEventListener('mousedown', handleMouseDownListener);
		document.addEventListener('mouseover', handleMouseOverListener);

		return () => {
			document.removeEventListener('mousedown', handleMouseDownListener);
			document.removeEventListener('mouseover', handleMouseOverListener);
		};
	}, []);

	useEffect(() => {
		setToggled(toggled);
	}, [toggled]);

	const handleMouseDownListener = (event: Event) => {
		const clickedTrigger =
			refTriggerElement.current &&
			refTriggerElement.current.contains(event.target as Node);
		const clickedInside =
			refChildElement.current &&
			refChildElement.current.contains(event.target as Node);

		if (clickedTrigger) {
			setToggled((toggled) => !toggled);
		} else if (!clickedInside) {
			setToggled(false);
		}
	};

	const handleMouseOverListener = (event: Event) => {
		const clickedTrigger =
			refTriggerElement.current &&
			refTriggerElement.current.contains(event.target as Node);
		const clickedInside =
			refChildElement.current &&
			refChildElement.current.contains(event.target as Node);

		if (clickedTrigger) {
			setToggled(true);
		} else if (!clickedInside) {
			setToggled(false);
		}
	};
};
