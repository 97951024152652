import './UserPage.scss';

import {
	EVENT_MESSAGE_CART_CREATED,
	EVENT_MESSAGE_CART_DELETED,
	EVENT_MESSAGE_CART_EDITED,
} from '../../constants/events.constants';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { CartEntity } from '../../entities/cart.entity';
import { CollectionInfo } from '../../components/Collection/CollectionInfo/CollectionInfo';
import { CollectionInstrument } from '../../components/Collection/CollectionInfo/CollectionInstrument';
import { CreateCart } from '../../smart_components/CartControl/CreateCart';
import { CreateCollection } from '../../components/Collection/CollectionControl/CreateCollection';
import { DeleteCollection } from '../../components/Collection/CollectionControl/DeleteCollection';
import { EditCollection } from '../../components/Collection/CollectionControl/EditCollection';
import { EditCollectionDescriptionService } from '../../services/collections/edit-collection-description.service';
import { FullCartEntity } from '../../entities/full-cart.entity';
import { FullCartPopup } from '../../smart_components/popups/FullCartPopup/FullCartPopup';
import { FullUserEntity } from '../../entities/full-user.entity';
import { ListCollectionEntity } from '../../entities/collection.entity';
import { LoadingData } from '../../components/LoadingData/LoadingData';
import { NoContent } from '../../components/NoContent/NoContent';
import { PageWithSidePanel } from '../../components/Pages/PageWithSidePanel';
import { PopupContainer } from '../../components/PopupContainer/PopupContainer';
import { ReactedEmojiEntity } from '../../entities/emoji.entity';
import { SearchFiltersSidePanel } from '../../components/SidePanel/SearchFiltersSidePanel';
import { URL_USER_COLLECTION_PAGE } from '../../constants/urls.constants';
import { UserInfoPageBlock } from '../../smart_components/UserInfoPageBlock/UserInfoPageBlock';
import { UserPageStore } from '../../stores/UserPageStore';
import { VerticalCart } from '../../components/Cart/VerticalCart/VerticalCart';
import { fetchCollections } from '../../services/collections/fetch-user-collections.service';
import { fetchUserInfo } from '../../services/fetch-user-info.service';
import { fetchUserPageFiltersItems } from '../../services/fetch-user-page-filter-items.service';
import { getFullCart } from '../../services/carts/get-full-cart.service';
import { observer } from 'mobx-react-lite';
import { pushEmoji } from '../../services/carts/push-emoji.service';
import { useRootStore } from '../../stores/RootStore';

export const UserPage = observer(() => {
	const { pageUrl } = useParams();

	const [userPageStore] = useState(() => new UserPageStore());
	const { paginationStore, orderingStore, searchStore } = userPageStore;

	const { profileStore, eventsStore } = useRootStore();
	const emojiStore = profileStore.emojiStore;

	const [userInfo, setUserInfo] = useState<FullUserEntity | null>(null);
	const [collections, setCollections] = useState<ListCollectionEntity[]>([]);
	const [isLoadingCollections, setIsLoadingCollections] =
		useState<boolean>(true);
	const [openedFullCartId, setOpenedFullCartId] = useState<number | null>(
		null,
	);
	const [fullCart, setFullCart] = useState<FullCartEntity | null>(null);
	const [creatingCartCollectionId, setCreatingCartCollectionId] = useState<
		number | null
	>(null);
	const [editingCollectionId, setEditingCollectionId] = useState<
		number | null
	>(null);
	const [deletingCollectionId, setDeletingCollectionId] = useState<
		number | null
	>(null);
	const [isCreatingCollection, setIsCreatingCollection] =
		useState<boolean>(false);

	const isMyPage =
		userInfo !== null && userInfo.userId === profileStore.userId;

	const reloadPage = () => window.location.reload();

	const fetchPageInfo = async () => {
		if (!pageUrl) {
			return;
		}

		const fetchUserInfoResponse = await fetchUserInfo(pageUrl);
		if (fetchUserInfoResponse.status === 'OK') {
			setUserInfo(fetchUserInfoResponse.data);
		} else {
			eventsStore.pushUnknownErrorEvent();
		}
	};

	const fetchPageData = async (user_id: number) => {
		setIsLoadingCollections(true);
		const fetchCollectionsResponse = await fetchCollections(
			user_id,
			userPageStore.filterCategoryStore.picked,
			searchStore.searchQuery,
			orderingStore.orderBy,
			orderingStore.ordering,
			paginationStore.nextPage,
			paginationStore.perPage,
		);
		if (fetchCollectionsResponse.status === 'OK') {
			setCollections(fetchCollectionsResponse.data.data ?? []);
		} else {
			eventsStore.pushUnknownErrorEvent();
		}
		setIsLoadingCollections(false);
	};

	const fetchFiltersItems = async () => {
		if (!userInfo || userInfo.isHidden) {
			return;
		}

		const { status, filters } = await fetchUserPageFiltersItems(
			userInfo.userId,
		);
		if (status === 'OK') {
			userPageStore.filterCategoryStore.updateItems(
				filters?.categories ?? [],
			);
		} else {
			eventsStore.pushUnknownErrorEvent();
		}
	};

	useEffect(() => {
		setUserInfo(null);
		userPageStore.clear();
		fetchPageInfo();
	}, [pageUrl]);

	useEffect(() => {
		userInfo && fetchFiltersItems();
	}, [userInfo && userInfo.userId]);

	useEffect(() => {
		userInfo && !userInfo.isHidden && fetchPageData(userInfo.userId);
	}, [
		userInfo && userInfo.userId,
		searchStore.searchQuery,
		userPageStore.filterCategoryStore.picked.length,
	]);

	const fetchFullCartData = () => {
		const updateFullCart = async (cartId: number) => {
			const response = await getFullCart(cartId.toString());
			if (response.status === 'OK') {
				setFullCart(response.data);
			} else {
				eventsStore.pushUnknownErrorEvent();
			}
		};

		setFullCart(null);
		if (openedFullCartId !== null) {
			updateFullCart(openedFullCartId);
		}
	};
	useEffect(fetchFullCartData, [openedFullCartId]);

	const updateFullCartEmojis = (emojis: ReactedEmojiEntity[]) => {
		if (fullCart === null) {
			return;
		}
		const newFullCartEntity = { ...fullCart };
		newFullCartEntity.cart.emojis = emojis;
		setFullCart(newFullCartEntity);
	};

	const renderCart = (cart: CartEntity): React.ReactNode => {
		const pushEmojiCallback = async (emojiName: string, add: boolean) => {
			const response = await pushEmoji(cart.id, emojiName, add);
			if (response.status === 'OK') {
				const emojis = response.data;
				updateFullCartEmojis(emojis);
			} else {
				eventsStore.pushUnknownErrorEvent();
			}
		};

		const updateCart = (cart: CartEntity) => {
			const collectionIndexToUpdate = collections.findIndex(
				(obj) => obj.fullCollection.id === cart.collection.id,
			);
			const collectionCarts = collections[collectionIndexToUpdate].carts;
			const cartIndexToUpdate = collectionCarts.findIndex(
				(obj) => obj.id === cart.id,
			);
			collectionCarts[cartIndexToUpdate] = cart;
			setCollections([...collections]);
		};
		const editedCallback = (cart: CartEntity) => {
			eventsStore.pushSuccessEvent(
				EVENT_MESSAGE_CART_EDITED`${cart.title}`,
			);
			updateCart(cart);
			setOpenedFullCartId(null);
		};

		const deleteCart = () => {
			const collectionIndexToUpdate = collections.findIndex(
				(obj) => obj.fullCollection.id === cart.collection.id,
			);
			const collection = collections[collectionIndexToUpdate];
			collection.fullCollection.totalCartsCount =
				collection.fullCollection.totalCartsCount - 1;
			const collectionCarts = collection.carts;
			const cartIndexToDelete = collectionCarts.findIndex(
				(obj) => obj.id === cart.id,
			);
			collectionCarts.splice(cartIndexToDelete, 1);
			setCollections([...collections]);
		};
		const deletedCallback = () => {
			eventsStore.pushSuccessEvent(
				EVENT_MESSAGE_CART_DELETED`${cart.title}`,
			);
			deleteCart();
			setOpenedFullCartId(null);
		};

		const isOpenedFullCart = openedFullCartId === cart.id;
		const fullCartNode: React.ReactNode = isOpenedFullCart && (
			<PopupContainer
				isOpened={isOpenedFullCart}
				closePopupCallback={() => setOpenedFullCartId(null)}
			>
				<FullCartPopup
					fullCart={fullCart}
					isMyCart={isMyPage}
					allEmojis={emojiStore.allEmojis}
					lastUsedEmojis={emojiStore.lastUsedEmojis}
					pushEmojiCallback={pushEmojiCallback}
					editedCallback={editedCallback}
					deletedCallback={deletedCallback}
				/>
			</PopupContainer>
		);

		return (
			<div className="collection__vcart_container" key={cart.id}>
				{fullCartNode}
				<VerticalCart
					cart={cart}
					openFullCartCallback={() => setOpenedFullCartId(cart.id)}
					key={cart.id}
				/>
			</div>
		);
	};

	const renderCartsCountUtilCart = (
		collectionId: number,
		collectionTotalCartsCount: number,
	): React.ReactNode => (
		<div className="collection__vcart_container">
			<Link
				to={URL_USER_COLLECTION_PAGE`${collectionId.toString()}`}
				className="collection__util-cart"
			>
				<h1 className="collection__util-cart-number">
					{collectionTotalCartsCount}
				</h1>
				<span className="collection__util-cart-text">всего</span>
				<span>карточек</span>
			</Link>
		</div>
	);

	const renderCreateCartUtilCart = (
		collectionId: number,
	): React.ReactNode => (
		<div className="collection__vcart_container">
			<div
				className="collection__util-cart"
				onClick={() => setCreatingCartCollectionId(collectionId)}
			>
				<h1 className="collection__util-cart-number">+</h1>
			</div>
		</div>
	);

	const renderCollection = (
		listCollection: ListCollectionEntity,
	): React.ReactNode => {
		const fullCollection = listCollection.fullCollection;
		const carts = listCollection.carts;

		const cartsNodes = carts.map((cart) => renderCart(cart));
		const cartsCountUtilCartNode = renderCartsCountUtilCart(
			fullCollection.id,
			fullCollection.totalCartsCount,
		);
		const createCartUtilCartNode =
			isMyPage && renderCreateCartUtilCart(fullCollection.id);

		const addCart = (cart: CartEntity) => {
			const collectionIndexToUpdate = collections.findIndex(
				(obj) => obj.fullCollection.id === cart.collection.id,
			);
			const collection = collections[collectionIndexToUpdate];
			collection.fullCollection.totalCartsCount =
				collection.fullCollection.totalCartsCount + 1;
			const collectionCarts = collection.carts;
			collectionCarts.splice(0, 0, cart);
			setCollections([...collections]);
		};
		const createdCallback = (cart: CartEntity) => {
			eventsStore.pushSuccessEvent(
				EVENT_MESSAGE_CART_CREATED`${cart.title}${cart.collection.title}`,
			);
			addCart(cart);
			setCreatingCartCollectionId(null);
		};
		const isCreatingCart = creatingCartCollectionId === fullCollection.id;
		const createCartNode: React.ReactNode = isMyPage && isCreatingCart && (
			<PopupContainer
				isOpened={isCreatingCart}
				closePopupCallback={() => setCreatingCartCollectionId(null)}
			>
				<CreateCart
					createdCallback={createdCallback}
					initialCollection={{
						id: fullCollection.id,
						title: fullCollection.title,
						category: fullCollection.category,
					}}
				/>
			</PopupContainer>
		);

		const isEditingCollection = editingCollectionId === fullCollection.id;
		const editCollectionNode: React.ReactNode = isMyPage &&
			isEditingCollection && (
				<PopupContainer
					isOpened={isEditingCollection}
					closePopupCallback={() => setEditingCollectionId(null)}
				>
					<EditCollection
						collectionId={fullCollection.id}
						initialData={{
							title: fullCollection.title,
							category: fullCollection.category,
						}}
						collectionEditedCallback={reloadPage}
					/>
				</PopupContainer>
			);

		const isDeletingCollection = deletingCollectionId === fullCollection.id;
		const deleteCollectionNode: React.ReactNode = isMyPage &&
			isDeletingCollection && (
				<PopupContainer
					isOpened={isDeletingCollection}
					closePopupCallback={() => setDeletingCollectionId(null)}
				>
					<DeleteCollection
						collectionId={fullCollection.id}
						collectionTitle={fullCollection.title}
						collectionCartsCount={fullCollection.totalCartsCount}
						collectionDeletedCallback={reloadPage}
					/>
				</PopupContainer>
			);

		const collectionInstrumentNodes = isMyPage && [
			<CollectionInstrument
				title="Редактировать"
				onClick={() => setEditingCollectionId(fullCollection.id)}
				key="edit_instrument"
			/>,
			<CollectionInstrument
				title="Удалить"
				onClick={() => setDeletingCollectionId(fullCollection.id)}
				key="delete_instrument"
			/>,
		];

		const submitDescriptionCallback = async (
			description: string | null,
		) => {
			const serviceResponse = await EditCollectionDescriptionService(
				fullCollection.id,
				description,
			);
			if (serviceResponse.status !== 'OK') {
				eventsStore.pushUnknownErrorEvent();
			}
		};
		const collectionInfoNode: React.ReactNode = (
			<CollectionInfo
				collection={fullCollection}
				isMyCollection={isMyPage}
				isLink={true}
				instrumentNodes={collectionInstrumentNodes}
				submitDescriptionCallback={submitDescriptionCallback}
			/>
		);

		return (
			<div className="collection" key={fullCollection.id}>
				{createCartNode}
				{editCollectionNode}
				{deleteCollectionNode}
				{collectionInfoNode}
				<div className="collection__carts">
					{createCartUtilCartNode}
					{cartsNodes}
					{cartsCountUtilCartNode}
				</div>
			</div>
		);
	};

	const collectionsNodes: React.ReactNode = (
		<div className="user-page__collections">
			{collections.map(renderCollection)}
		</div>
	);

	const noCollectionsText = userPageStore.isFiltered
		? 'Коллекций не найдено'
		: isMyPage
			? 'У вас еще не создано ни одной коллекции'
			: 'У пользователя пока нет коллекций';
	const noCollectionsNode: React.ReactNode =
		collections.length === 0 && !isLoadingCollections ? (
			<NoContent width="920px" text={noCollectionsText} />
		) : null;

	const isHiddenUserNode: React.ReactNode = userInfo && userInfo.isHidden && (
		<NoContent
			width="920px"
			text="Пользователь ограничил доступ неавторизованным пользователям"
		/>
	);

	const createCollectionButton: React.ReactNode = (
		<div
			className="user-page__create-collection"
			onClick={() => setIsCreatingCollection(true)}
		>
			<span className="user-page__create-collection_button">
				Создать коллекцию
			</span>
		</div>
	);

	const createCollectionButtonNode: React.ReactNode =
		isMyPage && createCollectionButton;

	const createCollectionNode: React.ReactNode = isMyPage &&
		isCreatingCollection && (
			<PopupContainer
				isOpened={isCreatingCollection}
				closePopupCallback={() => setIsCreatingCollection(false)}
			>
				<CreateCollection />
			</PopupContainer>
		);

	const userInfoNode: React.ReactNode = (
		<div className="user-page__user-info">
			<UserInfoPageBlock
				profileId={profileStore.userId}
				user={userInfo}
				setIsFollowingCallback={(isFollowing) =>
					setUserInfo(userInfo && { ...userInfo, isFollowing })
				}
			/>
		</div>
	);

	const sidePanel: React.ReactNode = userInfo && (
		<SearchFiltersSidePanel
			filters={[
				{
					filter: userPageStore.filterCategoryStore,
					title: 'Категории',
				},
			]}
			search={{
				placeholder: 'Найти коллекцию',
				searchValue: searchStore.searchQuery,
				searchCallback: searchStore.setSearchQuery,
			}}
		/>
	);

	const loadingNode: React.ReactNode = (
		<LoadingData
			isLoading={
				isLoadingCollections && (!userInfo || !userInfo.isHidden)
			}
		></LoadingData>
	);

	const contentNode: React.ReactNode = (
		<div className="user-page">
			{userInfoNode}
			{createCollectionButtonNode}
			{collectionsNodes}
			{noCollectionsNode}
			{isHiddenUserNode}
			{loadingNode}
		</div>
	);

	const popUps: React.ReactNode[] = [createCollectionNode];

	return (
		<PageWithSidePanel
			sidePanel={sidePanel}
			content={contentNode}
			popUps={popUps}
		/>
	);
});
