import './AuthForm.scss';

import TelegramLogin, {
	TelegramUser,
} from '../../utils/reactTelegramAuthButton';

import React from 'react';
import { TELEGRAM_BOT_NAME } from '../../constants/common.constants';

type FormErrors = Partial<{ [key: string]: string[] }>;

type TelegramAuthFormProps = {
	errors: FormErrors;
	submitCallback: (telegramUser: TelegramUser) => void;
};

export const TelegramAuthForm: React.FC<TelegramAuthFormProps> = ({
	errors,
	submitCallback,
}) => {
	const telegramAuthButtonNode: React.ReactNode = (
		<TelegramLogin
			botName={TELEGRAM_BOT_NAME}
			dataOnAuth={submitCallback}
		/>
	);

	const nonFieldErrorNode: React.ReactNode = errors['non_field_errors'] && (
		<span className="auth-form__error">
			{errors['non_field_errors'][0]}
		</span>
	);

	return (
		<div className="auth-form">
			{telegramAuthButtonNode}
			{nonFieldErrorNode}
		</div>
	);
};
