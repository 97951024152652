import './UserInfoWide.scss';

import React, { useState } from 'react';

import { Avatar } from '../Avatar/Avatar';
import { FullUserEntity } from '../../../entities/full-user.entity';
import { Link } from 'react-router-dom';
import { SquareButton } from '../../../components/Buttons/SquareButton/SquareButton';
import { URL_USER_PAGE } from '../../../constants/urls.constants';

type UserInfoWideProps = {
	profileId: number | null;
	user: FullUserEntity;
	isFull: boolean;
	subscribeCallback: () => void;
	unsubscribeCallback: () => void;
	openSubscribersCallback?: () => void;
	openSignablesCallback?: () => void;
};

export const UserInfoWide: React.FC<UserInfoWideProps> = ({
	profileId,
	user,
	isFull,
	subscribeCallback,
	unsubscribeCallback,
	openSubscribersCallback,
	openSignablesCallback,
}) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const isMe = user.userId === profileId;

	const executeCallback = (callback: () => void) => {
		if (isLoading) {
			return;
		}
		setIsLoading(true);
		callback();
		setIsLoading(false);
	};

	const userInfoNode: React.ReactNode = (
		<Link className="user-info" to={URL_USER_PAGE`${user.pageUrl}`}>
			<h1 className="user-info__username">{user.username}</h1>
			<Avatar
				avatarUrl={user.avatar}
				username={user.username}
				size="large"
			/>
		</Link>
	);
	const subscribeInstrumentNode: React.ReactNode = !isMe &&
		!user.isFollowing && (
			<SquareButton
				title="Подписаться на обновления"
				disabled={isLoading}
				isLoading={isLoading}
				isFilled={true}
				onClick={() => executeCallback(subscribeCallback)}
			/>
		);

	const unsubscribeInstrumentNode: React.ReactNode = !isMe &&
		user.isFollowing && (
			<SquareButton
				title="Отписаться"
				disabled={isLoading}
				isLoading={isLoading}
				isFilled={false}
				onClick={() => executeCallback(unsubscribeCallback)}
			/>
		);

	const instrumentsNode: React.ReactNode = profileId && (
		<div className="user-info-instruments">
			{subscribeInstrumentNode || unsubscribeInstrumentNode}
		</div>
	);

	type subscriptionInfo = {
		label: string;
		count: number;
		href: string;
		onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
	};
	const renderSubscriptionInfoNode = (
		subscriptionInfo: subscriptionInfo,
	): React.ReactNode => {
		const { label, count, href, onClick } = subscriptionInfo;

		return (
			<div className="user-info-subscribers__block">
				<Link
					to={href}
					onClick={onClick}
					className="user-info-subscribers__label"
				>
					{label}
				</Link>
				<span className="user-info-subscribers__count">{count}</span>
			</div>
		);
	};

	const subscribersInfoNode =
		user &&
		renderSubscriptionInfoNode({
			label: 'подписчики',
			count: user.subscribersCount,
			href: '/',
			onClick: (e) => {
				if (e.metaKey || openSubscribersCallback === undefined) {
					return;
				}
				e.preventDefault();
				openSubscribersCallback && openSubscribersCallback();
			},
		});

	const signablesInfoNode =
		user &&
		renderSubscriptionInfoNode({
			label: 'подписки',
			count: user.signablesCount,
			href: '/',
			onClick: (e) => {
				if (e.metaKey || openSignablesCallback === undefined) {
					return;
				}
				e.preventDefault();
				openSignablesCallback && openSignablesCallback();
			},
		});
	const subscriptionsNode: React.ReactNode = isFull && user && (
		<div className="user-info-subscribers">
			{subscribersInfoNode}
			{signablesInfoNode}
		</div>
	);

	return (
		<div className="user-info-block">
			<div className="user-info-main">
				{userInfoNode}
				{instrumentsNode}
			</div>
			{subscriptionsNode}
		</div>
	);
};
