import { RepositoryResponse, request } from '../request.repository';

import { ERROR_REASON_UNAUTHORIZED } from '../../constants/api-errors.constants';
import { NotificationEntity } from './../../entities/notification.entity';
import { URL_API_NOTIFICATIONS_LIST } from '../../constants/urls.constants';

interface ErrorDataResponse {
	reason: string;
}

export interface ServiceResponseError {
	status: 'ERROR';
	data?: ErrorDataResponse;
}

export interface ServiceResponseSuccess {
	status: 'OK';
	data: NotificationEntity[];
}

export const FetchNotificationsService = async (): Promise<
	ServiceResponseSuccess | ServiceResponseError
> => {
	const url = URL_API_NOTIFICATIONS_LIST;
	const method = 'GET';
	const options = { method };

	const response: RepositoryResponse = await request(url, options);

	if (response.status === 'OK') {
		const data: NotificationEntity[] = response.data.content.data;
		return {
			status: 'OK',
			data,
		};
	} else if (response.statusCode === 403) {
		return {
			status: 'ERROR',
			data: { reason: ERROR_REASON_UNAUTHORIZED },
		};
	}

	return { status: 'ERROR' };
};
