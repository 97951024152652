import './VerticalCart.scss';

import React, { useState } from 'react';

import { CartEntity } from '../../../entities/cart.entity';
import { Image } from '../../Image/Image';
import { URL_CART_PAGE } from '../../../constants/urls.constants';
import { observer } from 'mobx-react-lite';

type HorizontalCartProps = {
	cart: CartEntity;
	openFullCartCallback: () => void;
};

export const VerticalCart: React.FC<HorizontalCartProps> = observer(
	({ cart, openFullCartCallback }) => {
		const [cartFocus, setCartFocus] = useState<boolean>(false);

		const maxCartTitleLength = 64;
		const cartTitle =
			cart.title.length > maxCartTitleLength
				? `${cart.title.slice(0, maxCartTitleLength)}...`
				: cart.title;

		const cartLineOpacity = cartFocus ? '70' : '00';
		const cartLineColor = `4px #${cart.color}${cartLineOpacity} solid`;
		const cartBackgroundColor = `#${cart.color}09`;
		const cartBackgroundHeight = cartFocus ? '100%' : '0%';

		const fullCartUrl = URL_CART_PAGE`${cart.id.toString()}`;
		const openFullCart = (e: React.MouseEvent<HTMLAnchorElement>) => {
			if (e.metaKey) {
				return;
			}
			e.preventDefault();
			openFullCartCallback();
		};

		return (
			<div className="vcart__container">
				<div
					className="vcart__color_background"
					style={{
						backgroundColor: cartBackgroundColor,
						height: cartBackgroundHeight,
					}}
				></div>
				<div
					className="vcart"
					style={{
						borderTop: cartLineColor,
					}}
					onMouseOver={() => setCartFocus(true)}
					onMouseLeave={() => {
						setCartFocus(false);
					}}
				>
					<a
						href={fullCartUrl}
						className="vcart__image_container"
						onClick={openFullCart}
					>
						<div className="vcart__image">
							<Image src={cart.thumbnailUrl} height={200} />
						</div>
					</a>
					<div className="vcart__title_container">
						<a
							className="vcart__title"
							title={cartTitle}
							href={fullCartUrl}
							onClick={openFullCart}
						>
							{cart.title}
						</a>
						<p className="vcart__rate">{cart.rate.title}</p>
					</div>
				</div>
			</div>
		);
	},
);
