import {
	PaginatedDataResponse,
	RepositoryResponse,
	request,
} from '../request.repository';

import { NewsFeedCartEntity } from '../../entities/news-feed.entity';
import { URL_API_NEWSFFED_LIST } from '../../constants/urls.constants';

export interface ServiceResponseSuccess {
	status: 'OK';
	data: PaginatedDataResponse<NewsFeedCartEntity>;
}

export interface ServiceResponseError {
	status: 'ERROR';
	data: null;
}

export async function FetchNewsService(
	signableId: number | null,
	page: number | null,
	perPage: number | null = null,
): Promise<ServiceResponseSuccess | ServiceResponseError> {
	let getParams = {};
	if (signableId) {
		getParams = { ...getParams, signable_id: signableId.toString() };
	}
	if (page) {
		getParams = { ...getParams, page: page.toString() };
	}
	if (perPage) {
		getParams = { ...getParams, per_page: perPage.toString() };
	}
	const params = new URLSearchParams(getParams);

	const url = `${URL_API_NEWSFFED_LIST}?${params}`;

	const method = 'GET';
	const options = {
		method,
	};

	const response: RepositoryResponse = await request(url, options);

	if (response.status === 'OK') {
		const data: PaginatedDataResponse<NewsFeedCartEntity> =
			response.data.content;
		return {
			status: 'OK',
			data,
		};
	}

	return {
		status: 'ERROR',
		data: null,
	};
}
