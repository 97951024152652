export const parseStringIds = (
	idsStrings: string[],
	actualIds: number[],
): number[] => {
	const parsedIds = idsStrings
		.filter((idString) => {
			const id = parseInt(idString);
			if (!isNaN(id) && actualIds.includes(id)) {
				return true;
			}
			return false;
		})
		.map((id): number => {
			return parseInt(id);
		});
	return parsedIds;
};
