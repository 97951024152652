import { AllEmojisEntity, EmojiEntity } from '../entities/emoji.entity';

import { makeAutoObservable } from 'mobx';

export class EmojiStore {
	allEmojis: AllEmojisEntity = {
		custom: [],
		people: [],
		body: [],
		clothing: [],
		nature: [],
		food: [],
		activities: [],
		travel: [],
		objects: [],
		symbols: [],
		flags: [],
	};
	lastUsedEmojis: EmojiEntity[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	setAllEmojis(allEmojis: AllEmojisEntity): void {
		this.allEmojis = allEmojis;
	}

	setLastUsedEmojis(emojis: EmojiEntity[]): void {
		this.lastUsedEmojis = emojis;
	}
}
