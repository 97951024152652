import './Icon.scss';

import React from 'react';

type IconProps = {
	src: string;
	title: string;
	sizePx: number;
	round?: boolean;
};

export const Icon: React.FC<IconProps> = ({ src, title, sizePx, round }) => {
	const roundClass = round ? 'icon__img--round' : '';

	return (
		<div
			className="icon"
			title={title}
			style={{ maxHeight: `${sizePx}px`, width: `${sizePx}px` }}
		>
			<img className={`icon__img ${roundClass}`} src={src} alt={title} />
		</div>
	);
};
