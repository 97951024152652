import { RateEntity } from '../entities/rate.entity';

export type RATES_CHOICES = 1 | 2 | 3 | 4 | 5;

export const RATE_NAMES_MAP = {
	1: 'Абсолютная годнота',
	2: 'Годнота',
	3: 'Одним глазком',
	4: 'Без сознания',
	5: 'Скрытая годнота',
};

export const RATES: RateEntity[] = [
	{ id: '1', title: 'Абсолютная годнота' },
	{ id: '2', title: 'Годнота' },
	{ id: '3', title: 'Одним глазком' },
	{ id: '4', title: 'Без сознания' },
	{ id: '5', title: 'Скрытая годнота' },
];

export const RATE_IMAGE_HEIGHT_MAP = {
	1: 250, // Абсолютная годнота
	2: 220, // Годнота
	3: 190, // Одним глазком
	4: 160, // Без сознания
	5: 180, // Скрытая годнота
};
