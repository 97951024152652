import { RepositoryResponse, request } from '../request.repository';

import { InviteUsersDataEntity } from '../../entities/invite-users-data.entity';
import { URL_API_ACCOUNT_INVITE_USERS_DATA } from '../../constants/urls.constants';

export interface ErrorDataResponse {
	[key: string]: Array<string>;
}

export interface ServiceResponseSuccess {
	status: 'OK';
	data: InviteUsersDataEntity;
}

export interface ServiceResponseError {
	status: 'ERROR';
	data: Partial<ErrorDataResponse>;
}

export async function getInviteUsersDataService(): Promise<
	ServiceResponseSuccess | ServiceResponseError
> {
	const url = URL_API_ACCOUNT_INVITE_USERS_DATA;

	const method = 'GET';
	const options = { method };

	const response: RepositoryResponse = await request(url, options);

	if (response.status === 'OK') {
		const data: InviteUsersDataEntity = response.data.content.data;
		return {
			status: 'OK',
			data,
		};
	}

	return {
		status: 'ERROR',
		data: response.data,
	};
}
