import './PendingCartsPage.scss';

import {
	EVENT_MESSAGE_CART_CREATED,
	EVENT_MESSAGE_CART_DELETED,
	EVENT_MESSAGE_CART_EDITED,
	EVENT_MESSAGE_PENDING_CART_CREATED,
} from '../../constants/events.constants';
import {
	ORDERING_CHOICES,
	PENDING_CARTS_ORDER_BY_CHOICES,
} from '../../constants/order.constants';
import { OrderItem, OrderPanel } from '../../components/OrderPanel/OrderPanel';
import React, { useEffect, useState } from 'react';

import { AddUtilCart } from '../../components/Cart/UtilCart/AddUtilCart';
import { CartEntity } from '../../entities/cart.entity';
import { CreateCart } from '../../smart_components/CartControl/CreateCart';
import { CreatePendingCart } from '../../smart_components/CartControl/PendingCart/CreatePendingCart';
import { DeletePendingCart } from '../../smart_components/CartControl/PendingCart/DeletePendingCart';
import { DynamicGrid } from '../../components/DynamicGrid/DynamicGrid';
import { EditPendingCart } from '../../smart_components/CartControl/PendingCart/EditPendingCart';
import { FetchPendingCartsFiltersService } from '../../services/pending/fetch-pending-filters.service';
import { FetchPendingCartsService } from '../../services/pending/fetch-pending-carts.service';
import { LoadingData } from '../../components/LoadingData/LoadingData';
import { PageWithSidePanel } from '../../components/Pages/PageWithSidePanel';
import { PendingCart } from '../../components/Cart/HorizontalCart/PendingCart';
import { PendingCartEntity } from '../../entities/pending-cart.entity';
import { PendingCartsPageStore } from '../../stores/PendingCartsPageStore';
import { PopupContainer } from '../../components/PopupContainer/PopupContainer';
import { SearchFiltersSidePanel } from '../../components/SidePanel/SearchFiltersSidePanel';
import { observer } from 'mobx-react-lite';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useRootStore } from '../../stores/RootStore';

export const PendingCartsPage: React.FC = observer(() => {
	const [pendingCartsStore] = useState(() => new PendingCartsPageStore());
	const { cursorPaginationStore, orderingStore, searchStore } =
		pendingCartsStore;

	const { eventsStore } = useRootStore();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const [carts, setCarts] = useState<PendingCartEntity[]>([]);
	const [isCreatingCart, setIsCreatingCart] = useState<boolean>(false);
	const [addingCartId, setAddingCartId] = useState<number | null>(null);
	const [editingCartId, setEditingCartId] = useState<number | null>(null);
	const [deletingCartId, setDeletingCartId] = useState<number | null>(null);

	const updateFiltersItems = async () => {
		const serviceResponse = await FetchPendingCartsFiltersService();
		if (serviceResponse.status === 'OK') {
			pendingCartsStore.filterCategoryStore.updateItems(
				serviceResponse.data.categories,
			);
		} else {
			eventsStore.pushUnknownErrorEvent();
		}
	};

	useEffect(() => {
		updateFiltersItems();
	}, []);

	const fetchPageCarts = async () => {
		if (isLoading) {
			return;
		}
		setIsLoading(true);

		const serviceResponse = await FetchPendingCartsService(
			pendingCartsStore.filterCategoryStore.picked,
			searchStore.searchQuery,
			orderingStore.orderBy,
			orderingStore.ordering,
			cursorPaginationStore.nextCursor || null,
			cursorPaginationStore.perPage,
		);
		if (serviceResponse.status === 'OK') {
			const newCarts = serviceResponse.data.data;
			setCarts([...carts, ...newCarts]);
			cursorPaginationStore.setNextCursor(
				serviceResponse.data.meta.next_cursor,
			);
		} else {
			eventsStore.pushUnknownErrorEvent();
			cursorPaginationStore.setNextCursor(null);
		}

		setIsLoading(false);
	};

	const fetchCartsEffect = () => {
		setCarts([]);
		setIsLoading(false);
		cursorPaginationStore.setNextCursor(undefined);
	};
	useEffect(fetchCartsEffect, [
		pendingCartsStore.filterCategoryStore.picked.length,
		searchStore.searchQuery,
		orderingStore.orderBy,
		orderingStore.ordering,
		cursorPaginationStore.perPage,
	]);

	const [infiniteScrollRef] = useInfiniteScroll({
		loading: isLoading,
		hasNextPage: cursorPaginationStore.isHasNextPage,
		onLoadMore: fetchPageCarts,
		disabled: false,
		rootMargin: '0px 0px 10px 0px',
	});

	const addCart = (pendingCart: PendingCartEntity) => {
		carts.splice(0, 0, pendingCart);
		setCarts([...carts]);
	};
	const createdPendingCallback = (pendingCart: PendingCartEntity) => {
		eventsStore.pushSuccessEvent(
			EVENT_MESSAGE_PENDING_CART_CREATED`${pendingCart.title}`,
		);
		addCart(pendingCart);
		setIsCreatingCart(false);
	};
	const createCartNode: React.ReactNode = isCreatingCart && (
		<PopupContainer
			isOpened={isCreatingCart}
			closePopupCallback={() => setIsCreatingCart(false)}
			key="create-cart__popup"
		>
			<CreatePendingCart createdCallback={createdPendingCallback} />
		</PopupContainer>
	);

	const createCartUtilCart: React.ReactNode = (
		<div className="pending-carts__cart-container">
			<AddUtilCart actionCallback={() => setIsCreatingCart(true)} />
		</div>
	);

	const renderCart = (
		pendingCart: PendingCartEntity,
		index: number,
	): React.ReactNode => {
		const createdCallback = (cart: CartEntity) => {
			eventsStore.pushSuccessEvent(
				EVENT_MESSAGE_CART_CREATED`${cart.title}${cart.collection.title}`,
			);
			setAddingCartId(null);
			setCarts(carts.filter((newCart) => newCart.id !== pendingCart.id));
		};
		const isAdding = addingCartId === pendingCart.id;
		const addCartNode: React.ReactNode = isAdding && (
			<PopupContainer
				isOpened={isAdding}
				closePopupCallback={() => setAddingCartId(null)}
			>
				<CreateCart
					createdCallback={createdCallback}
					initialPendingCart={pendingCart}
				/>
			</PopupContainer>
		);

		const updateCart = (pendingCart: PendingCartEntity) => {
			const cartIndexToUpdate = carts.findIndex(
				(obj) => obj.id === pendingCart.id,
			);
			carts[cartIndexToUpdate] = pendingCart;
			setCarts([...carts]);
		};
		const editedCallback = (pendingCart: PendingCartEntity) => {
			eventsStore.pushSuccessEvent(
				EVENT_MESSAGE_CART_EDITED`${pendingCart.title}`,
			);
			updateCart(pendingCart);
			setEditingCartId(null);
		};
		const isEditing = editingCartId === pendingCart.id;
		const editCartNode: React.ReactNode = isEditing && (
			<PopupContainer
				isOpened={isEditing}
				closePopupCallback={() => setEditingCartId(null)}
			>
				<EditPendingCart
					editedCallback={editedCallback}
					initialCart={pendingCart}
				/>
			</PopupContainer>
		);

		const cartDeletedCallback = () => {
			eventsStore.pushSuccessEvent(
				EVENT_MESSAGE_CART_DELETED`${pendingCart.title}`,
			);
			setDeletingCartId(null);
			setCarts(carts.filter((newCart) => newCart.id !== pendingCart.id));
		};
		const isDeleting = deletingCartId === pendingCart.id;
		const deleteCartNode: React.ReactNode = isDeleting && (
			<PopupContainer
				isOpened={isDeleting}
				closePopupCallback={() => setDeletingCartId(null)}
			>
				<DeletePendingCart
					cartId={pendingCart.id}
					cartTitle={pendingCart.title}
					deletedCallback={cartDeletedCallback}
				/>
			</PopupContainer>
		);

		return (
			<div className="pending-carts__cart-container">
				{addCartNode}
				{editCartNode}
				{deleteCartNode}
				<PendingCart
					key={`${index}__pending-cart`}
					cart={pendingCart}
					editCartCallback={() => setEditingCartId(pendingCart.id)}
					deleteCartCallback={() => setDeletingCartId(pendingCart.id)}
					addCartCallback={() => setAddingCartId(pendingCart.id)}
				/>
			</div>
		);
	};

	const gridElements: React.ReactNode[] = [
		createCartUtilCart,
		...carts.map(renderCart),
	];
	const cartsGrid: React.ReactNode = <DynamicGrid elements={gridElements} />;

	const loading = (
		<LoadingData isLoading={isLoading}>
			{cursorPaginationStore.isHasNextPage && (
				<div ref={infiniteScrollRef}></div>
			)}
		</LoadingData>
	);

	const orderItems: OrderItem[] = [
		{
			orderByKey: PENDING_CARTS_ORDER_BY_CHOICES.DATE_CREATED,
			titleDsc: 'сначала новые',
			titleAsc: 'сначала старые',
			defaultOrdering: ORDERING_CHOICES.DSC,
		},
	];
	const orderPanel: React.ReactNode = (
		<OrderPanel
			ordering={orderingStore.ordering}
			orderBy={orderingStore.orderBy}
			orderItems={orderItems}
			setOrderCallback={(order_by, ordering) => {
				orderingStore.setOrder(order_by, ordering);
			}}
		/>
	);

	const contentNode: React.ReactNode = (
		<div className="pending-carts">
			{orderPanel}
			{cartsGrid}
			{loading}
		</div>
	);

	const sidePanel: React.ReactNode = (
		<SearchFiltersSidePanel
			filters={[
				{
					filter: pendingCartsStore.filterCategoryStore,
					title: 'Категории',
				},
			]}
			search={{
				placeholder: 'Найти карточку',
				searchValue: searchStore.searchQuery,
				searchCallback: searchStore.setSearchQuery,
			}}
		/>
	);

	const popUps: React.ReactNode[] = [createCartNode];

	return (
		<PageWithSidePanel
			sidePanel={sidePanel}
			content={contentNode}
			popUps={popUps}
		/>
	);
});
