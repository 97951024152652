import { RepositoryResponse, request } from '../request.repository';

import { FilterItem } from '../../entities/filter-item.entity';
import { URL_API_PENDING_CART_FILTERS } from '../../constants/urls.constants';

type Filters = {
	categories: FilterItem[];
};

export interface ServiceResponseSuccess {
	status: 'OK';
	data: Filters;
}

export interface ServiceResponseError {
	status: 'ERROR';
}

export async function FetchPendingCartsFiltersService(): Promise<
	ServiceResponseSuccess | ServiceResponseError
> {
	const url = URL_API_PENDING_CART_FILTERS;
	const method = 'GET';
	const options = {
		method,
	};

	const response: RepositoryResponse = await request(url, options);

	if (response.status === 'OK') {
		const filters: Filters = response.data.content.data;
		return {
			status: 'OK',
			data: filters,
		};
	}

	return {
		status: 'ERROR',
	};
}
