import React from 'react';
import { useDropdownState } from './DropdownContext';

type DropdownListProps = {
	maxHeightPx?: number;
};

export const DropdownList: React.FC<
	React.PropsWithChildren<DropdownListProps>
> = ({ maxHeightPx, children }) => {
	const { toggled } = useDropdownState();
	const toggleClass: string = toggled ? 'dropdown-list--toggled' : '';

	const style = maxHeightPx ? { maxHeight: `${maxHeightPx}px` } : {};

	return (
		<nav className={`dropdown-list ${toggleClass}`} style={style}>
			{children}
		</nav>
	);
};
