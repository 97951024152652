import { makeAutoObservable } from 'mobx';

export class CursorPaginationStore {
	nextCursor: string | null | undefined = undefined;
	perPage: number | null = null;

	constructor() {
		makeAutoObservable(this);
	}

	setNextCursor = (nextCursor: string | null | undefined): void => {
		this.nextCursor = nextCursor;
	};

	get isHasNextPage(): boolean {
		return this.nextCursor !== null;
	}
}
