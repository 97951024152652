import { RepositoryResponse, request } from '../request.repository';

import { CartNoteEntity } from '../../entities/cart-note.entity';
import { Errors } from '../../types/api-error.type';
import { PendingCartEntity } from '../../entities/pending-cart.entity';
import { URL_API_PENDING_CART_EDIT } from '../../constants/urls.constants';

export interface ServiceResponseSuccess {
	status: 'OK';
	data: PendingCartEntity;
}

export interface ServiceResponseError {
	status: 'ERROR';
	errors: Errors;
}

export async function EditPendingCartService(
	cartId: number,
	categoryId: string,
	title: string,
	picture: File | null,
	pictureUrl: string | null,
	notes: CartNoteEntity[],
): Promise<ServiceResponseSuccess | ServiceResponseError> {
	const url = URL_API_PENDING_CART_EDIT`${cartId.toString()}`;

	const formData = new FormData();
	formData.append('category_name', categoryId);
	formData.append('title', title);

	const notesData = notes.map((note) => {
		return { id: note.id, text: note.text, is_spoiler: note.isSpoiler };
	});
	formData.append('notes', JSON.stringify(notesData));

	if (picture) {
		formData.append('picture', picture);
	} else if (pictureUrl) {
		formData.append('picture_url', pictureUrl);
	}

	const body = formData;
	const method = 'POST';
	const options = {
		body,
		method,
	};
	const noBaseOptions = true;

	const response: RepositoryResponse = await request(
		url,
		options,
		noBaseOptions,
	);

	if (response.status === 'OK') {
		return {
			status: 'OK',
			data: response.data.content.data,
		};
	}

	return {
		status: 'ERROR',
		errors: response.data,
	};
}
