import './AuthForm.scss';

import React, { useState } from 'react';

import { BubbleButton } from '../Buttons/BubbleButton/BubbleButton';
import { TextInput } from '../FormInputs/TextInput/TextInput';

type FormErrors = Partial<{ [key: string]: string[] }>;

type LoginFormProps = {
	errors: FormErrors;
	isSubmitLoading: boolean;
	submitCallback: (username: string, password: string) => void;
};

export const LoginForm: React.FC<LoginFormProps> = ({
	errors,
	isSubmitLoading,
	submitCallback,
}) => {
	const [username, setUsername] = useState<string>('');
	const [password, setPassword] = useState<string>('');

	const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		submitCallback(username, password);
	};

	const usernameError = errors['username_or_email'];
	const inputUsernameNode: React.ReactNode = (
		<div className="auth-form__input-block">
			<TextInput
				label="Имя пользователя"
				value={username}
				setValueCallback={setUsername}
				error={usernameError && usernameError[0]}
			/>
		</div>
	);

	const passwordError = errors['password'];
	const inputPasswordNode: React.ReactNode = (
		<div className="auth-form__input-block">
			<TextInput
				label="Пароль"
				value={password}
				inputType="password"
				setValueCallback={setPassword}
				error={passwordError && passwordError[0]}
			/>
		</div>
	);

	const nonFieldError = errors['non_field_errors'];
	const nonFieldErrorNode: React.ReactNode = nonFieldError && (
		<span className="auth-form__error">{nonFieldError[0]}</span>
	);

	const submitNode: React.ReactNode = (
		<div className="auth-form__submit">
			<BubbleButton
				title="Войти"
				disabled={isSubmitLoading}
				isLoading={isSubmitLoading}
			/>
		</div>
	);

	return (
		<form className="auth-form" onSubmit={submitForm}>
			{inputUsernameNode}
			{inputPasswordNode}
			{nonFieldErrorNode}
			{submitNode}
		</form>
	);
};
