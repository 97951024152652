export type CATEGORY_CHOICES =
	| 'MV'
	| 'CT'
	| 'SR'
	| 'VG'
	| 'BG'
	| 'BK'
	| 'MC'
	| 'AN'
	| 'MG'
	| 'CX'
	| 'FD'
	| 'PC'
	| 'OTHER';

export const CATEGORY_NAMES_MAP = {
	MV: 'Фильмы',
	CT: 'Мультфильмы',
	SR: 'Сериалы',
	VG: 'Видеоигры',
	BG: 'Настольные игры',
	BK: 'Книги',
	MC: 'Музыка',
	AN: 'Аниме',
	MG: 'Манга',
	CX: 'Комиксы',
	FD: 'Еда',
	PC: 'Места',
	OTHER: 'Другое',
};
