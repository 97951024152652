import './Subscriptions.scss';

import { FullUserEntity } from '../../entities/full-user.entity';
import React from 'react';
import { SearchField } from '../SearchField/SearchField';
import { UserEntity } from '../../entities/user.entity';
import { UserInfoMini } from '../Users/UserInfoMini/UserInfoMini';
import { UserInfoWide } from '../Users/UserInfoWide/UserInfoWide';

type SubscriptionsProps = {
	profileId: number | null;
	owner: FullUserEntity;
	users: UserEntity[];
	usersTotalCount: number | null;
	blockTitle: string;
	subscribeCallback: (userId: number) => void;
	unsubscribeCallback: (userId: number) => void;
	searchCallback: (searchValue: string) => void;
};

export const Subscriptions: React.FC<SubscriptionsProps> = ({
	profileId,
	owner,
	users,
	usersTotalCount,
	blockTitle,
	subscribeCallback,
	unsubscribeCallback,
	searchCallback,
}) => {
	const titleNode: React.ReactNode =
		usersTotalCount !== null
			? `${blockTitle}: ${usersTotalCount}`
			: `${blockTitle}`;

	const renderUser = (user: UserEntity): React.ReactNode => (
		<div className="subscriptions__user" key={user.userId}>
			<UserInfoMini
				profileId={profileId}
				user={user}
				subscribeCallback={() => subscribeCallback(user.userId)}
				unsubscribeCallback={() => unsubscribeCallback(user.userId)}
			/>
		</div>
	);
	const usersNodes = users.map(renderUser);

	return (
		<div className="subscriptions">
			<div className="subscriptions__owner-info">
				<UserInfoWide
					profileId={profileId}
					user={owner}
					isFull={false}
					subscribeCallback={() => subscribeCallback(owner.userId)}
					unsubscribeCallback={() =>
						unsubscribeCallback(owner.userId)
					}
				/>
			</div>
			<div className="subscriptions__search">
				<SearchField
					placeholder={'Поиск'}
					searchCallback={searchCallback}
				/>
			</div>
			<h1 className="subscriptions__title-block">{titleNode}</h1>
			<div className="subscriptions__users">{usersNodes}</div>
		</div>
	);
};
