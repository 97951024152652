import './SidePanel.scss';

import React, { useRef, useState } from 'react';

import { CloseButton } from '../CloseButton/CloseButton';
import { Icon } from '../Icon/Icon';
import { useClickAway } from 'react-use';

type SidePanelProps = {
	toggleIconSrc: string;
};

export const SidePanel: React.FC<React.PropsWithChildren<SidePanelProps>> = ({
	children,
	toggleIconSrc,
}) => {
	const [isSidePanelOpened, setIsSidePanelOpened] = useState<boolean>(false);

	const refSidePanel = useRef<HTMLDivElement>(null);
	useClickAway(refSidePanel, () => setIsSidePanelOpened(false));

	const closeSidePaneNode: React.ReactNode = isSidePanelOpened && (
		<CloseButton
			topPx={56}
			rightPx={26}
			closePopupCallback={() => setIsSidePanelOpened(false)}
		/>
	);

	const toggleButtonNode: React.ReactNode = (
		<div
			className="side-panel__toggle"
			onClick={() => setIsSidePanelOpened(!isSidePanelOpened)}
		>
			<Icon src={toggleIconSrc} title="" sizePx={30} />
		</div>
	);

	const sidePanelToggleClassName = isSidePanelOpened
		? 'side-panel--opened'
		: 'side-panel--closed';

	return (
		<div className="side-panel__container" ref={refSidePanel}>
			{toggleButtonNode}
			<div className={`side-panel ${sidePanelToggleClassName}`}>
				{closeSidePaneNode}
				<div className="side-panel__content">{children}</div>
			</div>
		</div>
	);
};
