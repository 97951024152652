import './HeaderMenu.scss';

import React, { useEffect, useState } from 'react';
import {
	URL_LOGIN_PATH,
	URL_NEWS_PATH,
	URL_PENDING_CART_PATH,
	URL_USER_PAGE,
} from '../../constants/urls.constants';

import { CheckNotificationsService } from '../../services/notifications/check-notifications.service';
import { ERROR_REASON_UNAUTHORIZED } from '../../constants/api-errors.constants';
import { Errors } from '../../types/api-error.type';
import { FetchEmojisService } from '../../services/fetch-all-emojis.service';
import { FetchNotificationsService } from '../../services/notifications/fetch-notifications.service';
import { Icon } from '../../components/Icon/Icon';
import { LogoutService } from '../../services/auth/logout.service';
import { MenuButton } from '../../components/Dropdown/MenuButton/MenuButton';
import { NotificationEntity } from '../../entities/notification.entity';
import { Notifications } from '../../components/Notifications/Notifications';
import { ProfileDropdown } from './ProfileDropdown/ProfileDropdown';
import { TelegramAuthService } from '../../services/auth/telegram-auth.service';
import { TelegramUser } from '../../utils/reactTelegramAuthButton';
import { Toolbar } from '../../components/Toolbar/Toolbar';
import { getAuthInfoService } from '../../services/auth/get-auth-info.service';
import homeIconSrc from '../../assets/icons/common/home.svg';
import homeIconWhiteSrc from '../../assets/icons/common/home-white.svg';
import newIconSrc from '../../assets/icons/common/new-dot.svg';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { useRootStore } from '../../stores/RootStore';

export const HeaderMenu: React.FC = observer(() => {
	const { profileStore, eventsStore } = useRootStore();
	const { emojiStore, profileInfo } = profileStore;
	const [homeHover, setHomeHover] = useState<boolean>(false);

	const location = useLocation();

	const [notifications, setNotifications] = useState<NotificationEntity[]>(
		[],
	);
	const [isNotificationsChecked, setIsNotificationsChecked] =
		useState<boolean>(false);

	const isNewNotificationsExist: boolean =
		!isNotificationsChecked &&
		notifications.length > 0 &&
		!notifications[0].isViewed;

	const checkNotifications = async () => {
		if (!isNewNotificationsExist) {
			return;
		}

		const serviceResponse = await CheckNotificationsService();
		if (serviceResponse.status === 'OK') {
			setIsNotificationsChecked(true);
			return;
		}

		eventsStore.pushUnknownErrorEvent();
	};

	const logout = async () => {
		await LogoutService();
		profileStore.logout();
	};

	const getAuthInfo = async () => {
		const serviceResponse = await getAuthInfoService();
		if (serviceResponse.status === 'OK') {
			profileStore.setProfileInfo(serviceResponse.data);
		} else if (
			serviceResponse.data &&
			serviceResponse.data.reason === ERROR_REASON_UNAUTHORIZED
		) {
			profileStore.logout();
		} else {
			eventsStore.pushUnknownErrorEvent();
		}
	};

	useEffect(() => {
		getAuthInfo();
	}, [location.pathname]);

	const fetchEmojis = async () => {
		const serviceResponse = await FetchEmojisService();
		if (serviceResponse.status === 'OK') {
			emojiStore.setAllEmojis(serviceResponse.data.allEmojis);
			emojiStore.setLastUsedEmojis(
				serviceResponse.data.userLastUsedEmojis,
			);
		} else if (
			serviceResponse.data &&
			serviceResponse.data.reason === ERROR_REASON_UNAUTHORIZED
		) {
			//pass
		} else {
			eventsStore.pushUnknownErrorEvent();
		}
	};

	const fetchNotifications = async () => {
		const serviceResponse = await FetchNotificationsService();
		if (serviceResponse.status === 'OK') {
			setNotifications(serviceResponse.data);
		} else if (
			serviceResponse.data &&
			serviceResponse.data.reason === ERROR_REASON_UNAUTHORIZED
		) {
			//pass
		} else {
			eventsStore.pushUnknownErrorEvent();
		}
	};

	useEffect(() => {
		if (!profileStore.isAuth) {
			return;
		}

		fetchEmojis();
		fetchNotifications();
	}, [profileStore.isAuth]);

	const homeIcon: React.ReactNode = (
		<div className="header__home-icon">
			<Icon
				src={homeHover ? homeIconWhiteSrc : homeIconSrc}
				title="Домой"
				sizePx={26}
			/>
		</div>
	);
	const homeNode: React.ReactNode = profileStore.isAuth && profileInfo && (
		<div
			className="header__item header__item--home"
			onMouseEnter={() => setHomeHover(true)}
			onMouseLeave={() => setHomeHover(false)}
		>
			<MenuButton
				title={homeIcon}
				urlPath={URL_USER_PAGE`${profileInfo.pageUrl}`}
			/>
		</div>
	);

	const newsExistIcon = profileStore.isAuth &&
		profileStore.profileInfo?.hasFeedNews && (
			<img src={newIconSrc} alt="new" width={8} />
		);
	const NewsFeedNode: React.ReactNode = profileStore.isAuth && (
		<MenuButton
			title={'Новое'}
			urlPath={URL_NEWS_PATH}
			extraInfo={newsExistIcon}
		/>
	);

	const pendingCartCountNode: React.ReactNode = profileStore.isAuth &&
		profileStore.profileInfo &&
		profileStore.profileInfo.pendingCartsCount > 0 && (
			<span className="header__count">
				{profileStore.profileInfo.pendingCartsCount}
			</span>
		);
	const PendingCartsNode: React.ReactNode = profileStore.isAuth && (
		<MenuButton
			title={'Отложенное'}
			urlPath={URL_PENDING_CART_PATH}
			extraInfo={pendingCartCountNode}
		/>
	);

	const leftSideElements = [homeNode, NewsFeedNode, PendingCartsNode];

	const profileMenuNode: React.ReactNode = (
		<ProfileDropdown
			profile={profileStore.profileInfo!}
			logoutCallback={logout}
		/>
	);
	const loginMenuNode: React.ReactNode = (
		<MenuButton title={'Войти'} urlPath={URL_LOGIN_PATH} />
	);
	const profileItem = profileStore.isAuth ? profileMenuNode : loginMenuNode;

	const notificationsNode: React.ReactNode = profileStore.isAuth && (
		<Notifications
			notifications={notifications}
			isNewNotificationsExist={isNewNotificationsExist}
			readNotificationsCallback={checkNotifications}
		/>
	);

	const rightSideElements = [notificationsNode, profileItem];

	const onTelegramAuth = async (telegramUser: TelegramUser) => {
		const serviceResponse = await TelegramAuthService(telegramUser);
		if (serviceResponse.status === 'OK') {
			window.location.reload();
		} else {
			const errors: Errors = serviceResponse.data;
			if (errors['non_field_errors']) {
				eventsStore.pushErrorEvent(errors['non_field_errors'][0]);
			}
		}
	};

	const isTelegramAuthNotificationShow =
		profileInfo !== null && !profileInfo.isTelegramAuthorized;

	return (
		<header>
			<Toolbar
				leftSideElements={leftSideElements}
				rightSideElements={rightSideElements}
				onTelegramAuth={onTelegramAuth}
				isTelegramAuthNotificationShow={isTelegramAuthNotificationShow}
			/>
		</header>
	);
});
