import './TextInput.scss';

import React, { useEffect, useRef } from 'react';

import { useAutosizeTextArea } from '../../../utils/useAutosizeTextArea';

type TextInputProps = {
	label: string;
	value: string;
	inputType?: 'password' | 'email';
	setValueCallback?: (title: string) => void;
	error?: string | undefined;
	autoFocus?: boolean;
	maxLength?: number;
	multiLines?: boolean;
	disabled?: boolean;
};

export const TextInput: React.FC<TextInputProps> = ({
	label,
	value,
	inputType,
	setValueCallback = () => null,
	error = undefined,
	autoFocus = false,
	maxLength = 150,
	multiLines = false,
	disabled = false,
}) => {
	const labelOpacity: number = value ? 1 : 0;
	const type = inputType || 'text';

	const inputNode: React.ReactNode = (
		<input
			className="form-text-input__input"
			required
			autoFocus={autoFocus}
			type={type}
			name="text-input"
			maxLength={maxLength}
			placeholder={label}
			disabled={disabled}
			onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
				setValueCallback(e.target.value)
			}
			value={value}
		/>
	);

	const refNote = useRef<HTMLTextAreaElement>(null);
	useAutosizeTextArea(refNote.current, value);
	useEffect(() => {
		if (refNote.current) {
			refNote.current.style.height = '0px';
			const scrollHeight = refNote.current.scrollHeight;
			refNote.current.style.height = scrollHeight + 'px';
		}
	}, [refNote]);
	const textNode: React.ReactNode = (
		<textarea
			ref={refNote}
			className="form-text-input__text"
			maxLength={maxLength}
			onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
				setValueCallback(e.target.value)
			}
			value={value}
			autoFocus={autoFocus}
			placeholder={label}
		></textarea>
	);

	const errorNode: React.ReactNode = error && (
		<span className="form-text-input__error">{error}</span>
	);
	return (
		<div className="form-text-input">
			<label
				className="form-text-input__label"
				htmlFor="text-input"
				style={{ opacity: labelOpacity }}
			>
				{label}
			</label>
			{multiLines ? textNode : inputNode}
			{errorNode}
		</div>
	);
};
