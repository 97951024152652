export const NOTIFICATION_TYPE_USER_EMOJI = 'user_emoji';
export const NOTIFICATION_TYPE_CART_REPOST = 'cart_repost';
export const NOTIFICATION_TYPE_SUBSCRIBER = 'subscriber';
export const NOTIFICATION_TYPE_SYSTEM = 'system';
export const NOTIFICATION_TYPE_CART_NOTES = 'cart_notes';
export const NOTIFICATION_TYPE_TO_PENDING = 'to_pending';

export type NOTIFICATION_TYPES =
	| 'user_emoji'
	| 'cart_repost'
	| 'subscriber'
	| 'system'
	| 'cart_notes'
	| 'to_pending';
