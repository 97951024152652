import { RepositoryResponse, request } from '../request.repository';

import { Errors } from '../../types/api-error.type';
import { URL_API_SUBSCRIPTION_SUBSCRIBE } from '../../constants/urls.constants';

export interface ServiceResponseSuccess {
	status: 'OK';
}

export interface ServiceResponseError {
	status: 'ERROR';
	errors: Errors;
}

export async function SubscribeService(
	userId: number,
): Promise<ServiceResponseSuccess | ServiceResponseError> {
	const url = URL_API_SUBSCRIPTION_SUBSCRIBE`${userId.toString()}`;
	const method = 'POST';
	const options = { method };

	const response: RepositoryResponse = await request(url, options);

	if (response.status === 'OK') {
		return { status: 'OK' };
	}

	return {
		status: 'ERROR',
		errors: response.data,
	};
}
