import React, { useState } from 'react';

import { ConfirmBlock } from '../../components/ConfirmBlock/ConfirmBlock';
import { deleteCart } from '../../services/carts/delete-cart.service';
import { useRootStore } from '../../stores/RootStore';

type EditCartProps = {
	cartId: number;
	cartTitle: string;
	cartDeletedCallback: () => void;
};

export const DeleteCart: React.FC<EditCartProps> = ({
	cartId,
	cartTitle,
	cartDeletedCallback,
}) => {
	const { eventsStore } = useRootStore();

	const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

	const confirmBlockTitle = `Удалить ${cartTitle}?`;
	const submitButtonName = 'Удалить';

	const submitDeletingCart = async () => {
		setIsSubmitLoading(true);
		const deleteCartResponse = await deleteCart(cartId);
		if (deleteCartResponse.status === 'OK') {
			cartDeletedCallback();
		} else {
			eventsStore.pushUnknownErrorEvent();
		}
		setIsSubmitLoading(false);
	};

	return (
		<ConfirmBlock
			title={confirmBlockTitle}
			submitButtonName={submitButtonName}
			confirmCallback={submitDeletingCart}
			isLoading={isSubmitLoading}
		/>
	);
};
