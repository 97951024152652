import React, { useContext } from 'react';

import { EventsStore } from './EventsStore';
import { FormStateStore } from './FormStateStore';
import { LOCAL_STORAGE_KEY_CREATE_CART_FORM_STATE } from '../constants/local-storage.constants';
import { ProfileStore } from './ProfileStore';

interface Store {
	profileStore: ProfileStore;
	eventsStore: EventsStore;
	createCartStateForm: FormStateStore;
}

const StoreContext = React.createContext<Store>({} as Store);

const rootStore: Store = {
	profileStore: new ProfileStore(),
	eventsStore: new EventsStore(),
	createCartStateForm: new FormStateStore(
		LOCAL_STORAGE_KEY_CREATE_CART_FORM_STATE,
	),
};

const RootStateProvider: React.FC<React.PropsWithChildren<unknown>> = ({
	children,
}) => {
	return (
		<StoreContext.Provider value={rootStore}>
			{children}
		</StoreContext.Provider>
	);
};

const useRootStore = (): Store => useContext(StoreContext);

export { rootStore, RootStateProvider, useRootStore };
