import { FilterStore } from './FilterStore';
import { OrderingStore } from './OrderingStore';
import { PaginationStore } from './PaginationStore';
import { SearchStore } from './SearchStore';
import { makeAutoObservable } from 'mobx';

export class UserPageStore {
	paginationStore: PaginationStore = new PaginationStore();
	orderingStore: OrderingStore = new OrderingStore('');
	searchStore: SearchStore = new SearchStore();

	filterCategoryStore: FilterStore = new FilterStore();

	constructor() {
		makeAutoObservable(this);
	}

	get isFiltered(): boolean {
		if (
			this.searchStore.searchQuery ||
			this.filterCategoryStore.picked.length > 0
		) {
			return true;
		}

		return false;
	}

	clear = (): void => {
		this.paginationStore.setNextPage(null);
		this.orderingStore.setOrder(
			this.orderingStore.defaultOrderBy,
			this.orderingStore.defaultOrdering,
		);
		this.searchStore.setSearchQuery(null);
		this.filterCategoryStore.clearPickedItems();
	};
}
