import './FullCart.scss';

import React, { useState } from 'react';

import { Icon } from '../../Icon/Icon';
import { URL_CART_PAGE } from '../../../constants/urls.constants';
import deleteIconSrc from '../../../assets/icons/cart/delete.svg';
import linkIconSrc from '../../../assets/icons/cart/link.svg';
import repostIconSrc from '../../../assets/icons/cart/repost.svg';
import toPendingIconSrc from '../../../assets/icons/cart/time.svg';

type FullCartControlsProps = {
	isAuth: boolean;
	isMyCart: boolean;
	cartId: number;

	toPendingCartCallback?: () => void;
	repostCartCallback?: () => void;
	deleteCartCallback?: () => void;
};

export const FullCartControls: React.FC<FullCartControlsProps> = ({
	isAuth,
	isMyCart,
	cartId,
	toPendingCartCallback,
	repostCartCallback,
	deleteCartCallback,
}) => {
	const [isCartUrlCopied, setIsCartUrlCopied] = useState<boolean>(false);

	const toPendingControlNode: React.ReactNode = isAuth &&
		!isMyCart &&
		toPendingCartCallback && (
			<div className="full_cart__control" onClick={toPendingCartCallback}>
				<Icon src={toPendingIconSrc} title="Отложить" sizePx={22} />
			</div>
		);

	const repostControlNode: React.ReactNode = isAuth &&
		!isMyCart &&
		repostCartCallback && (
			<div className="full_cart__control" onClick={repostCartCallback}>
				<Icon src={repostIconSrc} title="Добавить к себе" sizePx={22} />
			</div>
		);

	const deleteControlNode: React.ReactNode = isAuth &&
		isMyCart &&
		deleteCartCallback && (
			<div className="full_cart__control" onClick={deleteCartCallback}>
				<Icon src={deleteIconSrc} title="Удалить" sizePx={22} />
			</div>
		);

	const copyCartUrl = async () => {
		const cartUrlPath = URL_CART_PAGE`${cartId.toString()}`;
		const cartFullUrl = `${window.location.origin}${cartUrlPath}`;
		await navigator.clipboard.writeText(cartFullUrl);
		setIsCartUrlCopied(true);
	};
	const cartUrlCopiedClass = isCartUrlCopied
		? 'full_cart__control--black'
		: '';
	const linkCopyControlNode: React.ReactNode = (
		<div
			className={`full_cart__control ${cartUrlCopiedClass}`}
			onClick={copyCartUrl}
		>
			<Icon
				src={linkIconSrc}
				title="Скопировать ссылку на карточку"
				sizePx={26}
			/>
		</div>
	);

	return (
		<div className="full_cart__control-block">
			{toPendingControlNode}
			{repostControlNode}
			{deleteControlNode}
			{linkCopyControlNode}
		</div>
	);
};
