import './FullCart.scss';

import { Image } from '../../Image/Image';
import { ImageInput } from '../../FormInputs/ImageInput/ImageInput';
import React from 'react';

type FullCartImageProps = {
	imageUrl: string | null;
	imageFile: File | null;
	setImageUrlCallback: (imageUrl: string | null) => void;
	setImageFileCallback: (imageFile: File | null) => void;

	isAllowChanging: boolean;
	error: string | undefined;
};

export const FullCartImage: React.FC<FullCartImageProps> = ({
	imageUrl,
	imageFile,
	setImageUrlCallback,
	setImageFileCallback,
	isAllowChanging,
	error,
}) => {
	const inputNode: React.ReactNode = (
		<ImageInput
			file={imageFile}
			setFile={setImageFileCallback}
			imageUrl={imageUrl}
			setImageUrl={setImageUrlCallback}
			error={error}
			imageMaxHeight={350}
		/>
	);

	const displayNode: React.ReactNode = (
		<div className="full-cart__image-container">
			<div className="full-cart__image">
				{imageUrl && (
					<Image src={imageUrl} maxHeight={350} maxWidth={350} />
				)}
			</div>
		</div>
	);

	const finalNode: React.ReactNode = isAllowChanging
		? inputNode
		: displayNode;

	return <div>{finalNode}</div>;
};
