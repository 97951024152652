import './PageWithSidePanel.scss';

import React from 'react';

type PageWithSidePanelProps = {
	sidePanel: React.ReactNode;
	content: React.ReactNode;
	popUps?: React.ReactNode[];
};

export const PageWithSidePanel: React.FC<PageWithSidePanelProps> = ({
	sidePanel,
	content,
	popUps,
}) => {
	return (
		<div className="page-with-side-panel">
			{popUps}
			{sidePanel}
			<div className="page-with-side-panel__content">{content}</div>
		</div>
	);
};
