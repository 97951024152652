import './NotesInput.scss';
import '../FormInputs.scss';

import React, { useState } from 'react';

import { CartNoteEntity } from '../../../entities/cart-note.entity';
import { NoteInput } from './NoteInput';
import crossedEyeIconSrc from '../../../assets/icons/cart/crossed-eye.svg';
import deleteIconSrc from '../../../assets/icons/cart/delete.svg';

type NotesInputProps = {
	notes: CartNoteEntity[];
	setNotesCallback: (notes: CartNoteEntity[]) => void;
	error: string | undefined;
};

export const NotesInput: React.FC<NotesInputProps> = ({
	notes,
	setNotesCallback,
	error,
}) => {
	const newNoteTemplate: CartNoteEntity = {
		id: 0,
		text: '',
		isSpoiler: false,
	};
	const [newNote, setNewNote] = useState<CartNoteEntity>({
		...newNoteTemplate,
	});

	const maxNotesCount = 20;

	const renderNote = (
		note: CartNoteEntity,
		index: number,
	): React.ReactNode => {
		const setNoteCallback = (note: CartNoteEntity) => {
			const newNotes = [...notes];
			newNotes[index] = note;
			setNotesCallback(newNotes);
		};
		const clickAwayCallback = () => {
			if (note.text.length === 0) {
				const newNotes = [...notes];
				newNotes.splice(index, 1);
				setNotesCallback(newNotes);
			}
		};
		const deleteNote = () => {
			const newNotes = [...notes];
			newNotes.splice(index, 1);
			setNotesCallback(newNotes);
		};
		const setIsSpoiler = () => {
			const newNotes = [...notes];
			const note = newNotes[index];
			note.isSpoiler = !note.isSpoiler;
			newNotes[index] = note;
			setNotesCallback(newNotes);
		};

		const isActiveSpoilerOption = note.isSpoiler
			? 'notes-input__note-control-option--active'
			: '';
		const spoilerOptionTitle = note.isSpoiler
			? 'Убрать спойлер'
			: 'Отметить как спойлер';
		return (
			<div className="notes-input__note" key={`note__${index}`}>
				<div className="notes-input__note-input">
					<NoteInput
						note={note}
						setNoteCallback={setNoteCallback}
						clickAwayCallback={clickAwayCallback}
					/>
				</div>
				<div className="notes-input__note-control">
					<div
						className={`notes-input__note-control-option ${isActiveSpoilerOption}`}
						title={spoilerOptionTitle}
					>
						<img
							className="notes-input__note-control-option-icon"
							src={crossedEyeIconSrc}
							alt="spoiler"
							onClick={setIsSpoiler}
						/>
					</div>
					<div
						className="notes-input__note-control-option"
						title="Удалить"
					>
						<img
							className="notes-input__note-control-option-icon"
							src={deleteIconSrc}
							alt="delete"
							onClick={deleteNote}
						/>
					</div>
				</div>
			</div>
		);
	};
	const notesNodes: React.ReactNode[] = notes.map(renderNote);

	const setNewNoteCallback = (note: CartNoteEntity) => {
		setNewNote({ ...note });
	};
	const addNewNoteCallback = () => {
		if (newNote.text.length === 0) {
			return;
		}

		setNotesCallback([...notes, newNote]);
		setNewNote({ ...newNoteTemplate });
	};

	const isNewNoteAvailable = notes.length < maxNotesCount;
	const newNoteNode: React.ReactNode = isNewNoteAvailable && (
		<div className="notes-input__note-input">
			<NoteInput
				note={newNote}
				placeholder={'Добавь заметку...'}
				setNoteCallback={setNewNoteCallback}
				clickAwayCallback={addNewNoteCallback}
				onEnterKeyPress={addNewNoteCallback}
			/>
		</div>
	);

	const errorNode: React.ReactNode = error && (
		<span className="form-input__error">{error}</span>
	);
	return (
		<div className="notes-input-container">
			<label className="form-input__label">Заметки</label>
			<div className="notes-input">
				{notesNodes}
				{newNoteNode}
			</div>
			{errorNode}
		</div>
	);
};
