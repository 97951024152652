import './OrderPanel.scss';

import { ORDERING_CHOICES } from '../../constants/order.constants';
import React from 'react';
import { observer } from 'mobx-react-lite';

export type OrderItem = {
	orderByKey: string;
	titleDsc: string;
	titleAsc: string;
	defaultOrdering: ORDERING_CHOICES;
};

type OrderPanelProps = {
	ordering: ORDERING_CHOICES;
	orderBy: string;
	orderItems: OrderItem[];
	setOrderCallback: (order_by: string, ordering: ORDERING_CHOICES) => void;
};

export const OrderPanel: React.FC<OrderPanelProps> = observer(
	({ ordering, orderBy, orderItems, setOrderCallback }) => {
		const renderOrderItem = (orderItem: OrderItem): React.ReactNode => {
			const isOrderedBy = orderItem.orderByKey === orderBy;
			const orderedItemClass = isOrderedBy
				? 'order-panel__order-item--picked'
				: '';

			const defaultOrdering = orderItem.defaultOrdering;
			const nonDefaultOrdering =
				orderItem.defaultOrdering === ORDERING_CHOICES.ASC
					? ORDERING_CHOICES.DSC
					: ORDERING_CHOICES.ASC;

			let defaultOrderingTitle = orderItem.titleAsc;
			let nonDefaultOrderingTitle = orderItem.titleDsc;
			if (defaultOrdering === ORDERING_CHOICES.DSC) {
				defaultOrderingTitle = orderItem.titleDsc;
				nonDefaultOrderingTitle = orderItem.titleAsc;
			}

			const orderItemTitle =
				isOrderedBy && ordering !== defaultOrdering
					? nonDefaultOrderingTitle
					: defaultOrderingTitle;

			const setOrder = () => {
				const newOrdering =
					isOrderedBy && ordering === defaultOrdering
						? nonDefaultOrdering
						: defaultOrdering;
				setOrderCallback(orderItem.orderByKey, newOrdering);
			};
			return (
				<div
					className={`order-panel__order-item ${orderedItemClass}`}
					key={orderItem.orderByKey}
					onClick={setOrder}
				>
					<span className="order-panel__order-item-title">
						{orderItemTitle}
					</span>
				</div>
			);
		};
		const orderItemsNodes: React.ReactNode[] =
			orderItems.map(renderOrderItem);

		return (
			<div className="order-panel">
				<div className="order-panel__controls">
					<span className="order-panel__controls-title">
						Порядок:{' '}
					</span>
					{orderItemsNodes}
				</div>
			</div>
		);
	},
);
