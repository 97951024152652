import './FullCart.scss';

import {
	Option,
	SingleChoiceInput,
} from '../../FormInputs/SingleChoiceInput/SingleChoiceInput';
import React, { useState } from 'react';

import { CollectionEntity } from '../../../entities/collection.entity';
import { Link } from 'react-router-dom';
import { URL_USER_COLLECTION_PAGE } from '../../../constants/urls.constants';

type FullCartCollectionProps = {
	isCartChanging: boolean;
	setIsCartChanging: (isChanging: boolean) => void;

	collection: CollectionEntity;
	collectionChoices: CollectionEntity[];
	pickCollectionCallback: (collection: CollectionEntity) => void;

	isAllowChanging: boolean;
	isAllowChangingClass: string;
	error: string | undefined;
};

export const FullCartCollection: React.FC<FullCartCollectionProps> = ({
	isCartChanging,
	setIsCartChanging,
	collection,
	collectionChoices,
	pickCollectionCallback,
	isAllowChanging,
	isAllowChangingClass,
	error,
}) => {
	const [isChanging, setIsChanging] = useState<boolean>(false);

	const inputNode: React.ReactNode = (
		<SingleChoiceInput
			label={'Коллекция'}
			options={collectionChoices as Option[]}
			error={error}
			selectedOption={collection}
			setSelectedOptionCallback={(option) => {
				pickCollectionCallback(option as CollectionEntity);
			}}
		/>
	);

	const displayChangingNode: React.ReactNode = (
		<span
			className={`full-cart__collection ${isAllowChangingClass}`}
			title="Коллекция"
			onClick={() => {
				setIsChanging(isAllowChanging);
				setIsCartChanging(isAllowChanging);
			}}
		>
			{collection.title}
		</span>
	);

	const displayLinkNode: React.ReactNode = (
		<Link to={URL_USER_COLLECTION_PAGE`${collection.id.toString()}`}>
			<span className="full-cart__collection" title="Коллекция">
				{collection.title}
			</span>
		</Link>
	);

	const displayNode: React.ReactNode = isAllowChanging
		? displayChangingNode
		: displayLinkNode;

	const finalNode: React.ReactNode =
		isChanging && isCartChanging ? inputNode : displayNode;

	const categoryNode: React.ReactNode = !isChanging && (
		<Link to={URL_USER_COLLECTION_PAGE`${collection.id.toString()}`}>
			<span className="full-cart__category" title="Категория">
				{collection.category.title}
			</span>
		</Link>
	);

	return (
		<div>
			{finalNode}
			{categoryNode}
		</div>
	);
};
