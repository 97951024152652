import { RepositoryResponse, request } from '../request.repository';

import { URL_API_COLLECTION_DELETE } from '../../constants/urls.constants';

export interface ServiceResponse {
	status: 'OK' | 'ERROR';
}

export async function deleteCollection(
	collectionId: number,
): Promise<ServiceResponse> {
	const url = URL_API_COLLECTION_DELETE`${collectionId.toString()}`;
	const method = 'POST';
	const options = { method };

	const response: RepositoryResponse = await request(url, options);

	if (response.status === 'OK') {
		return {
			status: 'OK',
		};
	}

	return {
		status: 'ERROR',
	};
}
