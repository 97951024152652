import './CollectionControlForm.scss';

import {
	Option,
	SingleChoiceInput,
} from '../../../FormInputs/SingleChoiceInput/SingleChoiceInput';
import React, { useState } from 'react';

import { CATEGORY_NAMES_MAP } from '../../../../constants/collections.constants';
import { CategoryEntity } from '../../../../entities/category.entity';
import { TextInput } from '../../../FormInputs/TextInput/TextInput';

type CollectionControlErrors = Partial<{ [key: string]: string[] }>;

export type CollectionControlInitialData = {
	title: string;
	category: CategoryEntity | null;
};

type CollectionControlFormProps = {
	initialData: CollectionControlInitialData;
	submitButtonName: string;
	isSubmitLoading: boolean;
	errors: CollectionControlErrors;
	setErrors: (errors: CollectionControlErrors) => void;
	submitCallback: (title: string, category: CategoryEntity | null) => void;
};

export const CollectionControlForm: React.FC<CollectionControlFormProps> = ({
	initialData,
	submitButtonName,
	isSubmitLoading,
	errors,
	submitCallback,
}) => {
	const [title, setTitle] = useState<string>(initialData.title);

	const [category, setCategory] = useState<Option | null>(
		initialData.category,
	);

	const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (!category) {
			return;
		}

		submitCallback(title, {
			id: category.id.toString(),
			title: category.title,
		});
	};

	const titleError = errors['title'];
	const inputTitleNode: React.ReactNode = (
		<div className="collection-control__input-block">
			<TextInput
				label="Название коллекции"
				value={title}
				setValueCallback={setTitle}
				error={titleError && titleError[0]}
			/>
		</div>
	);

	const categoryError = errors['category_name'];
	const categoryOptions: Option[] = Object.entries(CATEGORY_NAMES_MAP).map(
		([key, value]) => {
			return { id: key, title: value };
		},
	);
	const inputCategoryNode: React.ReactNode = (
		<div className="collection-control__input-block">
			<SingleChoiceInput
				label="Коллекция чего?"
				error={categoryError && categoryError[0]}
				options={categoryOptions}
				selectedOption={category}
				setSelectedOptionCallback={setCategory}
			/>
		</div>
	);

	const submitLoadingClass = isSubmitLoading
		? 'collection-control__submit--loading'
		: '';
	const formControlsNode: React.ReactNode = (
		<div className="collection-control__form-controls">
			<button
				disabled={isSubmitLoading}
				className={`collection-control__form-submit ${submitLoadingClass}`}
			>
				{submitButtonName}
			</button>
		</div>
	);

	return (
		<div className="collection-control">
			<form className="collection-control__form" onSubmit={submitForm}>
				<div className="collection-control__form_content">
					{inputTitleNode}
					{inputCategoryNode}
				</div>
				{formControlsNode}
			</form>
		</div>
	);
};
