import './SearchField.scss';

import React, { useEffect, useState } from 'react';

import searchImgSrc from '../../assets/icons/common/search.svg';

type SearchFieldProps = {
	placeholder: string;
	searchValue?: string | null;
	searchCallback: (searchQuery: string) => void;
};

export const SearchField: React.FC<SearchFieldProps> = ({
	placeholder,
	searchValue,
	searchCallback,
}) => {
	const clearSearchValue = searchValue ?? '';
	const [value, setValue] = useState<string>(clearSearchValue);
	useEffect(() => setValue(clearSearchValue), [clearSearchValue]);

	const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			searchCallback(value);
		}
	};

	const inputClass = `search-field__input ${
		value && 'search-field__input--active'
	}`;
	const iconClass = `search-field__icon-container ${
		value && 'search-field__icon-container--active'
	}`;

	return (
		<div className="search-field">
			<input
				className={inputClass}
				type="text"
				placeholder={placeholder}
				onKeyPress={onKeyPress}
				onChange={(e) => setValue(e.target.value)}
				value={value}
			/>
			<div className={iconClass}>
				<img
					src={searchImgSrc}
					alt="search"
					className="search-field__icon"
					onClick={() => searchCallback(value)}
				/>
			</div>
		</div>
	);
};
