import './Events.scss';

import {
	EVENT_TYPE_ERROR,
	EVENT_TYPE_SUCCESS,
} from '../../constants/events.constants';
import { useBoolean, useTimeoutFn } from 'react-use';

import { CloseButton } from '../../components/CloseButton/CloseButton';
import { EventEntity } from '../../entities/event.entity';
import React from 'react';

type EventProps = {
	event: EventEntity;
};

export const Event: React.FC<EventProps> = ({ event }) => {
	const [showEvent, setShowEvent] = useBoolean(true);

	useTimeoutFn(() => setShowEvent(false), event.timeout * 1000);

	const renderEvent = (event: EventEntity) => {
		let eventClass = '';
		switch (event.type) {
			case EVENT_TYPE_ERROR:
				eventClass = 'event--error';
				break;
			case EVENT_TYPE_SUCCESS:
				eventClass = 'event--success';
		}

		return (
			<div className={`event ${eventClass}`}>
				<CloseButton
					topPx={0}
					rightPx={10}
					closePopupCallback={() => setShowEvent(false)}
				/>
				{event.message}
			</div>
		);
	};

	const eventNode = showEvent && renderEvent(event);

	return <div>{eventNode}</div>;
};
