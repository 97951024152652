import './SidePanel.scss';

import { Filter } from '../../components/Filter/Filter';
import { FilterStore } from '../../stores/FilterStore';
import React from 'react';
import { SearchField } from '../../components/SearchField/SearchField';
import { SidePanel } from '../../components/SidePanel/SidePanel';
import filterIconSrc from '../../assets/icons/common/filter.svg';
import { observer } from 'mobx-react-lite';

type FilterProps = {
	filter: FilterStore;
	title: string;
	canPickOnlyOneOption?: boolean;
	isSearchEnabled?: boolean;
};

type SearchProps = {
	placeholder: string;
	searchValue: string | null;
	searchCallback: (searchQuery: string) => void;
};

type SearchFiltersSidePanelProps = {
	filters: FilterProps[];
	search?: SearchProps;
};

export const SearchFiltersSidePanel: React.FC<SearchFiltersSidePanelProps> =
	observer(({ filters, search }) => {
		const renderSearch = (searchProps: SearchProps): React.ReactNode => {
			const { placeholder, searchValue, searchCallback } = searchProps;
			return (
				<div className="filter-block__item">
					<SearchField
						placeholder={placeholder}
						searchValue={searchValue}
						searchCallback={searchCallback}
					/>
				</div>
			);
		};
		const searchNode = search && renderSearch(search);

		const renderFilter = (
			filterProps: FilterProps,
			index: number,
		): React.ReactNode => {
			const { filter, title, canPickOnlyOneOption, isSearchEnabled } =
				filterProps;
			return (
				<div className="filter-block__item" key={`filter__${index}`}>
					<Filter
						title={title}
						filterStore={filter}
						canPickOnlyOneOption={canPickOnlyOneOption}
						isSearchEnabled={isSearchEnabled}
					/>
				</div>
			);
		};

		const filtersNodes = filters.map(renderFilter);

		return (
			<SidePanel toggleIconSrc={filterIconSrc}>
				<div className="filter-block">
					{searchNode}
					{filtersNodes}
				</div>
			</SidePanel>
		);
	});
