import {
	CollectionControlForm,
	CollectionControlInitialData,
} from './CollectionControlForm/CollectionControlForm';
import {
	ErrorDataResponse,
	editCollection,
} from '../../../services/collections/edit-collection.service';
import React, { useState } from 'react';

import { CategoryEntity } from '../../../entities/category.entity';

type EditCollectionProps = {
	collectionId: number;
	initialData: CollectionControlInitialData;
	collectionEditedCallback: () => void;
};

export const EditCollection: React.FC<EditCollectionProps> = ({
	collectionId,
	initialData,
	collectionEditedCallback,
}) => {
	const [errors, setErrors] = useState<Partial<ErrorDataResponse>>({});
	const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

	const submitButtonName = 'Сохранить';

	const submitEditingCollection = async (
		title: string,
		category: CategoryEntity | null,
	) => {
		if (isSubmitLoading) {
			return;
		}

		setErrors({});
		if (!title) {
			setErrors({ title: ['Нужно указать название'] });
			return;
		}
		if (!category) {
			setErrors({ category_name: ['Нужно выбрать тип коллекции'] });
			return;
		}

		setIsSubmitLoading(true);

		const editCollectionResponse = await editCollection(
			collectionId,
			title,
			category.id,
		);
		if (editCollectionResponse.status === 'OK') {
			collectionEditedCallback();
		} else {
			setErrors(editCollectionResponse.errors);
		}

		setIsSubmitLoading(false);
	};

	return (
		<CollectionControlForm
			initialData={initialData}
			submitButtonName={submitButtonName}
			isSubmitLoading={isSubmitLoading}
			errors={errors}
			setErrors={setErrors}
			submitCallback={submitEditingCollection}
		/>
	);
};
