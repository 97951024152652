import { ORDERING_CHOICES } from '../constants/order.constants';
import { makeAutoObservable } from 'mobx';

export class OrderingStore {
	orderBy = '';
	ordering: ORDERING_CHOICES = ORDERING_CHOICES.DSC;

	defaultOrderBy = '';
	defaultOrdering: ORDERING_CHOICES = ORDERING_CHOICES.DSC;

	constructor(defaultOrderBy: string) {
		this.orderBy = defaultOrderBy;
		this.defaultOrderBy = defaultOrderBy;

		makeAutoObservable(this);
	}

	setOrder = (orderBy: string, ordering: ORDERING_CHOICES): void => {
		this.orderBy = orderBy;
		this.ordering = ordering;
	};

	get isDefaultOrdering(): boolean {
		return (
			this.orderBy === this.defaultOrderBy &&
			this.ordering === this.defaultOrdering
		);
	}
}
