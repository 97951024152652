import { RepositoryResponse, request } from '../request.repository';

import { URL_API_COLLECTION_EDIT } from '../../constants/urls.constants';

export interface ErrorDataResponse {
	[key: string]: Array<string>;
}

export interface ServiceResponseSuccess {
	status: 'OK';
}

export interface ServiceResponseError {
	status: 'ERROR';
	errors: Partial<ErrorDataResponse>;
}

export async function editCollection(
	collectionId: number,
	title: string,
	category: string,
): Promise<ServiceResponseSuccess | ServiceResponseError> {
	const url = URL_API_COLLECTION_EDIT`${collectionId.toString()}`;

	const data = {
		category_name: category,
		title,
	};
	const body = JSON.stringify(data);
	const method = 'POST';
	const options = {
		body,
		method,
	};
	const response: RepositoryResponse = await request(url, options);

	if (response.status === 'OK') {
		return { status: 'OK' };
	}

	return {
		status: 'ERROR',
		errors: response.data,
	};
}
