import './FullCartPage.scss';

import {
	CartEditingSubmitCallback,
	FullCart,
} from '../../components/Cart/FullCart/FullCart';
import {
	EVENT_MESSAGE_CART_CREATED,
	EVENT_MESSAGE_CART_DELETED,
	EVENT_MESSAGE_PENDING_CART_CREATED,
} from '../../constants/events.constants';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CartEntity } from '../../entities/cart.entity';
import { CollectionEntity } from '../../entities/collection.entity';
import { CreateCart } from '../../smart_components/CartControl/CreateCart';
import { CreatePendingCart } from '../../smart_components/CartControl/PendingCart/CreatePendingCart';
import { DeleteCart } from '../../smart_components/CartControl/DeleteCart';
import { ERROR_REASON_PERMISSION_DENIED } from '../../constants/api-errors.constants';
import { Errors } from '../../types/api-error.type';
import { FullCartEntity } from '../../entities/full-cart.entity';
import { LoadingData } from '../../components/LoadingData/LoadingData';
import { NoContent } from '../../components/NoContent/NoContent';
import { PendingCartEntity } from '../../entities/pending-cart.entity';
import { PopupContainer } from '../../components/PopupContainer/PopupContainer';
import { Tag } from '../../entities/tag.entity';
import { editCart } from '../../services/carts/edit-cart.service';
import { fetchCollectionChoices } from '../../services/collections/fetch-collection-choices.service';
import { fetchCollectionTags } from '../../services/collections/fetch-collection-tags.service';
import { getFullCart } from '../../services/carts/get-full-cart.service';
import { observer } from 'mobx-react-lite';
import { pushEmoji } from '../../services/carts/push-emoji.service';
import { useRootStore } from '../../stores/RootStore';

export const FullCartPage: React.FC = observer(() => {
	const { cartId } = useParams();
	const navigate = useNavigate();

	const { profileStore, eventsStore } = useRootStore();
	const { emojiStore, isAuth } = profileStore;

	const [isMyCart, setIsMyCart] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isHidden, setIsHidden] = useState<boolean>(false);
	const [fullCartEntity, setFullCartEntity] = useState<FullCartEntity | null>(
		null,
	);
	const [pickedCollection, setPickedCollection] =
		useState<CollectionEntity | null>(null);
	const [collectionChoices, setCollectionChoices] = useState<
		CollectionEntity[]
	>([]);
	const [collectionTags, setCollectionTags] = useState<Tag[]>([]);

	const [errors, setErrors] = useState<Errors>({});
	const [isEditingLoading, setIsEditingLoading] = useState<boolean>(false);
	const [isToPendingCart, setIsToPendingCart] = useState<boolean>(false);
	const [isRepostingCart, setIsRepostingCart] = useState<boolean>(false);
	const [isDeletingCart, setIsDeletingCart] = useState<boolean>(false);

	const updateCartData = async () => {
		if (!cartId) {
			return;
		}

		setFullCartEntity(null);
		const serviceResponse = await getFullCart(cartId);
		if (serviceResponse.status === 'OK') {
			const newFullCart = serviceResponse.data;
			setFullCartEntity(newFullCart);
			setIsMyCart(profileStore.userId === newFullCart.user.userId);
			setPickedCollection(newFullCart.cart.collection);
		} else if (
			serviceResponse.status === 'ERROR' &&
			serviceResponse.reason &&
			serviceResponse.reason === ERROR_REASON_PERMISSION_DENIED
		) {
			setIsHidden(true);
		} else {
			eventsStore.pushUnknownErrorEvent();
		}
		setIsLoading(false);
	};

	const fetchInitialDataEffect = () => {
		updateCartData();
	};
	useEffect(fetchInitialDataEffect, [cartId]);

	const updateCollectionChoices = async () => {
		setCollectionChoices([]);
		const serviceResponse = await fetchCollectionChoices();
		if (serviceResponse.status === 'OK') {
			setCollectionChoices(serviceResponse.data);
		} else {
			eventsStore.pushUnknownErrorEvent();
		}
	};

	const fetchIsMyCartDataEffect = () => {
		if (!isMyCart || isHidden) {
			return;
		}

		updateCollectionChoices();
	};
	useEffect(fetchIsMyCartDataEffect, [isMyCart]);

	const updateCollectionTags = async (collectionId: number) => {
		setCollectionTags([]);
		const serviceResponse = await fetchCollectionTags(
			collectionId.toString(),
		);
		if (serviceResponse.status === 'OK') {
			setCollectionTags(serviceResponse.data);
		} else {
			eventsStore.pushUnknownErrorEvent();
		}
	};

	const fetchCollectionDataEffect = () => {
		if (!pickedCollection || !isMyCart || isHidden) {
			return;
		}

		updateCollectionTags(pickedCollection.id);
	};

	const pickedCollectionState = pickedCollection && pickedCollection.id;
	useEffect(fetchCollectionDataEffect, [pickedCollectionState]);

	const pushEmojiCallback = async (emojiName: string, add: boolean) => {
		if (fullCartEntity === null) {
			return;
		}
		const response = await pushEmoji(
			fullCartEntity.cart.id,
			emojiName,
			add,
		);
		if (response.status === 'OK') {
			const newFullCartEntity = { ...fullCartEntity };
			newFullCartEntity.cart.emojis = response.data;
			setFullCartEntity(newFullCartEntity);
		} else {
			eventsStore.pushUnknownErrorEvent();
		}
	};

	const submitEditingCart: CartEditingSubmitCallback = async ({
		collection,
		title,
		rate,
		imageFile,
		imageUrl,
		notes,
		tags,
	}) => {
		if (!fullCartEntity) {
			return;
		}

		if (isEditingLoading) {
			return;
		}

		setErrors({});
		if (!title) {
			setErrors({ title: ['Нужно указать название'] });
			return;
		}

		setIsEditingLoading(true);
		const serviceResponse = await editCart(
			fullCartEntity.cart.id,
			collection.id,
			title,
			rate.id,
			imageFile,
			imageUrl,
			notes,
			tags,
		);
		if (serviceResponse.status === 'OK') {
			window.location.reload();
		} else {
			setErrors(serviceResponse.data);
		}

		setIsEditingLoading(false);
	};

	const createdPendingCallback = (pendingCart: PendingCartEntity) => {
		if (!fullCartEntity) {
			return;
		}
		eventsStore.pushSuccessEvent(
			EVENT_MESSAGE_PENDING_CART_CREATED`${pendingCart.title}`,
		);
		setIsToPendingCart(false);
	};
	const toPendingCartNode: React.ReactNode = isToPendingCart &&
		fullCartEntity && (
			<PopupContainer
				isOpened={isToPendingCart}
				closePopupCallback={() => setIsToPendingCart(false)}
			>
				<CreatePendingCart
					createdCallback={createdPendingCallback}
					initialCart={fullCartEntity.cart}
				/>
			</PopupContainer>
		);

	const createdCallback = (cart: CartEntity) => {
		eventsStore.pushSuccessEvent(
			EVENT_MESSAGE_CART_CREATED`${cart.title}${cart.collection.title}`,
		);
		setIsRepostingCart(false);
	};
	const repostCartNode: React.ReactNode = isRepostingCart &&
		fullCartEntity && (
			<PopupContainer
				isOpened={isRepostingCart}
				closePopupCallback={() => setIsRepostingCart(false)}
			>
				<CreateCart
					createdCallback={createdCallback}
					initialCart={fullCartEntity.cart}
					isRepost={true}
				/>
			</PopupContainer>
		);

	const cartDeletedCallback = () => {
		if (!fullCartEntity) {
			return;
		}
		eventsStore.pushSuccessEvent(
			EVENT_MESSAGE_CART_DELETED`${fullCartEntity?.cart.title}`,
		);
		navigate(profileStore.homePagePath);
	};
	const deleteCartNode: React.ReactNode = isDeletingCart &&
		fullCartEntity && (
			<PopupContainer
				isOpened={isDeletingCart}
				closePopupCallback={() => setIsDeletingCart(false)}
			>
				<DeleteCart
					cartId={fullCartEntity.cart.id}
					cartTitle={fullCartEntity.cart.title}
					cartDeletedCallback={cartDeletedCallback}
				/>
			</PopupContainer>
		);

	const fullCartNode: React.ReactNode = fullCartEntity !== null &&
		pickedCollection !== null && (
			<FullCart
				fullCart={fullCartEntity}
				isAuth={isAuth}
				isMyCart={isMyCart}
				allEmojis={emojiStore.allEmojis}
				lastUsedEmojis={emojiStore.lastUsedEmojis}
				pushEmojiCallback={pushEmojiCallback}
				toPendingCartCallback={() => setIsToPendingCart(true)}
				repostCartCallback={() => setIsRepostingCart(true)}
				deleteCartCallback={() => setIsDeletingCart(true)}
				cartEditingProps={{
					isEditingLoading,
					submitEditingCart,
					errors,
					setErrors,
					collection: pickedCollection,
					collectionChoices,
					pickCollectionCallback: setPickedCollection,
					collectionTags,
				}}
			/>
		);

	const isHiddenCartNode: React.ReactNode = isHidden && (
		<NoContent
			width="920px"
			text="Пользователь ограничил доступ неавторизованным пользователям"
		/>
	);

	return (
		<div className="full-cart-page__container">
			{fullCartNode}
			{toPendingCartNode}
			{repostCartNode}
			{deleteCartNode}
			<LoadingData isLoading={isLoading && !isHidden} />
			{isHiddenCartNode}
		</div>
	);
});
