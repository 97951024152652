import './CartForm.scss';

import {
	Option,
	SingleChoiceInput,
} from '../FormInputs/SingleChoiceInput/SingleChoiceInput';
import React, { useState } from 'react';

import { BubbleButton } from '../Buttons/BubbleButton/BubbleButton';
import { CATEGORY_NAMES_MAP } from '../../constants/collections.constants';
import { CartNoteEntity } from '../../entities/cart-note.entity';
import { CategoryEntity } from '../../entities/category.entity';
import { Errors } from '../../types/api-error.type';
import { Image } from '../Image/Image';
import { ImageInput } from '../FormInputs/ImageInput/ImageInput';
import { Link } from 'react-router-dom';
import { NotesInput } from '../FormInputs/NotesInput/NotesInput';
import { PendingCartEntity } from '../../entities/pending-cart.entity';
import { SecondaryButton } from '../Buttons/SecondaryButton/SecondaryButton';
import { TextInput } from '../FormInputs/TextInput/TextInput';

export type submitCallbackType = (
	category: CategoryEntity | null,
	title: string,
	picture: File | null,
	pictureUrl: string | null,
	notes: CartNoteEntity[],
) => void;

type PendingCartFormProps = {
	submitTitle: string;
	initialCart?: PendingCartEntity;
	isSubmitLoading: boolean;
	errors: Errors;
	setErrors: (errors: Errors) => void;
	submitCallback: submitCallbackType;
};

export const PendingCartForm: React.FC<PendingCartFormProps> = ({
	submitTitle,
	initialCart,
	isSubmitLoading,
	errors,
	setErrors,
	submitCallback,
}) => {
	const [picture, setPicture] = useState<File | null>(null);
	const [pictureUrl, setPictureUrl] = useState<string | null>(
		initialCart ? initialCart.pictureUrl : null,
	);
	const [title, setTitle] = useState<string>(
		initialCart ? initialCart.title : '',
	);
	const [category, setCategory] = useState<CategoryEntity | null>(
		initialCart ? initialCart.category : null,
	);
	const [notes, setNotes] = useState<CartNoteEntity[]>(
		initialCart ? initialCart.notes : [],
	);

	const clearForm = () => {
		setPicture(null);
		setPictureUrl(null);
		setTitle('');
		setCategory(null);
		setErrors({});
	};

	const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		submitCallback(category, title, picture, pictureUrl, notes);
	};

	const pictureError = errors['picture_url'] || errors['picture'];
	const inputImageNode: React.ReactNode = (
		<div>
			<ImageInput
				file={picture}
				setFile={setPicture}
				imageUrl={pictureUrl}
				setImageUrl={setPictureUrl}
				error={pictureError && pictureError[0]}
				imageMaxHeight={220}
			/>
		</div>
	);

	const sourceNode: React.ReactNode = initialCart?.source && (
		<div className="cart-control__source">
			<Link
				className="cart-control__source__image"
				to={initialCart.source.url}
			>
				<Image
					src={initialCart.source.pictureUrl}
					height={50}
					borderRadius="20%"
				/>
			</Link>
			<span className="cart-control__source__text">
				Источник:{' '}
				<Link
					to={initialCart.source.url}
					className="cart-control__source__link"
				>
					{initialCart.source.title}
				</Link>
			</span>
		</div>
	);

	const categoryError = errors['category_name'];
	const categoryOptions: Option[] = Object.entries(CATEGORY_NAMES_MAP).map(
		([key, value]) => {
			return { id: key, title: value };
		},
	);
	const inputCategoryNode: React.ReactNode = (
		<div className="cart-control__input-block">
			<SingleChoiceInput
				label="Коллекция чего?"
				error={categoryError && categoryError[0]}
				options={categoryOptions}
				selectedOption={category}
				setSelectedOptionCallback={(option) =>
					setCategory(option as CategoryEntity)
				}
			/>
		</div>
	);

	const titleError = errors['title'];
	const inputTitleNode: React.ReactNode = (
		<div className="cart-control__input-block">
			<TextInput
				label="Название"
				value={title}
				setValueCallback={setTitle}
				error={titleError && titleError[0]}
			/>
		</div>
	);

	const notesError = errors['notes'];
	const inputNotesNode: React.ReactNode = (
		<div className="cart-control__input-block">
			<NotesInput
				notes={notes}
				setNotesCallback={setNotes}
				error={notesError && notesError[0]}
			/>
		</div>
	);

	const formControlsNode: React.ReactNode = (
		<div className="cart-control__form-controls">
			<BubbleButton
				title={submitTitle}
				disabled={isSubmitLoading}
				isLoading={isSubmitLoading}
			/>
			<span className="cart-control__form-clear">
				<SecondaryButton title="Очистить" onClick={clearForm} />
			</span>
		</div>
	);

	return (
		<div className="cart-control">
			<div className="cart-control__top-color-line"></div>
			<form className="cart-control__form" onSubmit={submitForm}>
				<div className="cart-control__form_content">
					<div className="cart-control__image-container">
						{inputImageNode}
						{sourceNode}
					</div>
					<div className="cart-control__text-container">
						{inputTitleNode}
						{inputCategoryNode}
						{inputNotesNode}
					</div>
				</div>
				{formControlsNode}
			</form>
		</div>
	);
};
