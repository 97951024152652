import { Navigate } from 'react-router-dom';
import React from 'react';
import { URL_LOGIN_PATH } from '../../constants/urls.constants';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../stores/RootStore';

type ProtectedRouteProps = {
	children: React.ReactElement;
};

export const ProtectedRoute: React.FC<ProtectedRouteProps> = observer(
	({ children }) => {
		const { profileStore } = useRootStore();
		if (!profileStore.isAuth) {
			return <Navigate to={URL_LOGIN_PATH} replace />;
		}

		return children;
	},
);
