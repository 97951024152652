import { RepositoryResponse, request } from '../request.repository';

import { ERROR_REASON_NOT_FOUND } from '../../constants/api-errors.constants';
import { FullCollectionEntity } from '../../entities/collection.entity';
import { URL_API_COLLECTION_INFO } from '../../constants/urls.constants';

export interface ErrorDataResponse {
	reason: string;
}

export interface ServiceResponseSuccess {
	status: 'OK';
	data: FullCollectionEntity;
}

export interface ServiceResponseError {
	status: 'ERROR';
	data?: ErrorDataResponse;
}

async function fetchCollectionInfo(
	collection_id: string,
): Promise<ServiceResponseSuccess | ServiceResponseError> {
	const url = URL_API_COLLECTION_INFO`${collection_id}`;

	const method = 'GET';
	const options = { method };

	const response: RepositoryResponse = await request(url, options);

	if (response.status === 'OK') {
		const data: FullCollectionEntity = response.data.content.data;
		return {
			status: 'OK',
			data,
		};
	}

	if (response.statusCode === 404) {
		return {
			status: 'ERROR',
			data: { reason: ERROR_REASON_NOT_FOUND },
		};
	}

	return {
		status: 'ERROR',
	};
}

export { fetchCollectionInfo };
