import { FilterItem } from '../entities/filter-item.entity';
import { makeAutoObservable } from 'mobx';

class FilterStore {
	items: FilterItem[] = [];
	itemsPks: number[] = [];
	picked: number[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	updateItems(items: FilterItem[]): void {
		this.items = items;
		this.itemsPks = items.map((x) => x.pk);
	}

	toggleItem(itemPk: number): void {
		if (this.picked.includes(itemPk)) {
			this.picked = this.picked.filter((pk) => pk !== itemPk);
		} else {
			this.picked.push(itemPk);
		}
	}

	activateItems(itemPks: number[]): void {
		this.picked = itemPks;
	}

	toggleOnlyOneItem(itemPk: number): void {
		if (this.picked.length === 1 && this.picked[0] === itemPk) {
			this.picked = [];
		} else {
			this.picked = [itemPk];
		}
	}

	clearPickedItems(): void {
		this.picked = [];
	}

	get isNotEmpty(): boolean {
		return this.itemsCount > 0;
	}

	get itemsCount(): number {
		return this.items.length;
	}
}

export { FilterStore };
