import { AllEmojisEntity, EmojiEntity } from './../entities/emoji.entity';
import { RepositoryResponse, request } from './request.repository';

import { ERROR_REASON_UNAUTHORIZED } from '../constants/api-errors.constants';
import { URL_API_EMOJI_LIST } from '../constants/urls.constants';

interface ErrorDataResponse {
	reason: string;
}

interface ServiceResponseError {
	status: 'ERROR';
	data?: ErrorDataResponse;
}

interface AllEmojisResponseData {
	allEmojis: AllEmojisEntity;
	userLastUsedEmojis: EmojiEntity[];
}

interface ServiceResponseSuccess {
	status: 'OK';
	data: AllEmojisResponseData;
}

export async function FetchEmojisService(): Promise<
	ServiceResponseSuccess | ServiceResponseError
> {
	const method = 'GET';
	const options = {
		method,
	};

	const response: RepositoryResponse = await request(
		URL_API_EMOJI_LIST,
		options,
	);

	if (response.status === 'OK') {
		const data: AllEmojisResponseData = response.data.content.data;
		return {
			status: 'OK',
			data,
		};
	} else if (response.statusCode === 403) {
		return {
			status: 'ERROR',
			data: { reason: ERROR_REASON_UNAUTHORIZED },
		};
	}

	return { status: 'ERROR' };
}
