import {
	PendingCartForm,
	submitCallbackType,
} from '../../../components/CartForm/PendingCartForm';
import React, { useState } from 'react';

import { CartEntity } from '../../../entities/cart.entity';
import { CreatePendingCartService } from '../../../services/pending/create-pending-cart.service';
import { Errors } from '../../../types/api-error.type';
import { PendingCartEntity } from '../../../entities/pending-cart.entity';
import { URL_CART_PAGE } from '../../../constants/urls.constants';

type CreatePendingCartProps = {
	createdCallback: (pendingCart: PendingCartEntity) => void;
	initialCart?: CartEntity;
};

export const CreatePendingCart: React.FC<CreatePendingCartProps> = ({
	createdCallback,
	initialCart,
}) => {
	const [errors, setErrors] = useState<Partial<Errors>>({});
	const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

	const submitCreatingPendingCart: submitCallbackType = async (
		category,
		title,
		picture,
		pictureUrl,
		notes,
	) => {
		if (isSubmitLoading) {
			return;
		}

		setErrors({});
		if (!title) {
			setErrors({ title: ['Нужно указать название'] });
			return;
		}
		if (category === null) {
			setErrors({ category_name: ['Нужно выбрать категорию'] });
			return;
		}

		setIsSubmitLoading(true);
		const serviceResponse = await CreatePendingCartService(
			initialCart ? initialCart.id : null,
			category.id,
			title,
			picture,
			pictureUrl,
			notes,
		);
		if (serviceResponse.status === 'OK') {
			createdCallback(serviceResponse.data);
		} else {
			setErrors(serviceResponse.errors);
		}

		setIsSubmitLoading(false);
	};

	const initialPendingCart: PendingCartEntity | undefined = initialCart && {
		id: initialCart.id,
		category: initialCart.collection.category,
		title: initialCart.title,
		pictureUrl: initialCart.pictureUrl,
		thumbnailUrl: initialCart.thumbnailUrl,
		color: initialCart.color,
		notes: [],
		source: {
			title: initialCart.title,
			pictureUrl: initialCart.pictureUrl,
			url: URL_CART_PAGE`${initialCart.id.toString()}`,
		},
	};

	return (
		<PendingCartForm
			submitTitle="Добавить"
			initialCart={initialPendingCart}
			isSubmitLoading={isSubmitLoading}
			errors={errors}
			setErrors={setErrors}
			submitCallback={submitCreatingPendingCart}
		/>
	);
};
