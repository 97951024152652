import './HorizontalCart.scss';

import React, { useRef, useState } from 'react';

import { CartControlButton } from '../CartControlButton/CartControlButton';
import { Icon } from '../../Icon/Icon';
import { Image } from '../../Image/Image';
import { Link } from 'react-router-dom';
import { PendingCartEntity } from '../../../entities/pending-cart.entity';
import addIconSrc from '../../../assets/icons/cart/repost.svg';
import deleteIconSrc from '../../../assets/icons/cart/delete.svg';
import editIconSrc from '../../../assets/icons/cart/edit.svg';
import { getCartColorLineBorder } from '../../../utils/getCartColorLineBorder';
import { minifyString } from '../../../utils/minifyString';
import sourceIconSrc from '../../../assets/icons/cart/source.svg';
import textIconSrc from '../../../assets/icons/cart/text.svg';
import { useClickAway } from 'react-use';

type PendingCartProps = {
	cart: PendingCartEntity;
	editCartCallback: () => void;
	deleteCartCallback: () => void;
	addCartCallback: () => void;
};

export const PendingCart: React.FC<PendingCartProps> = ({
	cart,
	editCartCallback,
	deleteCartCallback,
	addCartCallback,
}) => {
	const [isCartFocused, setIsCartFocused] = useState<boolean>(false);

	const refPopup = useRef<HTMLDivElement>(null);
	useClickAway(refPopup, () => setIsCartFocused(false));

	const cartTitle = minifyString(cart.title, 64);
	const cartLineBorder = getCartColorLineBorder(isCartFocused, cart.color, 4);
	const cartBackgroundColor = `#${cart.color}09`;
	const cartBackgroundHeight = isCartFocused ? '100%' : '0%';

	const addCartControl: React.ReactNode = (
		<CartControlButton
			iconScr={addIconSrc}
			title="Добавить в коллекцию"
			callback={addCartCallback}
			key={`${cart.id}__add`}
		/>
	);

	const editCartControl: React.ReactNode = (
		<CartControlButton
			iconScr={editIconSrc}
			title="Редактировать"
			callback={editCartCallback}
			key={`${cart.id}__edit`}
		/>
	);

	const deleteCartControl: React.ReactNode = (
		<CartControlButton
			iconScr={deleteIconSrc}
			title="Удалить"
			callback={deleteCartCallback}
			key={`${cart.id}__delete`}
		/>
	);

	const cartControls: React.ReactNode[] = [
		addCartControl,
		editCartControl,
		deleteCartControl,
	];
	const cartControlsNode: React.ReactNode | null = isCartFocused ? (
		<div className="hcart__controls-container">
			<div className="hcart__controls">{cartControls}</div>
		</div>
	) : null;

	const cartImageNode: React.ReactNode = (
		<div className="hcart__image_container">
			<div className="hcart__image" onClick={editCartCallback}>
				<Image src={cart.thumbnailUrl} height={220} />
			</div>
		</div>
	);

	const sourceNode: React.ReactNode = cart.source && (
		<Link className="hcart__source" to={cart.source.url}>
			<div className="hcart__source__icon">
				<Icon src={sourceIconSrc} title={'Источник'} sizePx={16} />
			</div>
			Источник
		</Link>
	);

	const titleNode: React.ReactNode = (
		<p
			className="hcart__title"
			title={cart.title}
			onClick={editCartCallback}
		>
			{cartTitle}
		</p>
	);

	const categoryNode: React.ReactNode = (
		<p className="hcart__category">{cart.category.title}</p>
	);

	const notesNode: React.ReactNode = cart.notes.length > 0 && (
		<span className="hcart__notes" onClick={editCartCallback}>
			<div className="hcart__notes-icon">
				<Icon src={textIconSrc} title={'Заметки'} sizePx={16} />
			</div>
			Заметок: {cart.notes.length}
		</span>
	);

	return (
		<div className="hcart__container">
			<div
				className="hcart"
				style={{ borderTop: cartLineBorder }}
				onMouseOver={() => setIsCartFocused(true)}
				onTouchStart={() => setIsCartFocused(true)}
				onMouseLeave={() => setIsCartFocused(false)}
				ref={refPopup}
			>
				<div
					className="hcart__color_background"
					style={{
						backgroundColor: cartBackgroundColor,
						height: cartBackgroundHeight,
					}}
				></div>
				{cartControlsNode}
				{cartImageNode}
				<div className="hcart__content_container">
					<div className="hcart__title_container">
						{titleNode}
						{categoryNode}
						{notesNode}
						{sourceNode}
					</div>
				</div>
			</div>
		</div>
	);
};
