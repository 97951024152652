import './Notifications.scss';

import {
	NOTIFICATION_TYPE_SYSTEM,
	NOTIFICATION_TYPE_USER_EMOJI,
} from '../../constants/notifications.constants';
import {
	NotificationEntity,
	NotificationSourceEntity,
} from '../../entities/notification.entity';

import { Avatar } from '../Users/Avatar/Avatar';
import { Emoji } from '../Emoji/Emoji/Emoji';
import { EmojiEntity } from '../../entities/emoji.entity';
import { Icon } from '../Icon/Icon';
import { Link } from 'react-router-dom';
import React from 'react';
import newDotImgSource from '../../assets/icons/common/new-dot.svg';

type NotificationProps = {
	notification: NotificationEntity;
};

export const Notification: React.FC<NotificationProps> = ({ notification }) => {
	const { source, target, notificationType } = notification;

	const newNotificationNode: React.ReactNode = !notification.isViewed && (
		<div className="notification__new">
			<Icon src={newDotImgSource} title="new" sizePx={6} />
		</div>
	);

	const renderSourceItemNode = (
		source: NotificationSourceEntity,
		index: number,
	): React.ReactNode => {
		const sourceImageShape =
			notificationType === NOTIFICATION_TYPE_SYSTEM ? 'square' : 'circle';
		return (
			<Link
				to={source.targetUrl}
				className={`notification__source__item notification__source__item--${index}`}
				key={index}
			>
				<Avatar
					avatarUrl={source.imageUrl}
					username={source.title}
					size="default"
					shape={sourceImageShape}
				/>
			</Link>
		);
	};
	const renderSource = (
		sources: NotificationSourceEntity[],
	): React.ReactNode => {
		const sourcesNumber = source.length;
		let sourcesItems = sources.slice(0, 3).map(renderSourceItemNode);
		if (sourcesNumber > 3) {
			const additionalNumber = sourcesNumber - 3;
			const numberNode = (
				<div className="notification__source__item notification__source__item--3 notification__source__item--number">
					<span>+{additionalNumber}</span>
				</div>
			);
			sourcesItems = [...sourcesItems, numberNode];
		}

		return <div className="notification__source">{sourcesItems}</div>;
	};

	const sourceNode: React.ReactNode = renderSource(source);

	const renderEmojiNode = (
		emojiString: string,
		index: number,
	): React.ReactNode => {
		const emojiEntity: EmojiEntity = {
			picture: emojiString.length > 2 ? emojiString : null,
			name: emojiString,
		};
		return (
			<Emoji
				emojiEntity={emojiEntity}
				size="small"
				key={`emoji__${index}`}
			/>
		);
	};

	const renderMessage = (
		notification: NotificationEntity,
	): React.ReactNode => {
		if (
			notification.notificationType === NOTIFICATION_TYPE_USER_EMOJI &&
			notification.extraData
		) {
			const emojisNode = notification.extraData.map(renderEmojiNode);
			return (
				<div className="notification__content__message">
					{notification.message} {emojisNode}
				</div>
			);
		} else if (notification.notificationType === NOTIFICATION_TYPE_SYSTEM) {
			return (
				<div
					className="notification__content__message notification__content__message--system"
					dangerouslySetInnerHTML={{
						__html: notification.message,
					}}
				></div>
			);
		}

		return (
			<div className="notification__content__message">
				{notification.message}
			</div>
		);
	};

	const messageNode = renderMessage(notification);
	const contentNode: React.ReactNode = (
		<div className="notification__content">
			{messageNode}
			<span className="notification__content__datetime">
				{notification.datetimeRelative}
			</span>
		</div>
	);

	const targetNode: React.ReactNode = target && (
		<Link to={target.targetUrl} className="notification__target">
			<Icon src={target.imageUrl} title={target.title} sizePx={40} />
		</Link>
	);

	return (
		<div className="notification">
			{newNotificationNode}
			{sourceNode}
			{contentNode}
			{targetNode}
		</div>
	);
};
