import './Notifications.scss';

import React, { useState } from 'react';

import { Dropdown } from '../Dropdown/Dropdown';
import { DropdownItem } from '../Dropdown/DropdownItem';
import { DropdownList } from '../Dropdown/DropdownList';
import { DropdownToggle } from '../Dropdown/DropdownToggle';
import { Icon } from '../Icon/Icon';
import { Notification } from './Notification';
import { NotificationEntity } from '../../entities/notification.entity';
import newDotImgSource from '../../assets/icons/common/new-dot.svg';
import notificationsIconSrc from '../../assets/icons/common/notifications.svg';
import notificationsWhiteIconSrc from '../../assets/icons/common/notifications-white.svg';
import { useInterval } from 'react-use';

type NotificationsProps = {
	notifications: NotificationEntity[];
	isNewNotificationsExist: boolean;
	readNotificationsCallback: () => void;
};

export const Notifications: React.FC<NotificationsProps> = ({
	notifications,
	isNewNotificationsExist,
	readNotificationsCallback,
}) => {
	const [menuToggled, setMenuToggled] = useState<boolean>(false);

	useInterval(() => readNotificationsCallback(), menuToggled ? 1000 : null);

	const newNotificationNode: React.ReactNode = isNewNotificationsExist && (
		<div className="notifications__button__new">
			<Icon src={newDotImgSource} title="new" sizePx={8} />
		</div>
	);

	const notificationsButtonSrc: string = menuToggled
		? notificationsWhiteIconSrc
		: notificationsIconSrc;

	const dropdownToggleNode: React.ReactNode = (
		<DropdownToggle>
			<div className="notifications__button">
				<Icon
					src={notificationsButtonSrc}
					title="Уведомления"
					sizePx={28}
				/>
				{newNotificationNode}
			</div>
		</DropdownToggle>
	);

	const renderNotificationNode = (
		notification: NotificationEntity,
		index: number,
	) => {
		return (
			<DropdownItem key={`notification__${index}`}>
				<Notification notification={notification} />
			</DropdownItem>
		);
	};
	const notificationsNodes: React.ReactNode[] = notifications.map(
		renderNotificationNode,
	);

	const noNotificationsNode: React.ReactNode = (
		<DropdownItem>
			<span className="notifications__no">Нет уведомлений...</span>
		</DropdownItem>
	);

	const dropdownListElementsNode =
		notifications.length > 0 ? notificationsNodes : noNotificationsNode;

	const dropdownListNode: React.ReactNode = (
		<DropdownList>{dropdownListElementsNode}</DropdownList>
	);

	return (
		<Dropdown
			setToggledCallback={setMenuToggled}
			toggleNode={dropdownToggleNode}
			listNode={dropdownListNode}
		/>
	);
};
