import './Emoji.scss';

import { EmojiEntity } from '../../../entities/emoji.entity';
import React from 'react';
import twemoji from 'twemoji';

type emojiSizes = 'default' | 'small';

type EmojiProps = {
	emojiEntity: EmojiEntity;
	size?: emojiSizes;
};

export const Emoji: React.FC<EmojiProps> = ({
	emojiEntity,
	size = 'default',
}) => {
	const emojiNode: React.ReactNode = emojiEntity.picture ? (
		<img
			src={emojiEntity.picture}
			alt={emojiEntity.name}
			title={emojiEntity.name}
			className="emoji__img"
		/>
	) : (
		<div
			style={{ fontSize: '0px' }}
			dangerouslySetInnerHTML={{
				__html: twemoji.parse(emojiEntity.name, {
					className: 'emoji__img',
				}),
			}}
		></div>
	);

	return <div className={`emoji emoji--${size}`}>{emojiNode}</div>;
};
