import {
	PaginatedDataResponse,
	RepositoryResponse,
	request,
} from '../request.repository';

import { URL_API_SUBSCRIPTION_SIGNABLES } from '../../constants/urls.constants';
import { UserEntity } from '../../entities/user.entity';

interface ErrorDataResponse {
	[key: string]: Array<string>;
}

interface ServiceResponseError {
	status: 'ERROR';
	data: Partial<ErrorDataResponse>;
}

interface ServiceResponseSuccess {
	status: 'OK';
	data: PaginatedDataResponse<UserEntity>;
}

export async function FetchSignablesService(
	userId: number,
	search: string | null,
	page: number | null,
	perPage?: number | null,
): Promise<ServiceResponseSuccess | ServiceResponseError> {
	const getParams = Object.assign(
		{},
		page && { page: page.toString() },
		perPage && { per_page: perPage.toString() },
		search && { search },
	);
	const params = getParams ? new URLSearchParams(getParams) : '';

	const baseUrl = URL_API_SUBSCRIPTION_SIGNABLES`${userId.toString()}`;
	const url = `${baseUrl}?${params}`;

	const method = 'GET';
	const options = {
		method,
	};

	const response: RepositoryResponse = await request(url, options);

	if (response.status === 'OK') {
		const data: PaginatedDataResponse<UserEntity> = response.data.content;
		return {
			status: 'OK',
			data,
		};
	}

	return {
		status: 'ERROR',
		data: {},
	};
}
