import './ProfileDropdown.scss';

import { Avatar } from '../../../components/Users/Avatar/Avatar';
import { Link } from 'react-router-dom';
import { ProfileEntity } from '../../../entities/profile.entity';
import React from 'react';
import { URL_USER_PAGE } from '../../../constants/urls.constants';

type ProfileDropdownHeaderProps = {
	profile: ProfileEntity;
};

export const ProfileDropdownHeader: React.FC<ProfileDropdownHeaderProps> = ({
	profile,
}) => {
	const { username, pageUrl, avatar } = profile;

	return (
		<Link className="profile" to={URL_USER_PAGE`${pageUrl}`}>
			<div className="profile__avatar">
				<Avatar avatarUrl={avatar} username={username} size="default" />
			</div>
			<span className="profile__username">{username}</span>
		</Link>
	);
};
