import {
	PaginatedDataResponse,
	RepositoryResponse,
	request,
} from '../request.repository';

import { ListCollectionEntity } from '../../entities/collection.entity';
import { URL_API_COLLECTION_LIST } from '../../constants/urls.constants';

export interface SuccessServiceResponse {
	status: 'OK';
	data: PaginatedDataResponse<ListCollectionEntity>;
}

export interface ErrorServiceResponse {
	status: 'ERROR';
}

export async function fetchCollections(
	user_id: number,
	category_ids: Array<number>,
	search: string | null,
	order_by: string | null,
	ordering: 'asc' | 'dsc' | null,
	page: number | null,
	per_page: number | null,
): Promise<SuccessServiceResponse | ErrorServiceResponse> {
	const getParams = Object.assign(
		{ user_id: user_id.toString() },
		page && { page: page.toString() },
		per_page && { per_page: per_page!.toString() },
		order_by && { order_by },
		ordering && { ordering },
		search && { search },
	);
	const params = new URLSearchParams(getParams);
	const categoryIds = category_ids.map((x) => {
		return ['category_ids', x.toString()];
	});
	const categoriesParams = new URLSearchParams(categoryIds);

	const url = `${URL_API_COLLECTION_LIST}?${params}&${categoriesParams}`;

	const method = 'GET';
	const options = {
		method,
	};

	const response: RepositoryResponse = await request(url, options);

	if (response.status === 'OK') {
		const data: PaginatedDataResponse<ListCollectionEntity> =
			response.data.content;
		return {
			status: 'OK',
			data,
		};
	}

	return { status: 'ERROR' };
}
