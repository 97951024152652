import { RepositoryResponse, request } from '../request.repository';

import { ProfileEntity } from '../../entities/profile.entity';
import { URL_API_ACCOUNT_EDIT } from '../../constants/urls.constants';

export interface ErrorDataResponse {
	[key: string]: Array<string>;
}

export interface ServiceResponseSuccess {
	status: 'OK';
	data: ProfileEntity;
}

export interface ServiceResponseError {
	status: 'ERROR';
	data: Partial<ErrorDataResponse>;
}

export async function editProfile(
	avatar: File | null,
	avatarUrl: string | null,
	pageUrl: string,
	isHiddenFromUnauthorizedUsers: boolean,
): Promise<ServiceResponseSuccess | ServiceResponseError> {
	const url = URL_API_ACCOUNT_EDIT;

	const formData = new FormData();
	formData.append('page_url', pageUrl);
	formData.append(
		'is_hidden_from_unauthorized_users',
		isHiddenFromUnauthorizedUsers ? '1' : '0',
	);

	if (avatar) {
		formData.append('avatar', avatar);
	} else if (avatarUrl) {
		formData.append('avatar_url', avatarUrl);
	}

	const body = formData;
	const method = 'POST';
	const options = {
		body,
		method,
	};
	const noBaseOptions = true;

	const response: RepositoryResponse = await request(
		url,
		options,
		noBaseOptions,
	);

	if (response.status === 'OK') {
		const data: ProfileEntity = response.data.content.data;
		return {
			status: 'OK',
			data,
		};
	}

	return {
		status: 'ERROR',
		data: response.data,
	};
}
