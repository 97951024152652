import './InviteSettingsPage.scss';

import { useCallback, useEffect, useState } from 'react';

import { Errors } from '../../../types/api-error.type';
import { InviteSettings } from '../../../components/Settings/InviteSettings/InviteSettings';
import { InviteUsersDataEntity } from '../../../entities/invite-users-data.entity';
import { LoadingData } from '../../../components/LoadingData/LoadingData';
import { URL_REGISTER_BY_INVITATION } from '../../../constants/urls.constants';
import { getInviteUsersDataService } from '../../../services/account/get-invite-users-data.service';

export const InviteSettingsPage = () => {
	const [inviteData, setInviteData] = useState<InviteUsersDataEntity | null>(
		null,
	);
	const [errors, setErrors] = useState<Errors>({});
	const [isGetInviteDataLoading, setIsGetInviteDataLoading] =
		useState<boolean>(false);

	const getInviteData = useCallback(async () => {
		if (isGetInviteDataLoading) {
			return;
		}

		const serviceResponse = await getInviteUsersDataService();
		if (serviceResponse.status === 'OK') {
			setInviteData(serviceResponse.data);
		} else {
			setErrors(serviceResponse.data);
		}
	}, [isGetInviteDataLoading]);

	useEffect(() => {
		setIsGetInviteDataLoading(true);
		getInviteData();
		setIsGetInviteDataLoading(false);
	}, [getInviteData]);

	const inviteLink: string | null = inviteData
		? window.location.origin +
			URL_REGISTER_BY_INVITATION`${inviteData.invitationCode}`
		: null;
	const invitedUsersCount: number | null = inviteData
		? inviteData.invitedUsersCount
		: null;
	const maxInvitedUsersCount: number | null = inviteData
		? inviteData.maxInvitedUsersCount
		: null;

	const inviteSettingsNode: React.ReactNode = (
		<InviteSettings
			inviteLink={inviteLink}
			invitedUsersCount={invitedUsersCount}
			maxInvitedUsersCount={maxInvitedUsersCount}
			errors={errors}
		/>
	);
	const loadingNode: React.ReactNode = (
		<LoadingData isLoading={isGetInviteDataLoading}></LoadingData>
	);

	return (
		<div className="settings-page">
			<h1 className="settings-page__title">Приглашения на Годнохаб</h1>
			{inviteSettingsNode}
			{loadingNode}
		</div>
	);
};
