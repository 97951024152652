import { RepositoryResponse, request } from '../request.repository';

import { Errors } from '../../types/api-error.type';
import { URL_API_COLLECTION_CREATE } from '../../constants/urls.constants';

export interface ServiceResponseSuccess {
	status: 'OK';
}

export interface ServiceResponseError {
	status: 'ERROR';
	errors: Errors;
}

export async function createCollection(
	category: string,
	title: string,
): Promise<ServiceResponseSuccess | ServiceResponseError> {
	const data = {
		category_name: category,
		title,
	};
	const body = JSON.stringify(data);
	const method = 'POST';
	const options = {
		body,
		method,
	};

	const response: RepositoryResponse = await request(
		URL_API_COLLECTION_CREATE,
		options,
	);

	if (response.status === 'OK') {
		return { status: 'OK' };
	}

	return {
		status: 'ERROR',
		errors: response.data,
	};
}
