import './CollectionInfo.scss';

import React, { useState } from 'react';

import { BubbleButton } from '../../Buttons/BubbleButton/BubbleButton';
import { FullCollectionEntity } from '../../../entities/collection.entity';
import { SecondaryButton } from '../../Buttons/SecondaryButton/SecondaryButton';
import { TextInput } from '../../FormInputs/TextInput/TextInput';

type CollectionDescriptionProps = {
	collection: FullCollectionEntity;
	isMyCollection: boolean;
	submitDescriptionCallback?: (description: string | null) => void;
};

export const CollectionDescription: React.FC<CollectionDescriptionProps> = ({
	collection,
	isMyCollection,
	submitDescriptionCallback,
}) => {
	const [description, setDescription] = useState<string | null>(
		collection.description,
	);
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

	const addDescriptionNode: React.ReactNode = isMyCollection && (
		<span
			className="collection-description__add-button"
			onClick={() => setIsEditing(true)}
		>
			Добавить описание
		</span>
	);

	const descriptionDisplayNode: React.ReactNode = isMyCollection ? (
		<span
			className="collection-description__text collection-description__text--edit"
			onClick={() => setIsEditing(true)}
		>
			{description}
		</span>
	) : (
		<span className="collection-description__text">{description}</span>
	);

	const clear = () => {
		setDescription(collection.description);
		setIsEditing(false);
	};
	const submit = () => {
		setIsSubmitLoading(true);
		submitDescriptionCallback && submitDescriptionCallback(description);
		setIsSubmitLoading(false);
		setIsEditing(false);
	};
	const editControlsNode: React.ReactNode = (
		<div className="collection-description__edit-controls">
			<BubbleButton
				title="Сохранить"
				disabled={isSubmitLoading}
				isLoading={isSubmitLoading}
				onClick={submit}
				size="small"
			/>
			<span className="collection-description__clear-button">
				<SecondaryButton title="Отмена" onClick={clear} />
			</span>
		</div>
	);

	const descriptionEditNode: React.ReactNode = (
		<div className="collection-description__edit">
			<TextInput
				label="Описание"
				value={description || ''}
				setValueCallback={setDescription}
				error={undefined}
				autoFocus={true}
				maxLength={200}
				multiLines={true}
			/>
			{editControlsNode}
		</div>
	);

	const descriptionNode: React.ReactNode = isEditing
		? descriptionEditNode
		: description
			? descriptionDisplayNode
			: addDescriptionNode;

	return <div className="collection-description">{descriptionNode}</div>;
};
