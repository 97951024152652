import {
	CursorPaginatedDataResponse,
	RepositoryResponse,
	request,
} from '../request.repository';

import { PendingCartEntity } from './../../entities/pending-cart.entity';
import { URL_API_PENDING_CART_LIST } from '../../constants/urls.constants';

export interface ServiceResponseSuccess {
	status: 'OK';
	data: CursorPaginatedDataResponse<PendingCartEntity>;
}

export interface ServiceResponseError {
	status: 'ERROR';
}

export const FetchPendingCartsService = async (
	categoriesIds: Array<number>,
	search: string | null,
	orderBy: string | null,
	ordering: 'asc' | 'dsc' | null,
	nextCursor: string | null,
	perPage: number | null,
): Promise<ServiceResponseSuccess | ServiceResponseError> => {
	const getParams = Object.assign(
		{},
		orderBy && { order_by: orderBy },
		ordering && { ordering },
		nextCursor && { cursor: nextCursor },
		perPage && { per_page: perPage.toString() },
		search && { search },
	);
	const params = new URLSearchParams(getParams);

	const categoriesParams = new URLSearchParams(
		categoriesIds.map((id) => {
			return ['category', id.toString()];
		}),
	);

	const url = `${URL_API_PENDING_CART_LIST}?${params}&${categoriesParams}`;

	const method = 'GET';
	const options = {
		method,
	};

	const response: RepositoryResponse = await request(url, options);

	if (response.status === 'OK') {
		const data: CursorPaginatedDataResponse<PendingCartEntity> =
			response.data.content;
		return {
			status: 'OK',
			data,
		};
	}

	return { status: 'ERROR' };
};
