import './SubscriptionsPopup.scss';

import React, { useState } from 'react';

import { FetchSignablesService } from '../../../services/subscription/fetch-signables.service';
import { FetchSubscribersService } from '../../../services/subscription/fetch-subscribers.service';
import { FullUserEntity } from '../../../entities/full-user.entity';
import { LoadingData } from '../../../components/LoadingData/LoadingData';
import { SubscribeService } from '../../../services/subscription/subscribe.service';
import { Subscriptions } from '../../../components/Subscriptions/Subscriptions';
import { UnsubscribeService } from '../../../services/subscription/unsubscribe.service';
import { UserEntity } from '../../../entities/user.entity';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useRootStore } from '../../../stores/RootStore';

type SubscriptionsPopupType = 'subscribers' | 'signables';

const TYPE_OPTIONS_TO_TYPE_MAP = {
	subscribers: {
		blockTitle: 'Подписчики',
		fetchService: FetchSubscribersService,
	},
	signables: {
		blockTitle: 'Подписки',
		fetchService: FetchSignablesService,
	},
};

type SubscriptionsPopupProps = {
	type: SubscriptionsPopupType;
	profileId: number | null;
	owner: FullUserEntity;
	setOwnerIsFollowingCallback: (isFollowing: boolean) => void;
};

export const SubscriptionsPopup: React.FC<SubscriptionsPopupProps> = ({
	type,
	profileId,
	owner,
	setOwnerIsFollowingCallback,
}) => {
	const { eventsStore } = useRootStore();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [nextPage, setNextPage] = useState<number | null>(1);
	const [users, setUsers] = useState<UserEntity[]>([]);
	const [usersTotalCount, setUsersTotalCount] = useState<number | null>(null);

	const { blockTitle, fetchService } = TYPE_OPTIONS_TO_TYPE_MAP[type];

	const fetchSubscribers = async (
		userId: number = owner.userId,
		search: string | null = null,
		page: number | null = nextPage,
		initialUsers: UserEntity[] = users,
	) => {
		setIsLoading(true);
		const serviceResponse = await fetchService(userId, search, page);
		if (serviceResponse.status === 'OK') {
			const newUsers = serviceResponse.data.data;
			setUsers([...initialUsers, ...newUsers]);
			setUsersTotalCount(serviceResponse.data.meta.total_objects);
			setNextPage(serviceResponse.data.meta.next_page);
		} else {
			setNextPage(null);
			eventsStore.pushUnknownErrorEvent();
		}

		setIsLoading(false);
	};

	const search = (searchValue: string) => {
		const firstPage = 1;
		const noInitUsers: UserEntity[] = [];
		fetchSubscribers(owner.userId, searchValue, firstPage, noInitUsers);
	};

	const setIsFollowing = (userId: number, isFollowing: boolean) => {
		if (userId === owner.userId) {
			setOwnerIsFollowingCallback(isFollowing);
			return;
		}

		const userIndexToUpdate = users.findIndex(
			(obj) => obj.userId === userId,
		);
		const userToUpdate = users[userIndexToUpdate];
		userToUpdate.isFollowing = isFollowing;
		setUsers([...users]);
	};

	const subscribe = async (userId: number) => {
		const serviceResponse = await SubscribeService(userId);
		if (serviceResponse.status === 'OK') {
			setIsFollowing(userId, true);
		} else {
			eventsStore.pushUnknownErrorEvent();
		}
	};

	const unsubscribe = async (userId: number) => {
		const serviceResponse = await UnsubscribeService(userId);
		if (serviceResponse.status === 'OK') {
			setIsFollowing(userId, false);
		} else {
			eventsStore.pushUnknownErrorEvent();
		}
	};

	const isHasNextPage = nextPage !== null;
	const [infiniteScrollRef] = useInfiniteScroll({
		loading: isLoading,
		hasNextPage: isHasNextPage,
		onLoadMore: fetchSubscribers,
		disabled: false,
		rootMargin: '0px 0px 10px 0px',
	});

	const loadingNode = (
		<div className="subscriptions-popup__loading">
			<LoadingData isLoading={isLoading}>
				{isHasNextPage && <div ref={infiniteScrollRef}></div>}
			</LoadingData>
		</div>
	);

	return (
		<div className="subscriptions-popup">
			<div className="subscriptions-popup__subscription">
				<Subscriptions
					profileId={profileId}
					owner={owner}
					users={users}
					usersTotalCount={usersTotalCount}
					blockTitle={blockTitle}
					subscribeCallback={subscribe}
					unsubscribeCallback={unsubscribe}
					searchCallback={search}
				/>
			</div>
			{loadingNode}
		</div>
	);
};
