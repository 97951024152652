import './Auth.scss';

import {
	ErrorDataResponse,
	LoginService,
} from '../../services/auth/login.service';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import React, { useState } from 'react';
import TelegramLogin, {
	TelegramUser,
} from '../../utils/reactTelegramAuthButton';
import {
	URL_REGISTER_PATH,
	URL_USER_PAGE,
} from '../../constants/urls.constants';

import { LoginForm } from '../../components/Auth/LoginForm';
import { SecondaryButton } from '../../components/Buttons/SecondaryButton/SecondaryButton';
import { TELEGRAM_BOT_NAME } from '../../constants/common.constants';
import { TelegramLoginService } from '../../services/auth/telegram-login.service';
import { useRootStore } from '../../stores/RootStore';

export const LoginPage: React.FC = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams({});
	const redirect = searchParams.get('redirect');

	const { profileStore } = useRootStore();

	const [errors, setErrors] = useState<Partial<ErrorDataResponse>>({});
	const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

	const submitLogin = async (username: string, password: string) => {
		if (isSubmitLoading) {
			return;
		}

		setErrors({});
		setIsSubmitLoading(true);

		const serviceResponse = await LoginService(username, password);
		if (serviceResponse.status === 'OK') {
			const profileInfo = serviceResponse.data;
			profileStore.setProfileInfo(profileInfo);
			if (redirect) {
				navigate(redirect);
			} else {
				navigate(URL_USER_PAGE`${profileInfo.pageUrl}`);
			}
		} else {
			setErrors(serviceResponse.data);
		}

		setIsSubmitLoading(false);
	};

	const loginForm = (
		<LoginForm
			errors={errors}
			isSubmitLoading={isSubmitLoading}
			submitCallback={submitLogin}
		/>
	);

	const onTelegramAuth = async (telegramUser: TelegramUser) => {
		setIsSubmitLoading(true);

		const serviceResponse = await TelegramLoginService(telegramUser);
		if (serviceResponse.status === 'OK') {
			const profileInfo = serviceResponse.data;
			profileStore.setProfileInfo(profileInfo);
			if (redirect) {
				navigate(redirect);
			} else {
				navigate(URL_USER_PAGE`${profileInfo.pageUrl}`);
			}
		} else {
			setErrors(serviceResponse.data);
		}

		setIsSubmitLoading(false);
	};

	const telegramAuthButtonNode: React.ReactNode = (
		<TelegramLogin
			botName={TELEGRAM_BOT_NAME}
			dataOnAuth={onTelegramAuth}
			buttonSize="medium"
		/>
	);

	return (
		<div className="auth-page">
			{loginForm}
			<p className="text--small">Или так:</p>
			{telegramAuthButtonNode}
			<Link to={URL_REGISTER_PATH} className="auth-page__change_step">
				<SecondaryButton title="Или создать аккаунт" />
			</Link>
		</div>
	);
};
