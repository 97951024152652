import './Filter.scss';

import React, { useState } from 'react';

import { FilterItem } from '../../entities/filter-item.entity';
import { FilterStore } from '../../stores/FilterStore';
import { SearchField } from '../SearchField/SearchField';
import { minifyString } from '../../utils/minifyString';
import { observer } from 'mobx-react-lite';
import { useLimitItems } from '../../utils/useLimitItems';

type FilterProps = {
	title: string;
	filterStore: FilterStore;
	canPickOnlyOneOption?: boolean;
	isSearchEnabled?: boolean;
};

export const Filter: React.FC<FilterProps> = observer(
	({ title, filterStore, canPickOnlyOneOption, isSearchEnabled = false }) => {
		const minShowItemsCount = 10;

		const [search, setSearch] = useState<string>('');

		const items = search
			? filterStore.items.filter((item: FilterItem) =>
					item.name.toLowerCase().includes(search.toLowerCase()),
				)
			: filterStore.items;
		const [
			isItemsLimited,
			limitItemsCount,
			limitedItems,
			setIsItemsLimited,
		] = useLimitItems(items, minShowItemsCount);

		const toggleIsShowAllItems = () => {
			setIsItemsLimited(!isItemsLimited);
		};

		const maxTagNameLength = 25;
		const renderFilterItem = (filterItem: FilterItem): React.ReactNode => {
			const { pk, name, amount } = filterItem;
			const itemName = minifyString(name, maxTagNameLength);

			const isPickedClass = filterStore.picked.includes(pk)
				? 'filter-option-picked'
				: '';

			const onClick = canPickOnlyOneOption
				? () => filterStore.toggleOnlyOneItem(pk)
				: () => filterStore.toggleItem(pk);
			return (
				<div
					onClick={onClick}
					onDoubleClick={() => filterStore.toggleOnlyOneItem(pk)}
					className={`filter-option ${isPickedClass}`}
					key={pk}
				>
					<span title={name} className="filter-option__title">
						{itemName}
					</span>
					<span className="filter-option__items-count">
						{amount > 0 && amount}
					</span>
				</div>
			);
		};

		const filterItems: React.ReactNode[] =
			limitedItems.map(renderFilterItem);

		const showAllFilterItemsNode: React.ReactNode = isItemsLimited && (
			<div className="filter__all-options" onClick={toggleIsShowAllItems}>
				еще {limitItemsCount}
			</div>
		);

		const filterOptionsNode: React.ReactNode = (
			<div className="filter__options">
				{filterItems}
				{showAllFilterItemsNode}
			</div>
		);

		const noFilterOptionsNode: React.ReactNode = (
			<span className="filter__no-options">Ноль фильтров</span>
		);

		const filterContentNode: React.ReactNode = filterStore.isNotEmpty
			? filterOptionsNode
			: noFilterOptionsNode;

		const titleNode: React.ReactNode = (
			<h5 className="filter__header__title">{title}</h5>
		);

		const clearNode: React.ReactNode = filterStore.picked.length > 0 && (
			<span
				className="filter__header__clear"
				onClick={() => filterStore.clearPickedItems()}
			>
				Очистить
			</span>
		);

		const searchNode: React.ReactNode = isSearchEnabled &&
			filterStore.isNotEmpty && (
				<div className="filter__search">
					<SearchField
						placeholder="Найти тэг"
						searchValue={search}
						searchCallback={setSearch}
					/>
				</div>
			);

		return (
			<div className="filter">
				<div className="filter__header">
					{titleNode}
					{clearNode}
				</div>
				{searchNode}
				{filterContentNode}
			</div>
		);
	},
);
