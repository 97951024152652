import './DynamicGrid.scss';

import React, { useEffect, useState } from 'react';

import { useMeasure } from 'react-use';

type DynamicGridProps = {
	elements: React.ReactNode[];
};

export const DynamicGrid: React.FC<DynamicGridProps> = ({ elements }) => {
	const [columnsCount, setColumnsCount] = useState<number>(3);
	const [refContentArea, gridMeasure] = useMeasure<HTMLDivElement>();

	const gridWidth = Math.round(gridMeasure.width);
	const minElementWidth = 600;
	const elementWidth = Math.min(
		Math.round(gridWidth / columnsCount) - 20,
		minElementWidth,
	);

	const updateColumnsCountEffect = () => {
		if (gridWidth <= 700) {
			setColumnsCount(1);
		} else if (gridWidth > 700 && gridWidth <= 1200) {
			setColumnsCount(2);
		} else if (gridWidth > 1200) {
			setColumnsCount(3);
		}
	};
	useEffect(updateColumnsCountEffect, [gridWidth]);

	const groupElementsByColumns = (
		elements: React.ReactNode[],
		columnsCount: number,
	): React.ReactNode[][] => {
		const columns = [];
		for (
			let columnNumber = 0;
			columnNumber < columnsCount;
			columnNumber++
		) {
			const filterColumnElements = (
				value: React.ReactNode,
				index: number,
			): boolean => index % columnsCount === columnNumber;
			const column = elements.filter(filterColumnElements);
			columns.push(column);
		}
		return columns;
	};

	const renderElement = (
		element: React.ReactNode,
		index: number,
	): React.ReactNode => (
		<div
			className="carts-grid__element"
			style={{ width: `${elementWidth}px` }}
			key={`cart_grid_element__${index}`}
		>
			{element}
		</div>
	);

	const renderColumn = (
		elements: React.ReactNode[],
		index: number,
	): React.ReactNode => {
		const elementsNodes = elements.map(renderElement);
		return (
			<div
				className="carts-grid__column"
				key={`cart_grid_column__${index}`}
			>
				{elementsNodes}
			</div>
		);
	};

	const renderColumns = (
		elements: React.ReactNode[],
		columnsCount: number,
	): React.ReactNode[] => {
		const columnsWithElements = groupElementsByColumns(
			elements,
			columnsCount,
		);
		return columnsWithElements.map(renderColumn);
	};

	const columnsNodes = renderColumns(elements, columnsCount);

	return (
		<div ref={refContentArea} className="carts-grid">
			{columnsNodes}
		</div>
	);
};
