import { RepositoryResponse, request } from '../request.repository';

import { URL_API_AUTH_LOGOUT } from '../../constants/urls.constants';

export interface ErrorDataResponse {
	[key: string]: Array<string>;
}

export interface ServiceResponseSuccess {
	status: 'OK';
}

export interface ServiceResponseError {
	status: 'ERROR';
	data: Partial<ErrorDataResponse>;
}

export async function LogoutService(): Promise<
	ServiceResponseSuccess | ServiceResponseError
> {
	const url = URL_API_AUTH_LOGOUT;

	const method = 'GET';
	const options = { method };

	const response: RepositoryResponse = await request(url, options);

	if (response.status === 'OK') {
		return { status: 'OK' };
	}

	return {
		status: 'ERROR',
		data: {},
	};
}
