import { RepositoryResponse, request } from '../request.repository';

import { TelegramUser } from '../../utils/reactTelegramAuthButton';
import { URL_API_AUTH_TELEGRAM } from '../../constants/urls.constants';

export interface ErrorDataResponse {
	[key: string]: Array<string>;
}

export interface ServiceResponseSuccess {
	status: 'OK';
}

export interface ServiceResponseError {
	status: 'ERROR';
	data: Partial<ErrorDataResponse>;
}

export async function TelegramAuthService(
	telegramUser: TelegramUser,
): Promise<ServiceResponseSuccess | ServiceResponseError> {
	const url = URL_API_AUTH_TELEGRAM;

	const data = {
		auth_date: telegramUser.auth_date,
		id: telegramUser.id,
		first_name: telegramUser.first_name,
		last_name: telegramUser.last_name,
		username: telegramUser.username,
		photo_url: telegramUser.photo_url,
		hash: telegramUser.hash,
	};
	const body = JSON.stringify(data);
	const method = 'POST';
	const options = { body, method };

	const response: RepositoryResponse = await request(url, options);

	if (response.status === 'OK') {
		return {
			status: 'OK',
		};
	}

	return {
		status: 'ERROR',
		data: response.data,
	};
}
