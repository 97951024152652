import './SquareButton.scss';

import React from 'react';

type SquareButtonProps = {
	title: string;
	disabled: boolean;
	isLoading: boolean;
	isFilled: boolean;
	onClick?: () => void;
};

export const SquareButton: React.FC<SquareButtonProps> = ({
	title,
	disabled,
	isLoading,
	isFilled,
	onClick,
}) => {
	const isLoadingClass = isLoading ? 'square-button--is-loading' : '';
	const filledClass = isFilled
		? 'square-button--filled'
		: 'square-button--unfilled';

	return (
		<button
			className={`square-button ${filledClass} ${isLoadingClass}`}
			onClick={onClick}
			disabled={disabled}
		>
			{title}
		</button>
	);
};
