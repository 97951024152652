import { RepositoryResponse, request } from '../request.repository';

import { ERROR_REASON_NOT_FOUND } from '../../constants/api-errors.constants';
import { FilterItem } from '../../entities/filter-item.entity';
import { URL_API_CART_FILTERS } from '../../constants/urls.constants';

type Filters = {
	tags: FilterItem[];
	rates: FilterItem[];
};

export interface ErrorDataResponse {
	reason: string;
}

export interface ServiceResponseSuccess {
	status: 'OK';
	data: Filters;
}

export interface ServiceResponseError {
	status: 'ERROR';
	data?: ErrorDataResponse;
}

async function fetchCollectionPageFiltersItems(
	collectionId: number,
): Promise<ServiceResponseSuccess | ServiceResponseError> {
	const data = {
		collection_id: collectionId.toString(),
	};
	const params = new URLSearchParams(data);
	const url = `${URL_API_CART_FILTERS}?${params}`;

	const method = 'GET';
	const options = {
		method,
	};

	const response: RepositoryResponse = await request(url, options);

	if (response.status === 'OK') {
		const data: Filters = response.data.content.data;
		return {
			status: 'OK',
			data,
		};
	}

	if (response.statusCode === 404) {
		return {
			status: 'ERROR',
			data: { reason: ERROR_REASON_NOT_FOUND },
		};
	}

	return {
		status: 'ERROR',
	};
}

export { fetchCollectionPageFiltersItems };
