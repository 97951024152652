import './CollectionPage.scss';

import {
	CARTS_ORDER_BY_CHOICES,
	ORDERING_CHOICES,
} from '../../constants/order.constants';
import {
	EVENT_MESSAGE_CART_CREATED,
	EVENT_MESSAGE_CART_DELETED,
	EVENT_MESSAGE_CART_EDITED,
	EVENT_MESSAGE_PENDING_CART_CREATED,
} from '../../constants/events.constants';
import { OrderItem, OrderPanel } from '../../components/OrderPanel/OrderPanel';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { AddUtilCart } from '../../components/Cart/UtilCart/AddUtilCart';
import { CartEntity } from '../../entities/cart.entity';
import { CollectionInfo } from '../../components/Collection/CollectionInfo/CollectionInfo';
import { CollectionInstrument } from '../../components/Collection/CollectionInfo/CollectionInstrument';
import { CollectionPageStore } from '../../stores/CollectionPageStore';
import { CreateCart } from '../../smart_components/CartControl/CreateCart';
import { CreatePendingCart } from '../../smart_components/CartControl/PendingCart/CreatePendingCart';
import { DeleteCart } from '../../smart_components/CartControl/DeleteCart';
import { DeleteCollection } from '../../components/Collection/CollectionControl/DeleteCollection';
import { DynamicGrid } from '../../components/DynamicGrid/DynamicGrid';
import { ERROR_REASON_NOT_FOUND } from '../../constants/api-errors.constants';
import { EditCollection } from '../../components/Collection/CollectionControl/EditCollection';
import { FullCartEntity } from '../../entities/full-cart.entity';
import { FullCartPopup } from '../../smart_components/popups/FullCartPopup/FullCartPopup';
import { FullCollectionEntity } from '../../entities/collection.entity';
import { FullUserEntity } from '../../entities/full-user.entity';
import { HorizontalCart } from '../../components/Cart/HorizontalCart/HorizontalCart';
import { LoadingData } from '../../components/LoadingData/LoadingData';
import { NoContent } from '../../components/NoContent/NoContent';
import { PageWithSidePanel } from '../../components/Pages/PageWithSidePanel';
import { PendingCartEntity } from '../../entities/pending-cart.entity';
import { PopupContainer } from '../../components/PopupContainer/PopupContainer';
import { ReactedEmojiEntity } from '../../entities/emoji.entity';
import { SearchFiltersSidePanel } from '../../components/SidePanel/SearchFiltersSidePanel';
import { URL_NOT_FOUND } from '../../constants/urls.constants';
import { UserInfoPageBlock } from '../../smart_components/UserInfoPageBlock/UserInfoPageBlock';
import { fetchCollectionCarts } from '../../services/carts/fetch-carts.service';
import { fetchCollectionInfo } from '../../services/collections/fetch-collection-info.service';
import { fetchCollectionPageFiltersItems } from '../../services/collections/fetch-collection-page-filter-items.service';
import { fetchCollectionUserInfo } from '../../services/collections/fetch-collection-user-info.service';
import { getFullCart } from '../../services/carts/get-full-cart.service';
import { observer } from 'mobx-react-lite';
import { pushEmoji } from '../../services/carts/push-emoji.service';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useRootStore } from '../../stores/RootStore';

export const CollectionPage = observer(() => {
	const { collectionId } = useParams();
	const [searchParams, setSearchParams] = useSearchParams({});

	const [collectionPageStore] = useState(() => new CollectionPageStore());
	const { cursorPaginationStore, orderingStore, searchStore } =
		collectionPageStore;
	const { profileStore, eventsStore } = useRootStore();
	const { emojiStore } = profileStore;

	const navigate = useNavigate();

	const [isReadyToFetchCarts, setIsReadyToFetchCarts] =
		useState<boolean>(false);

	const [userInfo, setUserInfo] = useState<FullUserEntity | null>(null);
	const [collection, setCollection] = useState<FullCollectionEntity | null>(
		null,
	);

	const [isLoading, setIsLoading] = React.useState<boolean>(true);

	const [fullCart, setFullCart] = useState<FullCartEntity | null>(null);

	const [openedFullCartId, setOpenedFullCartId] = useState<number | null>(
		null,
	);
	const [isCreatingCart, setIsCreatingCart] = useState<boolean>(false);
	const [repostingCartId, setRepostingCartId] = useState<number | null>(null);
	const [deletingCartId, setDeletingCartId] = useState<number | null>(null);
	const [isPendingCartId, setIsPendingCartId] = useState<number | null>(null);

	const [isEditingCollection, setIsEditingCollection] =
		useState<boolean>(false);
	const [isDeletingCollection, setIsDeletingCollection] =
		useState<boolean>(false);

	const [carts, setCarts] = useState<CartEntity[]>([]);

	const isMyCollection =
		collection !== null && profileStore.userId === collection.userId;

	const updateUserInfo = async () => {
		if (!collectionId) {
			return;
		}
		setUserInfo(null);
		const serviceResponse = await fetchCollectionUserInfo(collectionId);
		if (serviceResponse.status === 'OK') {
			setUserInfo(serviceResponse.data);
		} else if (
			serviceResponse.data &&
			serviceResponse.data.reason === ERROR_REASON_NOT_FOUND
		) {
			navigate(URL_NOT_FOUND);
		} else {
			eventsStore.pushUnknownErrorEvent();
		}
	};

	const updateCollectionInfo = async () => {
		if (!collectionId || !userInfo || userInfo.isHidden) {
			return;
		}
		setCollection(null);
		const serviceResponse = await fetchCollectionInfo(collectionId);
		if (serviceResponse.status === 'OK') {
			setCollection(serviceResponse.data);
		} else if (
			serviceResponse.data &&
			serviceResponse.data.reason === ERROR_REASON_NOT_FOUND
		) {
			navigate(URL_NOT_FOUND);
		} else {
			eventsStore.pushUnknownErrorEvent();
		}
	};

	const fetchInitialUserInfoEffect = () => {
		updateUserInfo();
	};

	useEffect(fetchInitialUserInfoEffect, [collectionId]);

	const fetchInitialCollectionInfoEffect = () => {
		updateCollectionInfo();
	};

	useEffect(fetchInitialCollectionInfoEffect, [userInfo]);

	const updateFiltersItems = async () => {
		if (collection === null) {
			return;
		}
		const serviceResponse = await fetchCollectionPageFiltersItems(
			collection.id,
		);
		if (serviceResponse.status === 'OK') {
			const filters = serviceResponse.data;
			collectionPageStore.filterTagStore.updateItems(filters.tags);
			collectionPageStore.filterRateStore.updateItems(filters.rates);
			setIsReadyToFetchCarts(true);
		} else {
			eventsStore.pushUnknownErrorEvent();
		}
	};

	const fetchCollectionFiltersEffect = () => {
		updateFiltersItems();
	};

	useEffect(fetchCollectionFiltersEffect, [collection && collection.id]);

	const updateCollectionPageStoreBySearchParamsEffect = () => {
		if (!isReadyToFetchCarts) {
			return;
		}
		collectionPageStore.setStateFromSearchParams(searchParams);
		setCarts([]);
		setIsLoading(false);
		cursorPaginationStore.setNextCursor(undefined);
	};

	useEffect(updateCollectionPageStoreBySearchParamsEffect, [
		searchParams,
		isReadyToFetchCarts,
	]);

	const updateSearchParamsByCollectionPageStore = () => {
		const newSearchParams =
			collectionPageStore.createSearchParamsFromState();
		if (newSearchParams.toString() !== searchParams.toString()) {
			setSearchParams(newSearchParams);
		}
	};

	const updateSearchParamsByCollectionPageStoreEffect = () => {
		if (!isReadyToFetchCarts) {
			return;
		}
		updateSearchParamsByCollectionPageStore();
		setOpenedFullCartId(null);
	};

	useEffect(updateSearchParamsByCollectionPageStoreEffect, [
		searchStore.searchQuery,
		collectionPageStore.filterRateStore.picked,
		collectionPageStore.filterTagStore.picked,
		collectionPageStore.filterRateStore.picked.length,
		collectionPageStore.filterTagStore.picked.length,
		orderingStore.orderBy,
		orderingStore.ordering,
	]);

	const fetchPageCarts = async () => {
		if (
			!collectionId ||
			isLoading ||
			cursorPaginationStore.nextCursor === null
		) {
			return;
		}
		setIsLoading(true);
		const serviceResponse = await fetchCollectionCarts(
			collectionId,
			collectionPageStore.filterTagStore.picked,
			collectionPageStore.filterRateStore.picked,
			searchStore.searchQuery,
			orderingStore.orderBy,
			orderingStore.ordering,
			cursorPaginationStore.nextCursor || null,
			cursorPaginationStore.perPage,
		);
		if (serviceResponse.status === 'OK') {
			const newCarts = serviceResponse.data.data;
			setCarts([...carts, ...newCarts]);
			cursorPaginationStore.setNextCursor(
				serviceResponse.data.meta.next_cursor,
			);
		} else {
			eventsStore.pushUnknownErrorEvent();
			cursorPaginationStore.setNextCursor(null);
		}
		setIsLoading(false);
	};

	const [infiniteScrollRef] = useInfiniteScroll({
		loading: isLoading,
		hasNextPage: cursorPaginationStore.isHasNextPage,
		onLoadMore: fetchPageCarts,
		disabled: false,
		rootMargin: '0px 0px 10px 0px',
	});

	const reloadPage = () => window.location.reload();

	const fetchFullCartData = () => {
		const updateFullCart = async (cartId: number) => {
			const response = await getFullCart(cartId.toString());
			if (response.status === 'OK') {
				setFullCart(response.data);
			} else {
				eventsStore.pushUnknownErrorEvent();
			}
		};

		setFullCart(null);
		if (openedFullCartId !== null) {
			updateFullCart(openedFullCartId);
		}
	};
	useEffect(fetchFullCartData, [openedFullCartId]);

	const updateCartEmojis = (cartId: number, emojis: ReactedEmojiEntity[]) => {
		const cartIndexToUpdate = carts.findIndex((obj) => obj.id === cartId);
		const cartToUpdate = carts[cartIndexToUpdate];
		cartToUpdate.emojis = emojis;
		setCarts([...carts]);
	};
	const updateFullCartEmojis = (emojis: ReactedEmojiEntity[]) => {
		if (fullCart === null) {
			return;
		}
		const newFullCart = { ...fullCart };
		newFullCart.cart.emojis = emojis;
		setFullCart(newFullCart);
	};

	const renderCart = (cart: CartEntity): React.ReactNode => {
		if (!collection) {
			return;
		}

		const pushEmojiCallback = async (emojiName: string, add: boolean) => {
			const response = await pushEmoji(cart.id, emojiName, add);
			if (response.status === 'OK') {
				const emojis = response.data;
				updateCartEmojis(cart.id, emojis);
				updateFullCartEmojis(emojis);
			} else {
				eventsStore.pushUnknownErrorEvent();
			}
		};

		const updateCart = (cart: CartEntity) => {
			const cartIndexToUpdate = carts.findIndex(
				(obj) => obj.id === cart.id,
			);
			let cartToUpdate = carts[cartIndexToUpdate];
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			cartToUpdate = { ...cart, emojis: cartToUpdate.emojis };
			setCarts([...carts]);
		};
		const editedCallback = (cart: CartEntity) => {
			eventsStore.pushSuccessEvent(
				EVENT_MESSAGE_CART_EDITED`${cart.title}`,
			);
			updateCart(cart);
			setOpenedFullCartId(null);
		};

		const deleteCart = () => {
			const cartIndexToDelete = carts.findIndex(
				(obj) => obj.id === cart.id,
			);
			carts.splice(cartIndexToDelete, 1);
			setCarts([...carts]);
		};
		const deletedCallback = () => {
			eventsStore.pushSuccessEvent(
				EVENT_MESSAGE_CART_DELETED`${cart.title}`,
			);
			deleteCart();
			setOpenedFullCartId(null);
		};

		const isOpenedFullCart = openedFullCartId === cart.id;
		const fullCartNode: React.ReactNode = isOpenedFullCart && (
			<PopupContainer
				isOpened={isOpenedFullCart}
				closePopupCallback={() => setOpenedFullCartId(null)}
			>
				<FullCartPopup
					fullCart={fullCart}
					isMyCart={isMyCollection}
					allEmojis={emojiStore.allEmojis}
					lastUsedEmojis={emojiStore.lastUsedEmojis}
					pushEmojiCallback={pushEmojiCallback}
					editedCallback={editedCallback}
					deletedCallback={deletedCallback}
				/>
			</PopupContainer>
		);

		const cartDeletedCallback = () => {
			eventsStore.pushSuccessEvent(
				EVENT_MESSAGE_CART_DELETED`${cart.title}`,
			);
			deleteCart();
			setDeletingCartId(null);
		};
		const isDeletingCart = deletingCartId === cart.id;
		const deleteCartNode: React.ReactNode = isDeletingCart && (
			<PopupContainer
				isOpened={isDeletingCart}
				closePopupCallback={() => setDeletingCartId(null)}
			>
				<DeleteCart
					cartId={cart.id}
					cartTitle={cart.title}
					cartDeletedCallback={cartDeletedCallback}
				/>
			</PopupContainer>
		);

		const createdCallback = (cart: CartEntity) => {
			eventsStore.pushSuccessEvent(
				EVENT_MESSAGE_CART_CREATED`${cart.title}${cart.collection.title}`,
			);
			setRepostingCartId(null);
		};
		const isRepostingCart = repostingCartId === cart.id;
		const repostCartNode: React.ReactNode = isRepostingCart && (
			<PopupContainer
				isOpened={isRepostingCart}
				closePopupCallback={() => setRepostingCartId(null)}
			>
				<CreateCart
					createdCallback={createdCallback}
					initialCart={cart}
					isRepost={true}
				/>
			</PopupContainer>
		);

		const createdPendingCallback = (pendingCart: PendingCartEntity) => {
			eventsStore.pushSuccessEvent(
				EVENT_MESSAGE_PENDING_CART_CREATED`${pendingCart.title}`,
			);
			setIsPendingCartId(null);
		};
		const isPendingCart = isPendingCartId === cart.id;
		const pendingCartNode: React.ReactNode = isPendingCart && (
			<PopupContainer
				isOpened={isPendingCart}
				closePopupCallback={() => setIsPendingCartId(null)}
			>
				<CreatePendingCart
					createdCallback={createdPendingCallback}
					initialCart={cart}
				/>
			</PopupContainer>
		);

		return (
			<div className="collection__hcart_container" key={cart.id}>
				{fullCartNode}
				{deleteCartNode}
				{repostCartNode}
				{pendingCartNode}
				<HorizontalCart
					cart={cart}
					isMyCart={isMyCollection}
					isAuth={profileStore.isAuth}
					maxTagsCount={3}
					allEmojis={emojiStore.allEmojis}
					lastUsedEmojis={emojiStore.lastUsedEmojis}
					tagsIdsPicked={collectionPageStore.filterTagStore.picked}
					openFullCartCallback={() => setOpenedFullCartId(cart.id)}
					deleteCartCallback={() => setDeletingCartId(cart.id)}
					repostCartCallback={() => setRepostingCartId(cart.id)}
					toPendingCartCallback={() => setIsPendingCartId(cart.id)}
					pushEmojiCallback={pushEmojiCallback}
				/>
			</div>
		);
	};

	const editCollectionNode: React.ReactNode = isMyCollection &&
		isEditingCollection && (
			<PopupContainer
				isOpened={isEditingCollection}
				closePopupCallback={() => setIsEditingCollection(false)}
			>
				<EditCollection
					collectionId={collection.id}
					initialData={{
						title: collection.title,
						category: collection.category,
					}}
					collectionEditedCallback={reloadPage}
				/>
			</PopupContainer>
		);

	const deleteCollectionNode: React.ReactNode = isMyCollection &&
		isDeletingCollection && (
			<PopupContainer
				isOpened={isDeletingCollection}
				closePopupCallback={() => setIsDeletingCollection(false)}
			>
				<DeleteCollection
					collectionId={collection.id}
					collectionTitle={collection.title}
					collectionCartsCount={collection.totalCartsCount}
					collectionDeletedCallback={reloadPage}
				/>
			</PopupContainer>
		);

	const collectionInstrumentNodes = isMyCollection && [
		<CollectionInstrument
			title="Редактировать"
			onClick={() => setIsEditingCollection(true)}
			key="edit_instrument"
		/>,
		<CollectionInstrument
			title="Удалить"
			onClick={() => setIsDeletingCollection(true)}
			key="delete_instrument"
		/>,
	];

	const collectionInfoNode: React.ReactNode = collection && (
		<div className="collection-page__collection-info">
			<CollectionInfo
				collection={collection}
				isMyCollection={isMyCollection}
				isLink={false}
				instrumentNodes={collectionInstrumentNodes}
			/>
		</div>
	);

	const userInfoNode: React.ReactNode = (
		<div className="collection-page__user-info">
			<UserInfoPageBlock
				profileId={profileStore.userId}
				user={userInfo}
				setIsFollowingCallback={(isFollowing) =>
					setUserInfo(userInfo && { ...userInfo, isFollowing })
				}
			/>
		</div>
	);

	const addCart = (cart: CartEntity) => {
		carts.splice(0, 0, cart);
		setCarts([...carts]);
	};
	const createdCallback = (cart: CartEntity) => {
		eventsStore.pushSuccessEvent(
			EVENT_MESSAGE_CART_CREATED`${cart.title}${cart.collection.title}`,
		);
		addCart(cart);
		setIsCreatingCart(false);
	};
	const createCartNode: React.ReactNode | undefined = collection !== null && (
		<PopupContainer
			isOpened={isCreatingCart}
			closePopupCallback={() => setIsCreatingCart(false)}
			key={collectionId}
		>
			<CreateCart
				createdCallback={createdCallback}
				initialCollection={{
					id: collection.id,
					title: collection.title,
					category: collection.category,
				}}
			/>
		</PopupContainer>
	);

	const orderItems: OrderItem[] = [
		{
			orderByKey: CARTS_ORDER_BY_CHOICES.DATE_CREATED,
			titleDsc: 'сначала новые',
			titleAsc: 'сначала старые',
			defaultOrdering: ORDERING_CHOICES.DSC,
		},
		{
			orderByKey: CARTS_ORDER_BY_CHOICES.RATE,
			titleDsc: 'сначала не годнота',
			titleAsc: 'сначала годнота',
			defaultOrdering: ORDERING_CHOICES.ASC,
		},
	];
	const orderPanel: React.ReactNode = (
		<OrderPanel
			ordering={orderingStore.ordering}
			orderBy={orderingStore.orderBy}
			orderItems={orderItems}
			setOrderCallback={(order_by, ordering) =>
				orderingStore.setOrder(order_by, ordering)
			}
		/>
	);

	const isNeedCreateCartUtilCart = isMyCollection;

	const createCartUtilCart: React.ReactNode = (
		<div className="collection__hcart_container">
			<AddUtilCart actionCallback={() => setIsCreatingCart(true)} />
		</div>
	);

	const cartsNodes: React.ReactNode[] = carts.map(renderCart);
	const gridElements: React.ReactNode[] = isNeedCreateCartUtilCart
		? [createCartUtilCart, ...cartsNodes]
		: cartsNodes;
	const cartsGrid: React.ReactNode = <DynamicGrid elements={gridElements} />;

	const noCartsText = collectionPageStore.isFiltered
		? 'Карточек не найдено'
		: 'В коллекции пока нет карточек';
	const noCartsNode: React.ReactNode = carts.length === 0 &&
		!isMyCollection &&
		!isLoading && <NoContent text={noCartsText} />;

	const loading = (
		<LoadingData isLoading={isLoading && (!userInfo || !userInfo.isHidden)}>
			{cursorPaginationStore.isHasNextPage && (
				<div ref={infiniteScrollRef}></div>
			)}
		</LoadingData>
	);

	const sidePanel: React.ReactNode = userInfo && (
		<SearchFiltersSidePanel
			filters={[
				{
					filter: collectionPageStore.filterRateStore,
					title: 'Степень годноты',
				},
				{
					filter: collectionPageStore.filterTagStore,
					title: 'Тэги',
					isSearchEnabled: true,
				},
			]}
			search={{
				placeholder: 'Найти карточку',
				searchValue: searchStore.searchQuery,
				searchCallback: searchStore.setSearchQuery,
			}}
		/>
	);

	const isHiddenUserNode: React.ReactNode = userInfo && userInfo.isHidden && (
		<NoContent
			width="920px"
			text="Пользователь ограничил доступ неавторизованным пользователям"
		/>
	);

	const contentNode: React.ReactNode = (
		<div className="collection-page">
			{userInfoNode}
			{collectionInfoNode}
			{orderPanel}
			{cartsGrid}
			{noCartsNode}
			{isHiddenUserNode}
			{loading}
		</div>
	);

	const popUps: React.ReactNode[] = [
		createCartNode,
		editCollectionNode,
		deleteCollectionNode,
	];

	return (
		<PageWithSidePanel
			sidePanel={sidePanel}
			content={contentNode}
			popUps={popUps}
		/>
	);
});
