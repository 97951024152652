import './ImageInput.scss';

import React from 'react';
import { useDropArea } from 'react-use';

type ImageInputProps = {
	file: File | null;
	setFile: (file: File | null) => void;
	imageUrl: string | null;
	setImageUrl: (imageUrl: string | null) => void;
	error: string | undefined;
	imageMaxHeight?: number;
	label?: string | null;
};

export const ImageInput: React.FC<ImageInputProps> = ({
	file,
	setFile,
	imageUrl,
	setImageUrl,
	error,
	imageMaxHeight,
	label = null,
}) => {
	const [bond] = useDropArea({
		onFiles: (files) => setFile(files[0]),
	});

	const uploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files ? event.target.files[0] : null;
		setFile(file);
	};

	const checkImageExists = (url: string) =>
		new Promise<boolean>((resolve) => {
			const img = new Image();

			img.src = url;
			img.onload = () => resolve(true);
			img.onerror = () => resolve(false);
		});

	const insertImageUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
		const url = event.target.value;
		checkImageExists(url).then((isImageExists) => {
			if (isImageExists) {
				setImageUrl(event.target.value);
			}
		});
	};

	const clearImage = () => {
		setFile(null);
		setImageUrl(null);
	};

	const previewImageUrl: string | null = file
		? URL.createObjectURL(file)
		: imageUrl
			? imageUrl
			: null;
	const imageMaxHeightPx = imageMaxHeight ? imageMaxHeight : '240';
	const previewImageNode: React.ReactNode | null = previewImageUrl && (
		<div
			className="image-input__image-preview-container"
			style={{ maxHeight: `${imageMaxHeightPx}px` }}
		>
			<img
				className="image-input__image-preview"
				src={previewImageUrl}
				alt=""
			/>
			<div
				title="Удалить картину"
				className="image-input__image-clear"
				onClick={clearImage}
			>
				<div className="image-input__image-clear-sign">⨯</div>
			</div>
		</div>
	);

	const uploadImageNode: React.ReactNode = (
		<div>
			<div className="image-input__image-drop-area" {...bond}>
				<label
					className="image-input__image-input-label"
					htmlFor="image-file"
				>
					Перетащи картинку сюда
					<br></br>
					<br></br>
					<br></br>
					Или нажми и выбери файл
				</label>
				<input
					className="image-input__file-input"
					type="file"
					accept="image/*"
					name="image-file"
					alt="Card image"
					onChange={uploadImage}
				></input>
			</div>
			<div className="image-input__image-url-container">
				<input
					className="image-input__image-url-input"
					type="url"
					name="cart-image-url"
					placeholder="Или введи url картинки"
					onChange={insertImageUrl}
				/>
			</div>
		</div>
	);
	const imageUploadAreaNode: React.ReactNode = previewImageUrl
		? previewImageNode
		: uploadImageNode;

	const titleNode: React.ReactNode = label && (
		<label className="image-input__label">{label}</label>
	);

	const errorNode: React.ReactNode = error && (
		<span className="image-input__error">{error}</span>
	);

	return (
		<div className="image-input">
			{titleNode}
			{imageUploadAreaNode}
			{errorNode}
		</div>
	);
};
