import './Events.scss';

import React, { useEffect, useState } from 'react';

import { Event } from './Event';
import { EventEntity } from '../../entities/event.entity';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../stores/RootStore';

export const Events: React.FC = observer(() => {
	const { eventsStore } = useRootStore();

	const [activeEvents, setActiveEvents] = useState<EventEntity[]>([]);

	useEffect(() => {
		const event = eventsStore.shiftEvent();
		event && setActiveEvents([...activeEvents, event]);
	}, [eventsStore.events.length]);

	const renderEvent = (
		event: EventEntity,
		index: number,
	): React.ReactNode => {
		return <Event event={event} key={`event__${index}`} />;
	};

	const eventsNodes: React.ReactNode[] = activeEvents
		.reverse()
		.map(renderEvent);

	return <div className="events">{eventsNodes}</div>;
});
