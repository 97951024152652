import './ProfileSettingsPage.scss';

import { Errors } from '../../../types/api-error.type';
import { ProfileSettings } from '../../../components/Settings/ProfileSettings/ProfileSettings';
import { editProfile } from '../../../services/account/edit-profile.service';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../stores/RootStore';
import { useState } from 'react';

export const ProfileSettingsPage = observer(() => {
	const { profileStore } = useRootStore();
	const profileInfo = profileStore.profileInfo;

	const [errors, setErrors] = useState<Errors>({});
	const [isEditingLoading, setIsEditingLoading] = useState<boolean>(false);

	const profileSettingsInitialData = {
		avatarUrl: profileInfo?.avatar || null,
		username: profileInfo?.username || '',
		pageUrl: profileInfo?.pageUrl || '',
		isHiddenFromUnauthorizedUsers:
			profileInfo?.isHiddenFromUnauthorizedUsers || false,
	};

	const submitEditing = async (
		avatar: File | null,
		avatarUrl: string | null,
		pageUrl: string,
		isHiddenFromUnauthorizedUsers: boolean,
	) => {
		if (isEditingLoading) {
			return;
		}

		setErrors({});
		setIsEditingLoading(true);

		const serviceResponse = await editProfile(
			avatar,
			avatarUrl,
			pageUrl,
			isHiddenFromUnauthorizedUsers,
		);
		if (serviceResponse.status === 'OK') {
			profileStore.setProfileInfo(serviceResponse.data);
			window.location.reload();
		} else {
			setErrors(serviceResponse.data);
		}

		setIsEditingLoading(false);
	};

	return (
		<div className="settings-page">
			<h1 className="settings-page__title">Редактирование профиля</h1>
			<ProfileSettings
				initialData={profileSettingsInitialData}
				isEditingLoading={isEditingLoading}
				submitEditing={submitEditing}
				errors={errors}
				setErrors={setErrors}
			/>
		</div>
	);
});
