import { makeAutoObservable } from 'mobx';

export class SearchStore {
	searchQuery: string | null = null;

	constructor() {
		makeAutoObservable(this);
	}

	setSearchQuery = (searchQuery: string | null): void => {
		this.searchQuery = searchQuery;
	};
}
