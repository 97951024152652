export const EVENT_TYPE_ERROR = 'error';
export const EVENT_TYPE_SUCCESS = 'success';

export type EVENT_TYPES = 'error' | 'success';

export const EVENT_DEFAULT_MESSAGE_TIMEOUT = 10;

export const EVENT_MESSAGE_CART_CREATED = (
	strings: TemplateStringsArray,
	cartTitle: string,
	collectionTitle: string,
): string =>
	`Карточка "${cartTitle}" добавлена в коллекцию "${collectionTitle}"`;

export const EVENT_MESSAGE_CART_EDITED = (
	strings: TemplateStringsArray,
	cartTitle: string,
): string => `Карточка "${cartTitle}" обновлена`;

export const EVENT_MESSAGE_PENDING_CART_CREATED = (
	strings: TemplateStringsArray,
	cartTitle: string,
): string => `Карточка "${cartTitle}" добавлена в отложенное`;

export const EVENT_MESSAGE_CART_DELETED = (
	strings: TemplateStringsArray,
	cartTitle: string,
): string => `Карточка "${cartTitle}" удалена`;
