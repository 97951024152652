import './FullCart.scss';

import React, { useState } from 'react';

import { TextInput } from '../../FormInputs/TextInput/TextInput';

type FullCartTitleProps = {
	isCartChanging: boolean;
	setIsCartChanging: (isChanging: boolean) => void;

	title: string;
	setTitleCallback: (title: string) => void;

	isAllowChanging: boolean;
	isAllowChangingClass: string;
	error: string | undefined;
};

export const FullCartTitle: React.FC<FullCartTitleProps> = ({
	isCartChanging,
	setIsCartChanging,
	title,
	setTitleCallback,
	isAllowChanging,
	isAllowChangingClass,
	error,
}) => {
	const [isChanging, setIsChanging] = useState<boolean>(false);

	const inputNode: React.ReactNode = (
		<div className="full-cart__title-input">
			<TextInput
				label="Название"
				value={title}
				setValueCallback={setTitleCallback}
				error={error}
				autoFocus={true}
			/>
		</div>
	);

	const displayNode: React.ReactNode = (
		<h1
			className={`full-cart__title-display ${isAllowChangingClass}`}
			onClick={() => {
				setIsChanging(isAllowChanging);
				setIsCartChanging(isAllowChanging);
			}}
		>
			{title}
		</h1>
	);

	const finalNode: React.ReactNode =
		isChanging && isCartChanging ? inputNode : displayNode;

	return <div className="full-cart__title">{finalNode}</div>;
};
