import { CursorPaginationStore } from './CursorPaginationStore';
import { FilterStore } from './FilterStore';
import { OrderingStore } from './OrderingStore';
import { PENDING_CARTS_ORDER_BY_CHOICES } from '../constants/order.constants';
import { SearchStore } from './SearchStore';
import { makeAutoObservable } from 'mobx';

export class PendingCartsPageStore {
	cursorPaginationStore: CursorPaginationStore = new CursorPaginationStore();
	orderingStore: OrderingStore = new OrderingStore(
		PENDING_CARTS_ORDER_BY_CHOICES.DATE_CREATED,
	);
	searchStore: SearchStore = new SearchStore();

	filterCategoryStore: FilterStore = new FilterStore();

	constructor() {
		makeAutoObservable(this);
	}
}
