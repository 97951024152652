import './Dropdown.scss';

import { DropdownState, DropdownStateContext } from './DropdownContext';
import React, { useEffect, useRef, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { useMenuTrigger } from '../../utils/useClickAwayWithChildren';

type DropdownProps = {
	toggleNode: React.ReactNode;
	listNode: React.ReactNode;
	setToggledCallback?: (toogled: boolean) => void;
};

export const Dropdown: React.FC<DropdownProps> = ({
	toggleNode,
	listNode,
	setToggledCallback,
}) => {
	const [toggled, setToggled] = useState<boolean>(false);

	const refDropdown = useRef<HTMLDivElement>(null);
	const refToggleButton = useRef<HTMLDivElement>(null);

	useEffect(() => {
		setToggledCallback && setToggledCallback(toggled);
	}, [toggled]);

	useMenuTrigger(refToggleButton, refDropdown, toggled, setToggled);

	const location = useLocation();

	useEffect(() => {
		setToggled(false);
	}, [location]);

	const dropdownState: DropdownState = { toggled };

	return (
		<DropdownStateContext.Provider value={dropdownState}>
			<div className="dropdown-container">
				<div ref={refToggleButton}>{toggleNode}</div>
				<div ref={refDropdown}>{listNode}</div>
			</div>
		</DropdownStateContext.Provider>
	);
};
