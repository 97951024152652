import './SecondaryButton.scss';

import React from 'react';

type SecondaryButtonProps = {
	title: string;
	onClick?: () => void;
};

export const SecondaryButton: React.FC<SecondaryButtonProps> = ({
	title,
	onClick,
}) => {
	return (
		<span className="secondary-button" onClick={onClick}>
			{title}
		</span>
	);
};
