import { RepositoryResponse, request } from './request.repository';

import { FilterItem } from '../entities/filter-item.entity';
import { URL_API_COLLECTION_FILTERS } from '../constants/urls.constants';

type Filters = {
	categories: FilterItem[];
};

export interface ServiceResponse {
	status: 'OK' | 'ERROR';
	filters?: Filters;
}

async function fetchUserPageFiltersItems(
	user_id: number,
): Promise<ServiceResponse> {
	const data = {
		user_id: user_id.toString(),
	};
	const params = new URLSearchParams(data);
	const url = `${URL_API_COLLECTION_FILTERS}?${params}`;

	const method = 'GET';
	const options = {
		method,
	};

	const response: RepositoryResponse = await request(url, options);

	if (response.status === 'OK' ?? typeof response.data === 'object') {
		const filters: Filters = response.data.content.data;
		return {
			status: 'OK',
			filters,
		};
	}

	return {
		status: 'ERROR',
	};
}

export { fetchUserPageFiltersItems };
