import './CollectionInfo.scss';

import React from 'react';

type CollectionInstrumentProps = {
	title: string;
	onClick: () => void;
};

export const CollectionInstrument: React.FC<CollectionInstrumentProps> = ({
	title,
	onClick,
}) => {
	return (
		<p className="collection-info__instrument" onClick={onClick}>
			{title}
		</p>
	);
};
