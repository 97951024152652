import {
	PendingCartForm,
	submitCallbackType,
} from '../../../components/CartForm/PendingCartForm';
import React, { useState } from 'react';

import { EditPendingCartService } from '../../../services/pending/edit-pending-cart.service';
import { Errors } from '../../../types/api-error.type';
import { PendingCartEntity } from '../../../entities/pending-cart.entity';

type EditPendingCartProps = {
	initialCart: PendingCartEntity;
	editedCallback: (pendingCart: PendingCartEntity) => void;
};

export const EditPendingCart: React.FC<EditPendingCartProps> = ({
	initialCart,
	editedCallback,
}) => {
	const [errors, setErrors] = useState<Partial<Errors>>({});
	const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

	const submitCreatingPendingCart: submitCallbackType = async (
		category,
		title,
		picture,
		pictureUrl,
		notes,
	) => {
		if (isSubmitLoading) {
			return;
		}

		setErrors({});
		if (!title) {
			setErrors({ title: ['Нужно указать название'] });
			return;
		}
		if (category === null) {
			setErrors({ category_name: ['Нужно выбрать категорию'] });
			return;
		}

		setIsSubmitLoading(true);
		const serviceResponse = await EditPendingCartService(
			initialCart.id,
			category.id,
			title,
			picture,
			pictureUrl,
			notes,
		);
		if (serviceResponse.status === 'OK') {
			editedCallback(serviceResponse.data);
		} else {
			setErrors(serviceResponse.errors);
		}

		setIsSubmitLoading(false);
	};

	return (
		<PendingCartForm
			submitTitle="Сохранить"
			initialCart={initialCart}
			isSubmitLoading={isSubmitLoading}
			errors={errors}
			setErrors={setErrors}
			submitCallback={submitCreatingPendingCart}
		/>
	);
};
