import {
	CARTS_ORDER_BY_CHOICES,
	ORDERING_CHOICES,
} from '../constants/order.constants';

import { CursorPaginationStore } from './CursorPaginationStore';
import { FilterStore } from './FilterStore';
import { OrderingStore } from './OrderingStore';
import { SearchStore } from './SearchStore';
import { makeAutoObservable } from 'mobx';
import { parseStringIds } from '../utils/parseStringIds';

enum searchParamsKeys {
	SEARCH = 'search',
	ORDER_BY = 'order_by',
	ORDERING = 'ordering',
	RATES = 'rate',
	TAGS = 'tag_id',
}

export class CollectionPageStore {
	cursorPaginationStore: CursorPaginationStore = new CursorPaginationStore();
	orderingStore: OrderingStore = new OrderingStore(
		CARTS_ORDER_BY_CHOICES.DATE_CREATED,
	);
	searchStore: SearchStore = new SearchStore();

	filterRateStore: FilterStore = new FilterStore();
	filterTagStore: FilterStore = new FilterStore();

	constructor() {
		makeAutoObservable(this);
	}

	setFilters = (filterRateIds: number[], filterTagIds: number[]): void => {
		this.filterRateStore.picked = filterRateIds;
		this.filterTagStore.picked = filterTagIds;
	};

	get isFiltered(): boolean {
		if (
			this.searchStore.searchQuery ||
			this.filterRateStore.picked.length > 0 ||
			this.filterTagStore.picked.length > 0
		) {
			return true;
		}

		return false;
	}

	setStateFromSearchParams = (searchParams: URLSearchParams): void => {
		const searchQuery = searchParams.get(searchParamsKeys.SEARCH);
		const orderByValue = searchParams.get(searchParamsKeys.ORDER_BY);
		const orderingValue = searchParams.get(searchParamsKeys.ORDERING);
		const rates = searchParams.getAll(searchParamsKeys.RATES);
		const tags = searchParams.getAll(searchParamsKeys.TAGS);

		const orderBy =
			orderByValue === CARTS_ORDER_BY_CHOICES.RATE
				? CARTS_ORDER_BY_CHOICES.RATE
				: CARTS_ORDER_BY_CHOICES.DATE_CREATED;

		const ordering =
			orderingValue === ORDERING_CHOICES.ASC
				? ORDERING_CHOICES.ASC
				: ORDERING_CHOICES.DSC;

		const actualRatesIds = this.filterRateStore.itemsPks;
		const rateIds = parseStringIds(rates, actualRatesIds);

		const actualTagsIds = this.filterTagStore.itemsPks;
		const tagIds = parseStringIds(tags, actualTagsIds);

		this.filterRateStore.picked = rateIds;
		this.filterTagStore.picked = tagIds;
		this.searchStore.setSearchQuery(searchQuery);
		this.orderingStore.setOrder(orderBy, ordering);
	};

	createSearchParamsFromState = (): URLSearchParams => {
		const searchParams = new URLSearchParams();

		const { searchQuery } = this.searchStore;
		if (searchQuery) {
			searchParams.set(searchParamsKeys.SEARCH, searchQuery);
		}

		const { orderBy, ordering } = this.orderingStore;
		if (!this.orderingStore.isDefaultOrdering) {
			searchParams.set(searchParamsKeys.ORDER_BY, orderBy);
			searchParams.set(searchParamsKeys.ORDERING, ordering);
		}

		const tagsIds = this.filterTagStore.picked;
		if (tagsIds.length > 0) {
			searchParams.delete(searchParamsKeys.TAGS);
			tagsIds.forEach((tagId) => {
				searchParams.append(searchParamsKeys.TAGS, tagId.toString());
			});
		}

		const ratesIds = this.filterRateStore.picked;
		if (ratesIds.length > 0) {
			searchParams.delete(searchParamsKeys.RATES);
			ratesIds.forEach((rateId) => {
				searchParams.append(searchParamsKeys.RATES, rateId.toString());
			});
		}

		return searchParams;
	};
}
