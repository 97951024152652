import { RepositoryResponse, request } from '../request.repository';

import { ReactedEmojiEntity } from '../../entities/emoji.entity';
import { URL_API_EMOJI_PUSH } from '../../constants/urls.constants';

export interface ErrorDataResponse {
	[key: string]: Array<string>;
}

export interface ServiceResponseSuccess {
	status: 'OK';
	data: ReactedEmojiEntity[];
}

export interface ServiceResponseError {
	status: 'ERROR';
	data: Partial<ErrorDataResponse>;
}

export async function pushEmoji(
	cartId: number,
	name: string,
	add: boolean,
): Promise<ServiceResponseSuccess | ServiceResponseError> {
	const data = {
		cart_id: cartId,
		name,
		add,
	};
	const body = JSON.stringify(data);
	const method = 'POST';
	const options = {
		body,
		method,
	};

	const response: RepositoryResponse = await request(
		URL_API_EMOJI_PUSH,
		options,
	);

	if (response.status === 'OK') {
		const data: ReactedEmojiEntity[] = response.data.content.data;
		return {
			status: 'OK',
			data,
		};
	}

	return {
		status: 'ERROR',
		data: {},
	};
}
