import { RepositoryResponse, request } from '../request.repository';

import { CollectionEntity } from '../../entities/collection.entity';
import { URL_API_COLLECTION_CHOICES } from '../../constants/urls.constants';

export interface ServiceResponseSuccess {
	status: 'OK';
	data: CollectionEntity[];
}

export interface ServiceResponseError {
	status: 'ERROR';
}

async function fetchCollectionChoices(): Promise<
	ServiceResponseSuccess | ServiceResponseError
> {
	const url = URL_API_COLLECTION_CHOICES;

	const method = 'GET';
	const options = { method };

	const response: RepositoryResponse = await request(url, options);

	if (response.status === 'OK') {
		const data: CollectionEntity[] = response.data.content.data;
		return {
			status: 'OK',
			data,
		};
	}

	return { status: 'ERROR' };
}

export { fetchCollectionChoices };
