import './ConfirmBlock.scss';

import { BubbleButton } from '../Buttons/BubbleButton/BubbleButton';
import React from 'react';

type ConfirmBlockProps = {
	title: string;
	text?: string;
	submitButtonName: string;
	confirmCallback: () => void;
	isLoading?: boolean;
};

export const ConfirmBlock: React.FC<ConfirmBlockProps> = ({
	title,
	text,
	submitButtonName,
	confirmCallback,
	isLoading,
}) => {
	const textNode: React.ReactNode = text && (
		<p className="confirm-block__text">{text}</p>
	);

	return (
		<div className="confirm-block">
			<div className="confirm-block__content">
				<h3 className="confirm-block__title">{title}</h3>
				{textNode}
				<BubbleButton
					title={submitButtonName}
					disabled={isLoading || false}
					isLoading={isLoading || false}
					onClick={confirmCallback}
				/>
			</div>
		</div>
	);
};
