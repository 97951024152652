import './AddUtilCart.scss';

import React from 'react';

type AddUtilCartProps = {
	actionCallback: () => void;
};

export const AddUtilCart: React.FC<AddUtilCartProps> = ({ actionCallback }) => {
	return (
		<div className="util-cart">
			<div className="util-cart__button" onClick={actionCallback}>
				<h1 className="util-cart__button__sign">+</h1>
			</div>
		</div>
	);
};
