import './ReactedUsersTooltip.scss';

import { Avatar } from '../../Users/Avatar/Avatar';
import { EmojiReactedUser } from '../../../entities/emoji.entity';
import { Link } from 'react-router-dom';
import React from 'react';
import { URL_USER_PAGE } from '../../../constants/urls.constants';

type ReactedUsersTooltipProps = {
	lastReactedUsers: EmojiReactedUser[];
	reactedUsersTotalCount: number;
};

export const ReactedUsersTooltip: React.FC<ReactedUsersTooltipProps> = ({
	lastReactedUsers,
	reactedUsersTotalCount,
}) => {
	const renderReactedUser = (
		userEntity: EmojiReactedUser,
	): React.ReactNode => {
		return (
			<div
				className="reacted-users-tooltip__reacted-item"
				key={userEntity.user_id}
			>
				<Link to={URL_USER_PAGE`${userEntity.page_url}`}>
					<Avatar
						avatarUrl={userEntity.avatar}
						username={userEntity.username}
						size="default"
					/>
				</Link>
			</div>
		);
	};
	const reactedUsersNodes = lastReactedUsers.map(renderReactedUser);

	const limitedUsersReactedCount =
		reactedUsersTotalCount - lastReactedUsers.length;
	const isUsersReactedLimited = limitedUsersReactedCount > 0;
	const showAllUsersReactedNode: React.ReactNode = isUsersReactedLimited && (
		<div className="reacted-users-tooltip__reacted-item">
			<p className="reacted-users-tooltip__show-all-users-reacted">
				+{limitedUsersReactedCount}
			</p>
		</div>
	);

	return (
		<div>
			<div className="reacted-users-tooltip">
				{reactedUsersNodes}
				{showAllUsersReactedNode}
			</div>
		</div>
	);
};
