import {
	CollectionControlForm,
	CollectionControlInitialData,
} from './CollectionControlForm/CollectionControlForm';
import React, { useState } from 'react';

import { CategoryEntity } from '../../../entities/category.entity';
import { Errors } from '../../../types/api-error.type';
import { createCollection } from '../../../services/collections/create-collection.service';

export const CreateCollection: React.FC = () => {
	const [errors, setErrors] = useState<Errors>({});
	const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

	const formInitialData: CollectionControlInitialData = {
		title: '',
		category: null,
	};

	const submitButtonName = 'Создать';

	const submitCreatingCart = async (
		title: string,
		category: CategoryEntity | null,
	) => {
		if (isSubmitLoading) {
			return;
		}

		setErrors({});
		if (!title) {
			setErrors({ title: ['Нужно указать название'] });
			return;
		}
		if (!category) {
			setErrors({ category_name: ['Нужно выбрать тип коллекции'] });
			return;
		}

		setIsSubmitLoading(true);

		const createCollectionResponse = await createCollection(
			category.id,
			title,
		);
		if (createCollectionResponse.status === 'OK') {
			window.location.reload();
		} else {
			setErrors(createCollectionResponse.errors);
		}

		setIsSubmitLoading(false);
	};

	return (
		<CollectionControlForm
			initialData={formInitialData}
			submitButtonName={submitButtonName}
			isSubmitLoading={isSubmitLoading}
			errors={errors}
			setErrors={setErrors}
			submitCallback={submitCreatingCart}
		/>
	);
};
