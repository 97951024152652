import {
	CreateCartForm,
	createCartFormData,
} from '../../components/CartForm/CreateCartForm';
import React, { useEffect, useState } from 'react';

import { CartEntity } from '../../entities/cart.entity';
import { CategoryEntity } from '../../entities/category.entity';
import { CollectionEntity } from '../../entities/collection.entity';
import { CreateCartService } from '../../services/carts/create-cart.service';
import { Errors } from '../../types/api-error.type';
import { PendingCartEntity } from '../../entities/pending-cart.entity';
import { Tag } from '../../entities/tag.entity';
import { fetchCollectionChoices } from '../../services/collections/fetch-collection-choices.service';
import { fetchCollectionTags } from '../../services/collections/fetch-collection-tags.service';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../stores/RootStore';

type CreateCartProps = {
	createdCallback: (cart: CartEntity) => void;
	initialCollection?: CollectionEntity;
	initialCart?: CartEntity;
	initialPendingCart?: PendingCartEntity;
	isRepost?: boolean;
};

export const CreateCart: React.FC<CreateCartProps> = observer(
	({
		createdCallback,
		initialCollection,
		initialCart,
		initialPendingCart,
		isRepost,
	}) => {
		const { eventsStore, createCartStateForm } = useRootStore();

		const [errors, setErrors] = useState<Partial<Errors>>({});
		const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
		const [pickedCollection, setPickedCollection] =
			useState<CollectionEntity | null>(
				initialCollection ? initialCollection : null,
			);
		const [collectionChoices, setCollectionChoices] = useState<
			CollectionEntity[]
		>([]);
		const [collectionTags, setCollectionTags] = useState<Tag[]>([]);

		const updateCollectionChoices = async () => {
			setCollectionChoices([]);
			const serviceResponse = await fetchCollectionChoices();
			if (serviceResponse.status === 'OK') {
				const collections = serviceResponse.data;
				setCollectionChoices(collections);
				if (!pickedCollection && collections.length > 0) {
					let initialCategory: CategoryEntity | null = null;
					if (initialCart) {
						initialCategory = initialCart.collection.category;
					} else if (initialPendingCart) {
						initialCategory = initialPendingCart.category;
					}
					if (initialCategory !== null) {
						const sameCategoryCollections = collections.filter(
							(collection) => {
								return (
									collection.category.id ===
									initialCategory?.id
								);
							},
						);
						if (sameCategoryCollections.length > 0) {
							setPickedCollection(sameCategoryCollections[0]);
							return;
						}
					}
					setPickedCollection(collections[0]);
				}
			} else {
				eventsStore.pushUnknownErrorEvent();
			}
		};

		const fetchInitialDataEffect = () => {
			updateCollectionChoices();
		};

		useEffect(fetchInitialDataEffect, []);

		const updateCollectionTags = async (collectionId: number) => {
			setCollectionTags([]);
			const serviceResponse = await fetchCollectionTags(
				collectionId.toString(),
			);
			if (serviceResponse.status === 'OK') {
				setCollectionTags(serviceResponse.data);
			} else {
				eventsStore.pushUnknownErrorEvent();
			}
		};

		const fetchCollectionDataEffect = () => {
			if (!pickedCollection) {
				return;
			}

			updateCollectionTags(pickedCollection.id);
		};
		const pickedCollectionState = pickedCollection && pickedCollection.id;
		useEffect(fetchCollectionDataEffect, [pickedCollectionState]);

		const submitCreatingCart = async (
			createCartFormData: createCartFormData,
		) => {
			if (isSubmitLoading) {
				return;
			}

			setErrors({});
			if (!createCartFormData.title) {
				setErrors({ title: ['Нужно указать название'] });
				return;
			}
			if (!createCartFormData.rate) {
				setErrors({ rate: ['Нужно выбрать степень годноты'] });
				return;
			}

			setIsSubmitLoading(true);
			const serviceResponse = await CreateCartService(
				createCartFormData.collectionId,
				createCartFormData.title,
				createCartFormData.rate.id,
				createCartFormData.file,
				createCartFormData.imageUrl,
				createCartFormData.notes,
				createCartFormData.selectedTags,
				isRepost && initialCart ? initialCart.id : null,
				initialPendingCart ? initialPendingCart.id : null,
			);
			if (serviceResponse.status === 'OK') {
				createCartStateForm.resetState();
				createdCallback(serviceResponse.data);
			} else {
				setErrors(serviceResponse.data);
			}

			setIsSubmitLoading(false);
		};

		const getInitialCartData = (): createCartFormData | undefined => {
			if (initialCart)
				return {
					collectionId: initialCart.collection.id,
					title: initialCart.title,
					rate: initialCart.rate,
					file: null,
					imageUrl: initialCart.pictureUrl,
					notes: [],
					selectedTags: initialCart.tags,
				};

			const createCartFormState = createCartStateForm.loadState();
			if (createCartFormState === undefined) return;
			if (!pickedCollection) return;
			if (pickedCollection.id !== createCartFormState.collectionId)
				return;

			return createCartFormState;
		};

		return (
			pickedCollection && (
				<CreateCartForm
					collection={pickedCollection}
					collectionChoices={collectionChoices}
					collectionTags={collectionTags}
					initialCart={getInitialCartData()}
					initialPendingCart={initialPendingCart}
					isSubmitLoading={isSubmitLoading}
					errors={errors}
					pickCollectionCallback={setPickedCollection}
					setErrors={setErrors}
					submitCallback={submitCreatingCart}
					onFormChangeCallback={createCartStateForm.saveState}
				/>
			)
		);
	},
);
