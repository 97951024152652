import { RepositoryResponse, request } from '../request.repository';

import { CartEntity } from './../../entities/cart.entity';
import { CartNoteEntity } from '../../entities/cart-note.entity';
import { Tag } from '../../entities/tag.entity';
import { URL_API_CART_CREATE } from '../../constants/urls.constants';

export interface ErrorDataResponse {
	[key: string]: Array<string>;
}

export interface ServiceResponseSuccess {
	status: 'OK';
	data: CartEntity;
}

export interface ServiceResponseError {
	status: 'ERROR';
	data: Partial<ErrorDataResponse>;
}

export async function CreateCartService(
	collectionId: number,
	title: string,
	rate: string,
	picture: File | null,
	pictureUrl: string | null,
	notes: CartNoteEntity[],
	tags: Tag[],
	repostCartId: number | null,
	pendingCartId: number | null,
): Promise<ServiceResponseSuccess | ServiceResponseError> {
	const formData = new FormData();
	formData.append('collection_id', collectionId.toString());
	formData.append('title', title);
	formData.append('rate', rate);

	if (repostCartId) {
		formData.append('repost_cart_id', repostCartId.toString());
	}

	if (pendingCartId) {
		formData.append('pending_cart_id', pendingCartId.toString());
	}

	if (repostCartId) {
		formData.append('repost_cart_id', repostCartId.toString());
	}

	if (picture) {
		formData.append('picture', picture);
	} else if (pictureUrl) {
		formData.append('picture_url', pictureUrl);
	}

	const tagsNames: string[] = tags.map((tag) => tag.name);
	formData.append('tags', JSON.stringify(tagsNames));

	const notesData = notes.map((note, index) => {
		return { id: index, text: note.text, is_spoiler: note.isSpoiler };
	});
	formData.append('notes', JSON.stringify(notesData));

	const body = formData;
	const method = 'POST';
	const options = {
		body,
		method,
	};
	const noBaseOptions = true;

	const response: RepositoryResponse = await request(
		URL_API_CART_CREATE,
		options,
		noBaseOptions,
	);

	if (response.status === 'OK') {
		return {
			status: 'OK',
			data: response.data.content.data,
		};
	}

	return {
		status: 'ERROR',
		data: response.data,
	};
}
