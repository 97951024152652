import './ProfileDropdown.scss';

import {
	URL_SETTINGS_PATH,
	URL_SETTINGS_PROFILE_SUBPATH,
	URL_USER_PAGE,
} from '../../../constants/urls.constants';

import { Dropdown } from '../../../components/Dropdown/Dropdown';
import { DropdownItem } from '../../../components/Dropdown/DropdownItem';
import { DropdownList } from '../../../components/Dropdown/DropdownList';
import { DropdownToggle } from '../../../components/Dropdown/DropdownToggle';
import { Link } from 'react-router-dom';
import { ProfileDropdownHeader } from './ProfileDropdownHeader';
import { ProfileEntity } from '../../../entities/profile.entity';
import React from 'react';

type ProfileDropdownProps = {
	profile: ProfileEntity;
	logoutCallback: () => void;
};

export const ProfileDropdown: React.FC<ProfileDropdownProps> = ({
	profile,
	logoutCallback,
}) => {
	const { pageUrl } = profile;

	const dropdownToggleNode: React.ReactNode = (
		<DropdownToggle>
			<ProfileDropdownHeader profile={profile} />
		</DropdownToggle>
	);

	const dropdownListNode: React.ReactNode = (
		<DropdownList>
			<DropdownItem isOnlyMobile={true}>
				<Link
					to={URL_USER_PAGE`${pageUrl}`}
					className="profile-dropdown__item"
				>
					Моя страница
				</Link>
			</DropdownItem>
			<DropdownItem>
				<Link
					to={`${URL_SETTINGS_PATH}/${URL_SETTINGS_PROFILE_SUBPATH}`}
					className="profile-dropdown__item"
				>
					Настройки
				</Link>
			</DropdownItem>
			<DropdownItem>
				<div
					className="profile-dropdown__item"
					onClick={logoutCallback}
				>
					Выйти
				</div>
			</DropdownItem>
		</DropdownList>
	);

	return (
		<Dropdown toggleNode={dropdownToggleNode} listNode={dropdownListNode} />
	);
};
