import { RepositoryResponse, request } from '../request.repository';

import { ProfileEntity } from '../../entities/profile.entity';
import { URL_API_AUTH_REGISTER } from '../../constants/urls.constants';

export interface ErrorDataResponse {
	[key: string]: Array<string>;
}

export interface ServiceResponseSuccess {
	status: 'OK';
	data: ProfileEntity;
}

export interface ServiceResponseError {
	status: 'ERROR';
	data: Partial<ErrorDataResponse>;
}

export async function RegisterService(
	username: string,
	password: string,
	invitationCode: string | null,
): Promise<ServiceResponseSuccess | ServiceResponseError> {
	const url = URL_API_AUTH_REGISTER;

	const data = {
		username,
		password,
		invitation_code: invitationCode,
	};
	const body = JSON.stringify(data);
	const method = 'POST';
	const options = { body, method };

	const response: RepositoryResponse = await request(url, options);

	if (response.status === 'OK') {
		const profileInfo: ProfileEntity = response.data.content.data;
		return {
			status: 'OK',
			data: profileInfo,
		};
	}

	return {
		status: 'ERROR',
		data: response.data,
	};
}
