import React from 'react';

type DropdownItemProps = {
	actionCallback?: () => void;
	isOnlyMobile?: boolean;
};

export const DropdownItem: React.FC<
	React.PropsWithChildren<DropdownItemProps>
> = ({ actionCallback, isOnlyMobile = false, children }) => {
	const mobileClassName = isOnlyMobile ? 'dropdown-item--only-mobile' : '';
	return (
		<div
			className={`dropdown-item ${mobileClassName}`}
			onClick={actionCallback}
		>
			{children}
		</div>
	);
};
