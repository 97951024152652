import './CartForm.scss';

import {
	Option,
	SingleChoiceInput,
} from '../FormInputs/SingleChoiceInput/SingleChoiceInput';
import {
	RATES,
	RATES_CHOICES,
	RATE_IMAGE_HEIGHT_MAP,
} from '../../constants/carts.constants';
import React, { useEffect, useRef, useState } from 'react';

import { BubbleButton } from '../Buttons/BubbleButton/BubbleButton';
import { CartNoteEntity } from '../../entities/cart-note.entity';
import { CartTagsInput } from '../FormInputs/TagsInput/CartTagsInput';
import { CollectionEntity } from '../../entities/collection.entity';
import { Errors } from '../../types/api-error.type';
import { ImageInput } from '../FormInputs/ImageInput/ImageInput';
import { NotesInput } from '../FormInputs/NotesInput/NotesInput';
import { PendingCartEntity } from '../../entities/pending-cart.entity';
import { RateEntity } from '../../entities/rate.entity';
import { SecondaryButton } from '../Buttons/SecondaryButton/SecondaryButton';
import { Tag } from '../../entities/tag.entity';
import { TextInput } from '../FormInputs/TextInput/TextInput';
import { useClickAway } from 'react-use';

export type createCartFormData = {
	collectionId: number;
	title: string;
	rate: RateEntity | null;
	file: File | null;
	imageUrl: string | null;
	notes: CartNoteEntity[];
	selectedTags: Tag[];
};

type CreateCartFormProps = {
	collection: CollectionEntity;
	collectionChoices: CollectionEntity[];
	collectionTags: Tag[];
	initialCart?: createCartFormData;
	initialPendingCart?: PendingCartEntity;
	isSubmitLoading: boolean;
	errors: Errors;
	pickCollectionCallback: (collection: CollectionEntity) => void;
	setErrors: (errors: Errors) => void;
	submitCallback: (createCartFormData: createCartFormData) => void;
	onFormChangeCallback?: (createCartFormData: createCartFormData) => void;
};

export const CreateCartForm: React.FC<CreateCartFormProps> = ({
	collection,
	collectionChoices,
	collectionTags,
	initialCart,
	initialPendingCart,
	isSubmitLoading,
	errors,
	pickCollectionCallback,
	setErrors,
	submitCallback,
	onFormChangeCallback,
}) => {
	const [file, setFile] = useState<File | null>(null);
	const [imageUrl, setImageUrl] = useState<string | null>(
		initialCart
			? initialCart.imageUrl
			: initialPendingCart
				? initialPendingCart.pictureUrl
				: null,
	);
	const [title, setTitle] = useState<string>(
		initialCart
			? initialCart.title
			: initialPendingCart
				? initialPendingCart.title
				: '',
	);
	const [rate, setRate] = useState<RateEntity | null>(
		initialCart ? initialCart.rate : null,
	);
	const [notes, setNotes] = useState<CartNoteEntity[]>(
		initialCart
			? initialCart.notes
			: initialPendingCart
				? initialPendingCart.notes
				: [],
	);
	const [inputTag, setInputTag] = useState<string>('');
	const [selectedTags, setSelectedTags] = useState<Tag[]>(
		initialCart ? initialCart.selectedTags : [],
	);

	const [isCollectionChanging, setIsCollectionChanging] =
		useState<boolean>(false);

	useEffect(() => {
		if (onFormChangeCallback === undefined) return;

		onFormChangeCallback({
			collectionId: collection.id,
			title,
			rate,
			file: null,
			imageUrl,
			notes,
			selectedTags,
		});
	}, [collection.id, title, rate, imageUrl, notes, selectedTags]);

	const clearForm = () => {
		setFile(null);
		setImageUrl(null);
		setTitle('');
		setRate(null);
		setInputTag('');
		setSelectedTags([]);
		setErrors({});
	};

	const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		submitCallback({
			collectionId: collection.id,
			title,
			rate,
			file,
			imageUrl,
			notes,
			selectedTags,
		});
	};

	const imageError = errors['picture_url'] || errors['picture'];
	const cartImageMaxHeight =
		RATE_IMAGE_HEIGHT_MAP[
			rate ? (rate.id as unknown as RATES_CHOICES) : '3'
		];
	const inputImageNode: React.ReactNode = (
		<div>
			<ImageInput
				file={file}
				setFile={setFile}
				imageUrl={imageUrl}
				setImageUrl={setImageUrl}
				error={imageError && imageError[0]}
				imageMaxHeight={cartImageMaxHeight}
			/>
		</div>
	);

	const refCollection = useRef<HTMLDivElement>(null);
	useClickAway(refCollection, () => setIsCollectionChanging(false));

	const collectionBlockTitle = 'Коллекция';
	const collectionError = errors['collection_id'];
	const collectionOptions: Option[] = collectionChoices;
	const collectionInputNode: React.ReactNode = (
		<div className="cart-control__collection" ref={refCollection}>
			<SingleChoiceInput
				label={collectionBlockTitle}
				options={collectionOptions}
				error={collectionError && collectionError[0]}
				selectedOption={collection}
				setSelectedOptionCallback={(option) => {
					pickCollectionCallback(option as CollectionEntity);
				}}
			/>
		</div>
	);
	const collectionDisplayNode: React.ReactNode = (
		<div className="cart-control__collection">
			<span
				className="cart-control__label"
				onClick={() => setIsCollectionChanging(true)}
			>
				{collectionBlockTitle}
			</span>
			<p
				className="cart-control__collection-name"
				onClick={() => setIsCollectionChanging(true)}
			>
				{collection.title}
			</p>
		</div>
	);
	const collectionNode: React.ReactNode = isCollectionChanging
		? collectionInputNode
		: collectionDisplayNode;

	const titleError = errors['title'];
	const inputTitleNode: React.ReactNode = (
		<div className="cart-control__input-block">
			<TextInput
				label="Название"
				value={title}
				setValueCallback={setTitle}
				error={titleError && titleError[0]}
			/>
		</div>
	);

	const rateError = errors['rate'];
	const rateOptions = RATES;
	const inputRateNode: React.ReactNode = (
		<div className="cart-control__input-block">
			<SingleChoiceInput
				label="Степень годноты"
				error={rateError && rateError[0]}
				options={rateOptions}
				selectedOption={rate}
				setSelectedOptionCallback={(option) => {
					if (option !== null && typeof option.id === 'string') {
						setRate({
							id: option.id,
							title: option.title,
						});
					}
				}}
			/>
		</div>
	);

	const notesError = errors['notes'];
	const inputNotesNode: React.ReactNode = (
		<div className="cart-control__input-block">
			<NotesInput
				notes={notes}
				setNotesCallback={setNotes}
				error={notesError && notesError[0]}
			/>
		</div>
	);

	const tagsError = errors['tags'];
	const inputTagsNode: React.ReactNode = (
		<div className="cart-control__input-block">
			<CartTagsInput
				inputTag={inputTag}
				setInputTag={setInputTag}
				tags={collectionTags}
				selectedTags={selectedTags}
				setSelectedTags={setSelectedTags}
				error={tagsError && tagsError[0]}
			/>
		</div>
	);

	const formControlsNode: React.ReactNode = (
		<div className="cart-control__form-controls">
			<BubbleButton
				title="Добавить"
				disabled={isSubmitLoading}
				isLoading={isSubmitLoading}
			/>
			<span className="cart-control__form-clear">
				<SecondaryButton title="Очистить" onClick={clearForm} />
			</span>
		</div>
	);

	return (
		<div className="cart-control">
			<div className="cart-control__top-color-line"></div>
			<form className="cart-control__form" onSubmit={submitForm}>
				<div className="cart-control__form_content">
					<div className="cart-control__image-container">
						{inputImageNode}
					</div>
					<div className="cart-control__text-container">
						{collectionNode}
						{inputTitleNode}
						{inputRateNode}
						{inputNotesNode}
						{inputTagsNode}
					</div>
				</div>
				{formControlsNode}
			</form>
		</div>
	);
};
