import './Toolbar.scss';

import React, { useEffect, useState } from 'react';
import TelegramLogin, {
	TelegramUser,
} from '../../utils/reactTelegramAuthButton';

import { TELEGRAM_BOT_NAME } from '../../constants/common.constants';

type ToolbarProps = {
	leftSideElements: React.ReactNode[];
	rightSideElements: React.ReactNode[];
	onTelegramAuth: (telegramUser: TelegramUser) => void;
	isTelegramAuthNotificationShow: boolean;
};

export const Toolbar: React.FC<ToolbarProps> = ({
	leftSideElements,
	rightSideElements,
	onTelegramAuth,
	isTelegramAuthNotificationShow,
}) => {
	const [prevScrollPos, setPrevScrollPos] = useState<number>(0);
	const [visible, setVisible] = useState<boolean>(true);

	const menuVisibleClass: string = visible
		? ''
		: !isTelegramAuthNotificationShow
			? 'toolbar-container--invisible'
			: 'toolbar-container--invisible--with-notification';

	const scrollEffect = () => {
		const handleScroll = () => {
			const currentScrollPos = window.scrollY;
			const isVisible =
				(prevScrollPos > currentScrollPos &&
					(prevScrollPos - currentScrollPos > 70 || visible)) ||
				currentScrollPos < 10;
			setVisible(isVisible);
			setTimeout(() => setPrevScrollPos(currentScrollPos), 120);
		};

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	};

	useEffect(scrollEffect, [prevScrollPos, visible]);

	const renderToolbarItem = (
		item: React.ReactNode,
		index: number,
	): React.ReactNode => (
		<div className="toolbar__item" key={`toolbar_item__${index}`}>
			{item}
		</div>
	);

	const telegramAuthButtonNode: React.ReactNode = (
		<TelegramLogin
			botName={TELEGRAM_BOT_NAME}
			dataOnAuth={onTelegramAuth}
			buttonSize="medium"
			additionalClassNames="toolbar-notification__button--inline"
		/>
	);

	const telegramAuthNotificationNode: React.ReactNode =
		isTelegramAuthNotificationShow && (
			<div className="toolbar-notification">
				<p className="toolbar-notification__text">
					Раздражающий хэдер, чтобы вы обязательно привязали телеграм
					к своему аккаунту: {telegramAuthButtonNode}
				</p>
			</div>
		);

	const leftItemsNode: React.ReactNode[] =
		leftSideElements.map(renderToolbarItem);
	const rightItemsNode: React.ReactNode[] =
		rightSideElements.map(renderToolbarItem);

	return (
		<div className={`toolbar-container ${menuVisibleClass}`}>
			{telegramAuthNotificationNode}
			<div className="toolbar">
				<div className="toolbar__column">{leftItemsNode}</div>
				<div className="toolbar__column">{rightItemsNode}</div>
			</div>
		</div>
	);
};
