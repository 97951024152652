import './TagBubble.scss';

import React from 'react';

type Sizes = 'small';

type TagBubbleProps = {
	isPicked: boolean;
	title?: string;
	size?: Sizes;
	noBackground?: boolean;
	isDisabled?: boolean;
};

const sizeClassMap = {
	small: 'tag-bubble--small',
};

export const TagBubble: React.FC<React.PropsWithChildren<TagBubbleProps>> = ({
	isPicked,
	title,
	size,
	noBackground,
	isDisabled,
	children,
}) => {
	const isPickedClass = isPicked ? 'tag-bubble--picked' : '';
	const noBackgroundClass = noBackground ? 'tag-bubble--no-background' : '';
	const isDisabledClass = isDisabled ? 'tag-bubble--disabled' : '';
	const sizeClass = size ? sizeClassMap[size] : '';
	const titleNode: React.ReactNode = title && (
		<span className="tag-bubble__title">{title}</span>
	);
	const childrenNode: React.ReactNode = children && (
		<div className="tag-bubble__children">{children}</div>
	);

	return (
		<div
			className={`tag-bubble ${isPickedClass} ${sizeClass} ${noBackgroundClass} ${isDisabledClass}`}
			title={title}
		>
			{childrenNode}
			{titleNode}
		</div>
	);
};
