import { RepositoryResponse, request } from './request.repository';

import { Errors } from '../types/api-error.type';
import { FullUserEntity } from '../entities/full-user.entity';
import { URL_API_ACCOUNT_INFO } from '../constants/urls.constants';

export interface ServiceResponseSuccess {
	status: 'OK';
	data: FullUserEntity;
}

export interface ServiceResponseError {
	status: 'ERROR';
	errors: Errors;
}

export async function fetchUserInfo(
	page_url: string,
): Promise<ServiceResponseSuccess | ServiceResponseError> {
	const url = URL_API_ACCOUNT_INFO`${page_url}`;
	const method = 'GET';
	const options = { method };

	const response: RepositoryResponse = await request(url, options);

	if (response.status === 'OK') {
		const data: FullUserEntity = response.data.content.data;
		return {
			status: 'OK',
			data,
		};
	}

	return {
		status: 'ERROR',
		errors: {},
	};
}
