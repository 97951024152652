import './FullCart.scss';

import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { RATES } from '../../../constants/carts.constants';
import { RateEntity } from '../../../entities/rate.entity';
import { SingleChoiceInput } from '../../FormInputs/SingleChoiceInput/SingleChoiceInput';
import { TagBubble } from '../../TagBubble/TagBubble';
import { URL_USER_COLLECTION_PAGE } from '../../../constants/urls.constants';

type FullCartRateProps = {
	isCartChanging: boolean;
	setIsCartChanging: (isChanging: boolean) => void;

	collectionId: number;
	rate: RateEntity;
	setRateCallback: (rate: RateEntity) => void;

	isAllowChanging: boolean;
	isAllowChangingClass: string;
	error: string | undefined;
};

export const FullCartRate: React.FC<FullCartRateProps> = ({
	isCartChanging,
	setIsCartChanging,
	collectionId,
	rate,
	setRateCallback,
	isAllowChanging,
	isAllowChangingClass,
	error,
}) => {
	const [isChanging, setIsChanging] = useState<boolean>(false);

	const rateBlockTitle = 'Степень годноты';

	const inputNode: React.ReactNode = (
		<SingleChoiceInput
			label={rateBlockTitle}
			options={RATES}
			error={error}
			selectedOption={rate}
			setSelectedOptionCallback={(option) => {
				setRateCallback(option as RateEntity);
			}}
		/>
	);

	const displayNode: React.ReactNode = (
		<div>
			<span
				className={`full-cart__label ${isAllowChangingClass}`}
				onClick={() => {
					setIsChanging(isAllowChanging);
					setIsCartChanging(isAllowChanging);
				}}
			>
				{rateBlockTitle}
			</span>
			<Link
				to={{
					pathname: URL_USER_COLLECTION_PAGE`${collectionId.toString()}`,
					search: `?rate=${rate.id}`,
				}}
			>
				<TagBubble title={rate.title} isPicked={true} />
			</Link>
		</div>
	);

	const finalNode: React.ReactNode =
		isChanging && isCartChanging ? inputNode : displayNode;

	return <div>{finalNode}</div>;
};
