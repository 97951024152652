import {
	CursorPaginatedDataResponse,
	RepositoryResponse,
	request,
} from '../request.repository';

import { CartEntity } from '../../entities/cart.entity';
import { URL_API_CART_LIST } from '../../constants/urls.constants';

export interface ServiceResponseSuccess {
	status: 'OK';
	data: CursorPaginatedDataResponse<CartEntity>;
}

export interface ServiceResponseError {
	status: 'ERROR';
	data: null;
}

export async function fetchCollectionCarts(
	collectionId: string,
	tagIds: Array<number>,
	rateIds: Array<number>,
	search: string | null,
	orderBy: string | null,
	ordering: 'asc' | 'dsc' | null,
	nextCursor: string | null,
	per_page: number | null,
): Promise<ServiceResponseSuccess | ServiceResponseError> {
	const getParams = Object.assign(
		{ collection_id: collectionId.toString() },
		nextCursor && { cursor: nextCursor },
		per_page && { per_page: per_page.toString() },
		orderBy && { order_by: orderBy },
		ordering && { ordering },
		search && { search },
	);
	const params = new URLSearchParams(getParams);
	const tagIdsStr = tagIds.map((x) => {
		return ['tag_id', x.toString()];
	});
	const tagsParams = new URLSearchParams(tagIdsStr);
	const rateIdsStr = rateIds.map((x) => {
		return ['rate', x.toString()];
	});
	const ratesParams = new URLSearchParams(rateIdsStr);

	const url = `${URL_API_CART_LIST}?${params}&${tagsParams}&${ratesParams}`;

	const method = 'GET';
	const options = {
		method,
	};

	const response: RepositoryResponse = await request(url, options);

	if (response.status === 'OK') {
		const data: CursorPaginatedDataResponse<CartEntity> =
			response.data.content;
		return {
			status: 'OK',
			data,
		};
	}

	return {
		status: 'ERROR',
		data: null,
	};
}
