import './EmojiPicker.scss';

import { AllEmojisEntity, EmojiEntity } from '../../../entities/emoji.entity';
import {
	EMOJI_GROUPS_NAMES_MAP,
	I_EMOJI_GROUPS_NAMES_MAP,
} from '../../../constants/emojis.constants';
import React, { useRef } from 'react';

import { Emoji } from '../Emoji/Emoji';
import { useClickAway } from 'react-use';

type EmojiPickerProps = {
	lastUsedEmojis: EmojiEntity[];
	allEmojis: AllEmojisEntity;
	pushEmojiCallback: (emojiName: string, add: boolean) => void;
	closePickerCallback: () => void;
};

export const EmojiPicker: React.FC<EmojiPickerProps> = ({
	lastUsedEmojis,
	allEmojis,
	pushEmojiCallback,
	closePickerCallback,
}) => {
	const refEmojiPicker = useRef<HTMLDivElement>(null);

	useClickAway(refEmojiPicker, () => closePickerCallback());

	const pushEmoji = (emojiName: string) => {
		pushEmojiCallback(emojiName, true);
		closePickerCallback();
	};

	const renderEmoji = (emojiEntity: EmojiEntity): React.ReactNode => {
		return (
			<div
				className="emoji-picker__emoji"
				onClick={() => pushEmoji(emojiEntity.name)}
				key={emojiEntity.name}
			>
				<Emoji emojiEntity={emojiEntity} size="default" />
			</div>
		);
	};

	const renderGroup = (
		group: keyof I_EMOJI_GROUPS_NAMES_MAP,
		emojisEntities: EmojiEntity[],
	): React.ReactNode => {
		const groupName = EMOJI_GROUPS_NAMES_MAP[group];
		const emojis = emojisEntities.map(renderEmoji);

		if (emojis.length === 0) {
			return null;
		}

		return (
			<div key={`emoji_group__${group}`}>
				<p className="emoji-picker__list-title">{groupName}</p>
				<div className="emoji-picker__emoji-list">{emojis}</div>
			</div>
		);
	};

	const lastUsedEmojisNodes = lastUsedEmojis.map(renderEmoji);

	const allEmojisNodes: React.ReactNode[] = [];
	for (const [group, emojisEntities] of Object.entries(allEmojis)) {
		allEmojisNodes.push(
			renderGroup(
				group as keyof I_EMOJI_GROUPS_NAMES_MAP,
				emojisEntities,
			),
		);
	}

	return (
		<div className="emoji-picker" ref={refEmojiPicker}>
			<p className="emoji-picker__list-title">Последние использованные</p>
			<div className="emoji-picker__emoji-list">
				{lastUsedEmojisNodes}
			</div>
			{allEmojisNodes}
		</div>
	);
};
