import { createCartFormData } from '../components/CartForm/CreateCartForm';
import { makeAutoObservable } from 'mobx';

type stateObject = createCartFormData;

export class FormStateStore {
	localStorageKey: string;

	constructor(localStorageKey: string) {
		makeAutoObservable(this);
		this.localStorageKey = localStorageKey;
	}

	setLSFormState(state: stateObject): void {
		window.localStorage.setItem(
			this.localStorageKey,
			JSON.stringify(state),
		);
	}

	getLSFormState(): stateObject | undefined {
		const state = window.localStorage.getItem(this.localStorageKey);
		if (state) {
			return JSON.parse(state);
		}
	}

	removeLSFormState(): void {
		window.localStorage.removeItem(this.localStorageKey);
	}

	saveState = (state: stateObject): void => {
		console.log('saveState: ', state);
		this.setLSFormState(state);
	};

	loadState = (): stateObject | undefined => {
		const state = this.getLSFormState();
		console.log('loadState: ', state);
		return state;
	};

	resetState(): void {
		this.removeLSFormState();
	}
}
