import './ReactedEmoji.scss';

import { Emoji } from '../Emoji/Emoji';
import React from 'react';
import { ReactedEmojiEntity } from '../../../entities/emoji.entity';
import { ReactedUsersTooltip } from '../ReactedUsersTooltip/ReactedUsersTooltip';

type ReactedEmojiProps = {
	emojiEntity: ReactedEmojiEntity;
	pushEmojiCallback: (emojiName: string, add: boolean) => void;
};

export const ReactedEmoji: React.FC<ReactedEmojiProps> = ({
	emojiEntity,
	pushEmojiCallback,
}) => {
	const {
		name,
		count,
		is_reacted: isReacted,
		last_users_reacted: lastUsersReacted,
	} = emojiEntity;
	const isReactedClass = isReacted ? 'emoji__button--reacted' : '';
	return (
		<div className="emoji__container">
			<div className="emoji__reacted-users-tooltip">
				<ReactedUsersTooltip
					lastReactedUsers={lastUsersReacted}
					reactedUsersTotalCount={count}
				/>
			</div>
			<div
				className={`emoji__button ${isReactedClass}`}
				onClick={() => pushEmojiCallback(name, !isReacted)}
			>
				<div className="emoji__img-container">
					<Emoji emojiEntity={emojiEntity} size="small" />
				</div>
				<span className="emoji__count">{count}</span>
			</div>
		</div>
	);
};
